import { render, staticRenderFns } from "./UserSetup.vue?vue&type=template&id=795babd0&scoped=true"
import script from "./UserSetup.vue?vue&type=script&lang=js"
export * from "./UserSetup.vue?vue&type=script&lang=js"
import style0 from "./UserSetup.vue?vue&type=style&index=0&id=795babd0&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "795babd0",
  null
  
)

export default component.exports