import { render, staticRenderFns } from "./Flag.vue?vue&type=template&id=81ab48a6&scoped=true"
import script from "./Flag.vue?vue&type=script&lang=js"
export * from "./Flag.vue?vue&type=script&lang=js"
import style0 from "./Flag.vue?vue&type=style&index=0&id=81ab48a6&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "81ab48a6",
  null
  
)

export default component.exports