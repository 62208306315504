<template>
	<page customNavBar customBody>
		<template #navbar>
			<div class="navbar navbar-expand-lg flex-nowrap box-shadow">
				<!-- Page title -->
				<div class="navbar-text nav-title flex" id="pageTitle">
					{{ $t("Auditing.title") }}
					<span class="far fa-fw fa-angle-right"></span>
					{{ $t("CandidateAuditing.candidates") }}
					<template v-if="responseGroup">
						<span class="far fa-fw fa-angle-right"></span>
						{{ responseGroup.ref_id }}
					</template>
				</div>
				<div
					v-if="!responseGroup"
					class="btn-topnav b-l ml-0 d-flex flex-column justify-content-center"
					v-tippy="{ placement: 'bottom' }"
					:title="$t('tooltip.export_list')"
					data-toggle="modal"
					data-target="#exportModal"
				>
					<i v-if="!exporting" class="fas fa-lg fa-download"></i>
					<i v-if="exporting" class="fas fa-lg fa-sync fa-spin"></i>
				</div>
				<div
					v-if="responseGroup"
					class="btn-topnav b-l ml-0 d-flex flex-column justify-content-center"
					@click="doCandidateExport"
					v-tippy="{ placement: 'bottom' }"
					:title="$t('tooltip.export_candidate')"
				>
					<i v-if="!exporting" class="fas fa-lg fa-download"></i>
					<i v-if="exporting" class="fas fa-lg fa-sync fa-spin"></i>
				</div>
				<!-- Multiselect actions -->
				<div class="topnav-margins b-r" style="width: 1px; height: 56px" v-if="numSelected > 0"></div>
				<div class="btn-group pl-3 topnav-margins" v-if="numSelected > 0">
					<div class="navbar-text nav-title no-grow mr-2" id="pageTitle">
						{{ $t("Auditing.num_selected", { num: numSelected }) }}
					</div>
					<button class="btn white" data-toggle="dropdown" aria-expanded="false">
						<i class="fas fa-ellipsis-v faw"></i>
					</button>
					<div class="dropdown-menu dropdown-menu-right">
						<a class="dropdown-item" data-toggle="modal" data-target="#addLabels">
							<i class="far fa-tags faw"></i>
							<label class="mb-0">{{ $t("Auditing.add_labels_dots") }}</label>
						</a>
						<a class="dropdown-item" data-toggle="modal" data-target="#removeLabels">
							<i class="far fa-tags faw"></i>
							<label class="mb-0">{{ $t("Auditing.remove_labels_dots") }}</label>
						</a>
					</div>
				</div>
			</div>
		</template>

		<div
			class="d-flex flex flex-row h100 mb-auto align-items-stretch"
			style="background-color: white; overflow: auto"
			v-if="!loading"
		>
			<div class="aside d-flex scroll-y">
				<div
					v-if="!responseGroup"
					class="modal-dialog w-md light b-r b-t white p-2 audit-search d-flex flex-column"
				>
					<div class="form-group">
						<label>{{ $t("CandidateAuditing.project") }}</label>
						<config-select :options="projects" v-model="selectedProject"></config-select>
					</div>
					<div class="form-group">
						<label>{{ $t("CandidateAuditing.complete") }}</label>
						<config-select
							:options="completeOptions"
							v-model="complete"
							byField="id"
							sortBy="sequence"
							:nullOption="true"
						></config-select>
					</div>
					<div class="form-group">
						<label>{{ $t("CandidateAuditing.candidate_response_id") }}</label>
						<input v-model="response_id" type="text" class="form-control" />
					</div>
					<div class="row form-group">
						<div class="col-6">
							<label>{{ $t("CandidateAuditing.min_total_score") }}</label>
							<input
								v-model.number="minScore"
								type="number"
								class="form-control"
								:class="{
									'is-invalid': minScore != null && maxScore != null && minScore > maxScore,
								}"
							/>
						</div>
						<div class="col-6">
							<label>{{ $t("CandidateAuditing.max_total_score") }}</label>
							<input
								v-model.number="maxScore"
								type="number"
								class="form-control"
								:class="{
									'is-invalid': minScore != null && maxScore != null && minScore > maxScore,
								}"
							/>
						</div>
					</div>
					<div class="row form-group">
						<div class="col-12">
							<div>
								<label
									class="md-check"
									v-tippy="{ theme: 'popover' }"
									:title="$t('CandidateAuditing.include_extra_scores_tooltip')"
								>
									<input v-model="includeExtraScores" type="checkbox" />
									<i class="theme-accent" />
								</label>
								<span class="ml-1 flex">{{ $t("CandidateAuditing.include_extra_scores") }}</span>
							</div>
						</div>
					</div>
					<!-- <div class="form-group">
									<label>{{ $t("Auditing.state") }}</label>
									<v-select :options="states" :searchable="false" v-model="selectedState"></v-select>
								</div>
								<div class="form-group">
									<label>{{ $t("Auditing.scored_by") }}</label>
									<config-user-search :clientID="user.client && user.client.id" v-model="users" includeTeams multiple />
								</div>
								<div class="form-group">
									<label>{{ $t("Auditing.has_score_type") }}</label>
									<v-select multiple :options="score_types" v-model="filter_types"></v-select>
								</div>

								<div class="flex">
									<div class="btn-group mt-2 btn-block w-100 form-group">
										<button @click="showQueryModal()" class="btn btn-block theme">
											{{ $t("buttons.query_scores") }}
										</button>
										<button
											@click="clearScoreSearch()"
											class="btn theme lighten-2"
											v-tippy
											:title="$t('tooltip.clear')"
										>
											<i class="far fa-times"></i>
										</button>
									</div>
									<table class="table condensed-table" v-if="ats_display && ats_display.length > 0">
										<tr>
											<th>{{ $t("fields.type") }}</th>
											<th>{{ $t("fields.trait") }}</th>
											<th>{{ $t("fields.score") }}</th>
										</tr>
										<tr v-for="(row, i) in ats_display" :key="i">
											<td v-if="row.first_of_type" :rowspan="row.rowspan" class="v-mid">
												<span v-if="row.score_type == 0">{{ $t("Auditing.any") }}</span>
												<span
													class="d-flex flex-row align-items-center"
													v-if="row.score_type != 0"
												>
													<i class="material-icons mi-small mr-1">{{
														fs.scoreTypeIcon(row.score_type)
													}}</i>
													{{ fs.scoreTypeAbr(row.score_type, vm.$i18n) }}
												</span>
											</td>
											<td>{{ row.name }}</td>
											<td>{{ row.score }}</td>
										</tr>
									</table>
								</div>

								<div
									class="btn-group align-self-end"
									role="group"
									style="height: 35px"
									aria-label="First group"
								>
									<button
										type="button"
										class="btn mb-2"
										:class="{ theme: view == 'single' }"
										style="height: 35px"
										@click="view = 'single'"
										v-tippy
										:title="$t('tooltip.preview')"
									>
										<i class="fas fa-window-maximize" />
									</button>
									<button
										type="button"
										class="btn mb-2"
										:class="{ theme: view == 'list' }"
										style="height: 35px"
										@click="view = 'list'"
										v-tippy
										:title="$t('tooltip.list')"
									>
										<i class="fas fa-th-list" />
									</button>
								</div> -->
				</div>
				<div v-else class="modal-dialog w-md light b-r b-t white p-2 audit-search d-flex flex-column">
					<div class="form-group card py-2 px-3">
						<label class="text-muted">{{ $t("CandidateAuditing.candidate_response_id") }}</label>
						<div class="_600">{{ responseGroup.ref_id }}</div>
					</div>
					<div class="form-group card py-2 px-3">
						<label class="text-muted">{{ $t("fields.complete") }}</label>
						<div class="_600">{{ responseGroup.complete_count }} / {{ responseGroup.count }}</div>
					</div>
					<div class="form-group card py-2 px-3">
						<label class="text-muted">{{ $t("fields.total_score") }}</label>
						<div class="_600">{{ responseGroup.total_score }}</div>
						<template v-if="responseGroup.meta?.meta?.oscar_total_score">
							<hr class="my-2" />
							<label v-tippy="{ theme: 'popover' }" class="text-muted">
								{{ $t("CandidateAuditing.oscar_score_total") }}
							</label>
							<div class="_600">{{ responseGroup.meta?.meta?.oscar_total_score }}</div>
						</template>
						<template v-if="responseGroup.meta?.extra_score">
							<label
								v-if="responseGroup.meta?.extra_scores"
								v-tippy="{ theme: 'popover' }"
								:title="extraScoresTooltip(responseGroup.meta)"
								class="text-muted"
							>
								{{
									responseGroup.meta?.meta?.extra_score_type ||
									$t("CandidateAuditing.extra_score_total")
								}}
							</label>
							<div class="_600">{{ responseGroup.meta.extra_score }}</div>
						</template>
					</div>
					<button @click="unpickResponseGroup" class="btn btn-block theme">
						{{ $t("buttons.back") }}
					</button>
				</div>
			</div>
			<div class="flex d-flex flex-column" style="overflow: hidden">
				<div class="flex scroll-y scroll-x">
					<!-- Table for response groups -->
					<b-table
						v-if="!responseGroup"
						ref="groupTable"
						striped
						hover
						:show-empty="true"
						:items="provider"
						:fields="groupFields"
						:current-page="currentPage"
						:per-page="perPage"
						:filter="filter"
						:sort-by.sync="sortBy"
						:sort-desc.sync="sortDesc"
						:busy.sync="busy"
					>
						<!-- Select -->
						<template #head(select)>
							<table-multiselect
								v-model="selectedIDs"
								:selectAll.sync="selectAll"
								master
								:items="currentItems"
								byField="ref_id"
								:totalRows="totalRows"
								:numSelected.sync="numSelected"
								:query="query"
								:reset="resetSelection"
							></table-multiselect>
						</template>
						<template #cell(select)="data">
							<table-multiselect
								:value="selectedIDs"
								:selectAll="selectAll"
								:id="data.item.ref_id"
							></table-multiselect>
						</template>

						<!-- Ref ID -->
						<template #cell(ref_id)="data">
							<div
								v-if="data.item.reliability_score"
								class="dot"
								v-tippy
								:title="$t('Auditing.marked_reliability')"
							></div>
							{{ data.item.ref_id }}
							<i
								v-if="data.item.labels && data.item.labels.length > 0"
								class="far fa-tags fa-fw text-muted ml-1"
								:id="`labels-${data.item.ref_id}`"
								v-b-popover
							></i>
							<b-popover
								v-if="data.item.labels && data.item.labels.length > 0"
								:target="`labels-${data.item.ref_id}`"
								triggers="hover"
							>
								<template slot="title">{{ $t("Auditing.labels") }}:</template>
								<li v-for="(label, i) in data.item.labels" :key="i">{{ label }}</li>
							</b-popover>
						</template>

						<!-- complete -->
						<template #cell(complete)="data">
							{{ data.item.complete_count }} / {{ data.item.count }}
						</template>

						<!-- Actions -->
						<template #cell(actions)="data">
							<div class="d-flex flex-rows">
								<div class="w30">
									<button
										@click="pickResponseGroup(data.item)"
										class="btn btn-sm btn-icon btn-rounded theme-accent text-white m-0"
										v-tippy
										:title="$t('tooltip.view_responses')"
									>
										<i class="fas fa-caret-right"></i>
									</button>
								</div>
							</div>
						</template>
					</b-table>

					<!-- Table for individual responses -->
					<b-table
						v-if="responseGroup"
						ref="respTable"
						striped
						hover
						:show-empty="true"
						:items="responseGroup.responses"
						:fields="respFields"
						:sort-by.sync="respSortBy"
					>
						<!-- Ref ID -->
						<template #cell(ref_id)="data">
							<div
								v-if="data.item.reliability_score"
								class="dot"
								v-tippy
								:title="$t('Auditing.marked_reliability')"
							></div>
							{{ data.item.ref_id }}
							<i
								v-if="data.item.labels && data.item.labels.length > 0"
								class="far fa-tags fa-fw text-muted ml-1"
								:id="`labels-${data.item.id}`"
								v-b-popover
							></i>
							<b-popover
								v-if="data.item.labels && data.item.labels.length > 0"
								:target="`labels-${data.item.id}`"
								triggers="hover"
							>
								<template slot="title">{{ $t("Auditing.labels") }}:</template>
								<li v-for="(label, i) in data.item.labels" :key="i">{{ label }}</li>
							</b-popover>
						</template>

						<!-- State -->
						<template #cell(state)="data">
							{{ getState(data.item.state) }}
						</template>

						<!-- Scores -->
						<template #cell(scores)="data">
							<span class="d-flex flex-row">
								<template v-for="score in data.item.scores">
									<span v-if="data.item.scores.length" :key="score.id">
										<span
											v-tippy="{
												theme: 'popover',
												html: `#s-${score.id}`,
												placement: 'top-start',
											}"
											class="badge theme mr-1 pointer"
										>
											<i class="material-icons score-badge">{{
												fs.scoreTypeIcon(score.score_type)
											}}</i>
										</span>
										<div class="d-none p-0" :id="`s-${score.id}`">
											<Score :score="score" :rubric="data.item.rubric"></Score>
										</div>
									</span>
								</template>
							</span>
						</template>

						<!-- Created At -->
						<template #cell(created_at)="data">{{ format(data.item.created_at) }}</template>
						<!-- Edit -->
						<template #cell(actions)="data">
							<div class="d-flex flex-rows">
								<div class="w30">
									<a
										@click="auditView(data.item)"
										class="btn btn-sm btn-icon btn-rounded theme-accent text-white m-0"
										v-tippy
										:title="$t('tooltip.view')"
									>
										<i class="far fa-search"></i>
									</a>
								</div>
								<div v-if="canScore(data.item)" class="w30">
									<a
										@click="score(data.item)"
										class="btn btn-sm btn-icon btn-rounded theme text-white m-0"
										v-tippy
										:title="$t('tooltip.score')"
									>
										<i v-if="data.item.state <= 2" class="material-icons tiny">check</i>
										<i
											v-if="data.item.state > 2 && data.item.state != 10"
											class="material-icons tiny"
											>done_all</i
										>
										<i
											v-if="data.item.state == 10 && !data.item.has_backread"
											class="material-icons tiny"
											>verified_user</i
										>
									</a>
								</div>
								<div v-if="data.item.locked" class="w30">
									<a
										@click="unlock(data.item)"
										class="btn btn-sm btn-icon btn-rounded theme text-white m-0"
										v-tippy
										:title="$t('tooltip.unlock')"
									>
										<i class="far fa-unlock"></i>
									</a>
								</div>
							</div>
						</template>
					</b-table>

					<media
						v-if="view == 'single' && currentItems && currentItems[0]"
						:response="!busy && currentItems[0]"
						noChan
					/>
				</div>
				<div v-if="!responseGroup" class="p-1 b-t mt-auto white d-flex flex-row align-items-center">
					<a class="flex mx-3"
						>{{ $t("pagination.page") }} {{ totalRows > 0 ? currentPage : 0 }} {{ $t("pagination.of") }}
						{{ Math.ceil(totalRows / perPage) }} ({{ totalRows }})</a
					>
					<div>
						<mz-pagination
							size="md"
							class="m-1"
							:total-rows="totalRows"
							v-model="currentPage"
							:per-page="perPage"
						></mz-pagination>
					</div>
				</div>
			</div>
		</div>

		<!-- TraitScore query modal -->
		<b-modal
			id="traitScoreModal"
			size="lg"
			v-model="queryModal"
			v-if="selectedItem && selectedItem.rubric"
			no-fade
			@hide="hideQueryModal"
		>
			<template slot="modal-title">{{ $t("Auditing.score_query") }}</template>
			<div class="row">
				<table class="table ats-table">
					<thead v-if="score_specific_query">
						<tr>
							<th style="width: 50%"></th>
							<th></th>
							<th>
								<span class="d-flex flex-row align-items-center justify-content-center">
									<i class="material-icons mi-small mr-1">{{ fs.scoreTypeIcon(1) }}</i>
									{{ fs.scoreTypeAbr(1, this.$i18n) }}
								</span>
							</th>
							<th>
								<span class="d-flex flex-row align-items-center justify-content-center">
									<i class="material-icons mi-small mr-1">{{ fs.scoreTypeIcon(2) }}</i>
									{{ fs.scoreTypeAbr(2, this.$i18n) }}
								</span>
							</th>
							<th>
								<span class="d-flex flex-row align-items-center justify-content-center">
									<i class="material-icons mi-small mr-1">{{ fs.scoreTypeIcon(3) }}</i>
									{{ fs.scoreTypeAbr(3, this.$i18n) }}
								</span>
							</th>
							<th>
								<span class="d-flex flex-row align-items-center justify-content-center">
									<i class="material-icons mi-small mr-1">{{ fs.scoreTypeIcon(4) }}</i>
									{{ fs.scoreTypeAbr(4, this.$i18n) }}
								</span>
							</th>
							<th style="width: 50%"></th>
						</tr>
					</thead>
					<thead v-if="!score_specific_query">
						<tr>
							<th style="width: 50%"></th>
							<th></th>
							<th class="text-center">{{ $t("fields.score") }}</th>
							<th style="width: 50%"></th>
						</tr>
					</thead>
					<tbody>
						<template v-for="trait in selectedItem.rubric.traits">
							<tr v-if="!(trait.separator || trait.is_parent)" :key="trait.id">
								<td></td>
								<td class="p-custom pr-4 v-mid">{{ trait.reported_name || trait.name }}</td>
								<td v-if="!score_specific_query" style="width: 10px">
									<v-select
										@input="tsChange(trait.id, 0, $event)"
										class="v-select-narrow"
										:options="traitSelectionMap[trait.id][0].sps"
										:value="traitSelectionMap[trait.id][0].selected"
										:searchable="false"
										:clearable="false"
									></v-select>
								</td>
								<template v-for="st in score_types">
									<td v-if="score_specific_query" :key="st.type">
										<v-select
											@input="tsChange(trait.id, st.type, $event)"
											class="v-select-narrow"
											:options="traitSelectionMap[trait.id][st.type].sps"
											:searchable="false"
											:clearable="false"
											:value="traitSelectionMap[trait.id][st.type].selected"
										></v-select>
									</td>
								</template>
								<td></td>
							</tr>
						</template>
					</tbody>
				</table>
			</div>
			<template slot="modal-footer">
				<div class="d-flex flex-row align-items-center">
					<div class="_600 mr-2">{{ $t("Auditing.by_score") }}</div>
					<label class="ui-switch ui-switch-md theme-accent mr-3">
						<input type="checkbox" v-model="score_specific_query" />
						<i></i>
					</label>
					<button @click="applyQueryModal" class="btn btn-flat primary">{{ $t("Auditing.apply") }}</button>
				</div>
			</template>
		</b-modal>

		<div class="modal" id="alreadyScoredWarning" data-backdrop="static" data-keyboard="false">
			<div class="modal-dialog">
				<div class="modal-content">
					<div class="modal-header">
						<h5>{{ $t("Auditing.warning") }}</h5>
					</div>
					<div class="modal-body text-center">
						<span class="text-md" v-if="selected_resp">
							{{ $t("Auditing.already_scored_response") }}
							<strong>{{ selected_resp.ref_id }}</strong>
						</span>
					</div>
					<div class="modal-footer">
						<button class="btn btn-flat btn-primary" data-dismiss="modal">
							{{ $t("buttons.cancel") }}
						</button>
						<button
							@click="scoreResponse(selected_resp, true)"
							class="btn btn-flat theme"
							data-dismiss="modal"
						>
							{{ $t("buttons.score") }}
						</button>
					</div>
				</div>
			</div>
		</div>

		<div class="modal" id="addLabels" data-backdrop="static" data-keyboard="false">
			<div class="modal-dialog">
				<div class="modal-content">
					<div class="modal-header">
						<h5>{{ $t("Auditing.add_labels") }}</h5>
					</div>
					<div class="modal-body">
						<h6>
							{{
								$t("Auditing.add_num_labels", {
									num: numSelected,
									r: numSelected == 1 ? "response" : "responses",
								})
							}}
						</h6>
						<div>
							<div class="form-group">
								<config-label-search
									v-model="pickLabels"
									:selectedIDs="selectedIDs"
									:selectAll="selectAll"
									:query="getQuery()"
									candidates
									taggable
								/>
							</div>
						</div>
					</div>
					<div class="modal-footer">
						<button class="btn btn-flat btn-primary" data-dismiss="modal">
							{{ $t("buttons.cancel") }}
						</button>
						<button
							v-if="!addingLabels"
							@click="addLabels(pickLabels)"
							class="btn btn-flat btn-secondary"
							:class="{ theme: pickLabels && pickLabels.length > 0 }"
							:disabled="!pickLabels || pickLabels.length == 0"
						>
							{{ $t("buttons.add_labels") }}
						</button>
						<button
							v-if="addingLabels"
							class="btn btn-flat btn-secondary"
							:class="{ theme: pickLabels && pickLabels.length > 0 }"
							disabled
						>
							{{ $t("buttons.adding_labels") }}<loading type="icon" class="ml-1" />
						</button>
					</div>
				</div>
			</div>
		</div>

		<div class="modal" id="removeLabels" data-backdrop="static" data-keyboard="false">
			<div class="modal-dialog">
				<div class="modal-content">
					<div class="modal-header">
						<h5>{{ $t("Auditing.remove_labels") }}</h5>
					</div>
					<div class="modal-body">
						<h6>
							{{
								$t("Auditing.remove_num_labels", {
									num: numSelected,
									r: numSelected == 1 ? "response" : "responses",
								})
							}}
						</h6>
						<div>
							<div class="form-group">
								<config-label-search
									v-model="pickLabels"
									:selectedIDs="selectedIDs"
									:selectAll="selectAll"
									:query="getQuery()"
									candidates
								/>
							</div>
						</div>
					</div>
					<div class="modal-footer">
						<button class="btn btn-flat btn-primary" data-dismiss="modal">
							{{ $t("buttons.cancel") }}
						</button>
						<button
							@click="removeLabels(pickLabels)"
							class="btn btn-flat theme"
							data-dismiss="modal"
							:disabled="!pickLabels || pickLabels.length == 0"
						>
							{{ $t("buttons.remove_labels") }}
						</button>
					</div>
				</div>
			</div>
		</div>

		<div class="modal" id="exportModal">
			<div class="modal-dialog">
				<div class="modal-content">
					<div class="modal-header">
						<h5 class="mb-0">{{ $t("CandidateAuditing.export_scores") }}</h5>
					</div>
					<div class="modal-body">
						<div class="col-12 form-group">
							<label>{{ $t("CandidateAuditing.export_type") }}</label>
							<v-select :options="exportOptions" v-model="exportType"></v-select>
						</div>
						<div class="col-12">
							<ul style="list-style-type: circle">
								<li style="list-style-type: circle">
									<strong>{{ $t("CandidateAuditing.candidate_per_row") }}:</strong>
									{{ $t("CandidateAuditing.each_candidate_row") }}
								</li>
								<li style="list-style-type: circle">
									<strong>{{ $t("CandidateAuditing.response_per_row") }}:</strong>
									{{ $t("CandidateAuditing.multiple_rows") }}
								</li>
							</ul>
						</div>
					</div>
					<div class="modal-footer">
						<button class="btn primary btn-flat" data-dismiss="modal">{{ $t("buttons.cancel") }}</button>
						<button
							v-if="!exporting"
							class="btn btn-flat"
							@click="doExport(exportType)"
							:disabled="!exportType"
							:class="{ 'btn-success': exportType, 'btn-secondary': !exportType }"
						>
							{{ $t("buttons.export") }}
						</button>
						<button v-if="exporting" class="btn btn-flat btn-secondary" disabled>
							<loading type="icon" class="mr-1" />
							{{ $t("buttons.exporting") }}
						</button>
					</div>
				</div>
			</div>
		</div>
	</page>
</template>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.w40 {
	width: 40px;
}
.w30 {
	width: 30px;
}
.audit-search > .form-group {
	margin-bottom: 5px;
}
.score-badge {
	font-size: 1rem;
	line-height: 1.5rem;
}
.badge {
	width: 1.25rem;
	height: 1.5rem;
	padding: 0 0;
}
.empty-badge {
	box-shadow: inset 0px 0px 0px 2px rgba(0, 0, 0, 0.25);
}
.mi-small {
	font-size: 1rem;
}
.ats-table {
	white-space: nowrap;
}
label {
	margin-top: 0.25rem;
	margin-bottom: 0.25rem;
}
.dot {
	height: 5px;
	width: 5px;
	background-color: #bbb;
	border-radius: 50%;
	display: inline-block;
}
.nodot {
	height: 5px;
	width: 5px;
	border-radius: 50%;
	display: inline-block;
}
.btn-topnav {
	margin-top: -8px;
	margin-bottom: -8px;
	width: 56px;
	height: 56px;
	text-align: center;
	cursor: pointer;
}
.btn-topnav:last-child {
	margin-right: -16px;
	margin-left: -16px;
}
.btn-topnav:hover {
	background-color: rgba(0, 0, 0, 0.075);
}
.btn-topnav a {
	line-height: 56px;
}
.topnav-margins {
	margin-top: -8px;
	margin-bottom: -8px;
}
</style>

<script>
//UI Components
//Libraries
//import _ from "lodash";
import moment from "moment";
import $ from "jquery";

//Services
import AuthService from "../../services/AuthService";
import ConfigService from "../../services/ConfigService";
import AuditingService from "../../services/AuditingService";
import ScoringService from "../../services/ScoringService";
import SectionService from "@/services/SectionService";
import QCConfigService from "@/services/QCConfigService";
import ResGroupService from "@/services/ResGroupService";
import FileService from "@/services/FileService";
import fs from "../../services/FormatService";
import Notie from "../../services/NotieService";
import store from "../../services/Store";
import BB from "bluebird";

import TableMultiselect from "../../components/TableMultiselect";
import ConfigUserSearch from "../../components/ConfigUserSearch";
import ConfigLabelSearch from "../../components/ConfigLabelSearch";
import ConfigSelect from "../../components/ConfigSelect";
import Score from "../../components/Score";
import Media from "../../components/viewer/Media";
import ResponseImportModal from "../../components/ResponseImportModal";
import MzPagination from "../../components/MzPagination";

export default {
	name: "CandidateAuditing",
	props: ["user"],
	components: {
		Score,
		Media,
		TableMultiselect,
		ConfigUserSearch,
		ConfigLabelSearch,
		ConfigSelect,
		ResponseImportModal,
		MzPagination,
	},
	data() {
		var states = [
			{ label: "　", value: 0 }, //Any
			{ label: fs.responseState(1, this.$i18n), value: 1 }, //Unscored
			{ label: fs.responseState(2, this.$i18n), value: 2 }, //Reliability
			{ label: fs.responseState(3, this.$i18n), value: 3 }, //Resolution
			{ label: fs.responseState(10, this.$i18n), value: 10 }, //Complete
			{ label: fs.responseState(20, this.$i18n), value: 20 }, //Withheld
			{ label: fs.responseState(40, this.$i18n), value: 40 }, //Awaiting Resolution
		];

		var exportStates = [
			{ label: "　", value: null },
			{ label: "Yes", value: true },
			{ label: "No", value: false },
		];

		var scoreTypes = [
			{ label: fs.scoreTypeAbr(1, this.$i18n), type: 1 },
			{ label: fs.scoreTypeAbr(2, this.$i18n), type: 2 },
			{ label: fs.scoreTypeAbr(3, this.$i18n), type: 3 },
			{ label: fs.scoreTypeAbr(4, this.$i18n), type: 4 },
		];

		var completeOptions = [
			{ name: fs.completeState(1, this.$i18n), id: 1, sequence: 1 },
			{ name: fs.completeState(2, this.$i18n), id: 2, sequence: 2 },
			{ name: fs.completeState(3, this.$i18n), id: 3, sequence: 3 },
		];

		var state = store.resolveSelectedRespState(this, states, "audit.c.selectedState");
		var exportState = store.resolveFromList(this, exportStates, "value", 0, "audit.c.exportState");

		var labels = store.get(this, "audit.c.labels");
		var filter_types = store.resolveSelectedScoreTypes(this, scoreTypes, "audit.c.filter_types");
		var response_id = store.get(this, "audit.c.response_id");
		var minScore = store.get(this, "audit.c.minScore");
		var maxScore = store.get(this, "audit.c.maxScore");
		var includeExtraScores = store.get(this, "audit.c.includeExtraScores");
		var users = store.getDefault(this, "audit.c.users", []);
		var score_specific_query = store.get(this, "audit.c.score_specific_query");
		var sortBy = store.getDefault(this, "audit.c.sortBy", "");
		var sortDesc = store.get(this, "audit.c.sortDesc");
		var currentPage = store.get(this, "audit.c.currentPage");
		var complete = store.get(this, "audit.c.complete");
		// debugger;

		var respSortBy = store.getDefault(this, "audit.c.respSortBy", "section_name");

		var view = store.getDefault(this, "audit.c.view", "list");

		let responseGroup = store.get(this, "audit.c.responseGroup");
		store.set(this, "audit.c.responseGroup", null);

		// console.log("STATES", this.states);

		//Dynamically bound fields
		return {
			vm: this,
			fs: fs,
			loading: false,
			latestFulfilledRequest: 0,
			addingLabels: false,
			exporting: false,
			respFields: [
				{
					key: "section_name",
					label: this.$i18n.t("fields.section"),
					sortable: true,
				},
				{
					key: "item_name",
					label: this.$i18n.t("fields.item"),
					sortable: true,
				},
				{ key: "ref_id", label: this.$i18n.t("fields.ref_id_short"), sortable: true },
				{ key: "state", label: this.$i18n.t("fields.state"), sortable: true },
				{
					key: "scores",
					label: this.$i18n.t("fields.scores"),
					tdClass: "p-custom p-0 v-mid",
					sortable: false,
				},
				{
					key: "created_at",
					label: this.$i18n.t("fields.created_at"),
					tdClass: "text-truncate",
					sortable: true,
				},
				{ key: "actions", label: this.$i18n.t("fields.actions"), tdClass: "p-custom p-0 v-mid" },
			],
			currentPage: currentPage,
			perPage: view == "list" ? 20 : 1,
			totalRows: 0,
			filter: "",
			sortBy: sortBy,
			sortDesc: sortDesc,
			respSortBy: respSortBy,
			busy: null,
			currentItems: [],
			query: null,
			selectedIDs: [],
			selectAll: null,
			numSelected: 0,
			queueResetSelection: false,
			view: view,
			responseGroup: responseGroup,
			projects: null,
			selectedProject: null,
			completeOptions: completeOptions,
			complete: complete,
			minScore: minScore,
			maxScore: maxScore,
			includeExtraScores: includeExtraScores,

			sections: [],
			selectedSection: null,

			items: [],
			selectedItem: null,
			item: null,

			selectedState: state ? state : states[0],

			response_id: response_id ? response_id : "",
			users: users,
			labels: labels,

			selected_resp: null,

			//Score Query
			score_types: scoreTypes,
			filter_types: filter_types,
			score_specific_query: score_specific_query,
			traitSelectionMap: null,
			audit_trait_scores: [],
			ats_display: null,

			//Labeling
			pickLabels: [],
			//Priority
			pickPriority: 0,

			//Moving section/item
			moveToSection: null,
			moveToItems: [],
			moveToItem: null,
			scoreCounts: null,

			oldScoreQuery: null,
			queryModal: false,

			showExportModal: false,
			exportOptions: [
				{ label: this.$i18n.t("CandidateAuditing.candidate_per_row"), id: 1 },
				{ label: this.$i18n.t("CandidateAuditing.response_per_row"), id: 2 },
			],
			exportType: null,

			resGroupMap: {},
		};
	},

	created() {
		this.loadConfigData();
		this.refreshTableDelayed = _.debounce(() => {
			if (this.$refs.groupTable) {
				this.$refs.groupTable.refresh();
			}
			if (this.$refs.respTable) {
				this.$refs.respTable.refresh();
			}
		}, 500);
		this.refreshTable = _.debounce(() => {
			if (this.$refs.groupTable) {
				this.$refs.groupTable.refresh();
			}
			if (this.$refs.respTable) {
				this.$refs.respTable.refresh();
			}
		}, 10);

		let initQuery = store.get(this, "audit.c.query");
		store.set(this, "audit.c.query", null);
		let ctx = {
			sortBy: store.get(this, "audit.c.sortBy"),
			sortDesc: store.get(this, "audit.c.sortDesc"),
			currentPage: store.get(this, "audit.c.currentPage"),
			perPage: 20,
		};
		if (initQuery) {
			if (!this.responseGroup) return;
			AuditingService.candidateAuditQuery(initQuery, ctx).then((resp) => {
				let sections = resp.data.sections;
				let items = resp.data.items;
				let rubrics = resp.data.rubrics;
				let rows = resp.data.rows;

				_.each(rows, (group) => {
					if (group.ref_id == this.responseGroup.ref_id) {
						_.each(group.responses, (row) => {
							row.section = _.find(sections, { id: row.section_id });
							row.section_name = row.section.name;
							row.item = _.find(items, { id: row.item_id });
							row.item_name = row.item.name;
							if (!row.item) return;
							row.rubric = _.find(rubrics, { id: row.item.rubric_id });
						});
						this.responseGroup = group;
					}
				});
			});
		}
	},

	computed: {
		groupFields() {
			let fields = [
				{
					key: "select",
					label: this.$i18n.t("fields.select"),
					thClass: "p-custom p-0 v-mid",
					thStyle: "width: 40px;",
					tdClass: "p-custom p-0 v-mid",
				},
				{ key: "ref_id", label: this.$i18n.t("fields.ref_id_short"), sortable: true },
				{ key: "total_score", label: this.$i18n.t("fields.total_score"), sortable: true },
				{ key: "complete", label: this.$i18n.t("fields.complete"), sortable: false },
				{
					key: "actions",
					label: this.$i18n.t("tooltip.view_responses"),
					tdClass: "p-custom p-0 v-mid text-center",
				},
			];

			return fields;
		},

		states() {
			var states = [
				{ label: "　", value: 0 }, //Any
				{ label: fs.responseState(1, this.$i18n), value: 1 }, //Unscored
				{ label: fs.responseState(2, this.$i18n), value: 2 }, //Reliability
				{ label: fs.responseState(3, this.$i18n), value: 3 }, //Resolution
				{ label: fs.responseState(10, this.$i18n), value: 10 }, //Complete
				{ label: fs.responseState(20, this.$i18n), value: 20 }, //Withheld
				{ label: fs.responseState(40, this.$i18n), value: 40 }, //Awaiting Resolution
			];

			if (this.selectedSection && this.selectedItem) {
				if (!this.isInResGroup(this.selectedSection.id, this.selectedItem.id)) {
					states.pop();
				}
			}

			return states;
		},
	},

	watch: {
		selectedProject() {
			this.refreshTable();
			store.set(this, "audit.c.selectedProject", this.selectedProject ? this.selectedProject.id : null);
		},
		selectedState() {
			this.refreshTable();
			store.set(this, "audit.c.selectedState", this.selectedState ? this.selectedState.value : null);
		},
		response_id() {
			this.refreshTableDelayed.cancel();
			this.refreshTableDelayed();
			store.set(this, "audit.c.response_id", this.response_id);
		},
		minScore() {
			if (this.minScore === "") this.minScore = null;
			this.refreshTableDelayed.cancel();
			this.refreshTableDelayed();
			store.set(this, "audit.c.minScore", this.minScore);
		},
		maxScore() {
			if (this.maxScore === "") this.maxScore = null;
			this.refreshTableDelayed.cancel();
			this.refreshTableDelayed();
			store.set(this, "audit.c.maxScore", this.maxScore);
		},
		includeExtraScores() {
			this.refreshTableDelayed.cancel();
			this.refreshTableDelayed();
			store.set(this, "audit.c.includeExtraScores", this.includeExtraScores);
		},
		users() {
			this.refreshTable();
			store.set(this, "audit.c.users", this.users);
		},
		filter_types() {
			this.refreshTable();
			store.set(this, "audit.c.filter_types", this.filter_types);
		},
		score_specific_query() {
			this.audit_trait_scores = [];
			store.set(this, "audit.c.score_specific_query", this.score_specific_query);
		},
		audit_trait_scores() {
			store.set(this, "audit.c.trait_scores", this.audit_trait_scores);
			this.updateATSDisplay();
		},
		view(newVal, oldVal) {
			if (newVal == "list" && oldVal != "list") {
				this.currentPage = Math.floor((this.currentPage - 1) / 20) + 1;
				this.perPage = 20;
			}
			if (newVal == "single" && oldVal != "single") {
				this.currentPage = (this.currentPage - 1) * 20 + 1;
				this.perPage = 1;
			}
			store.set(this, "audit.c.view", this.view);
		},
		labels() {
			this.refreshTable();
			store.set(this, "audit.c.labels", this.labels);
		},
		pickPriority() {
			if (this.pickPriority < 0) {
				this.pickPriority = 0;
			} else if (this.pickPriority > 100) {
				this.pickPriority = 100;
			}
		},
		complete() {
			this.refreshTable();
			store.set(this, "audit.c.complete", this.complete);
		},
		respSortBy() {
			store.set(this, "audit.c.respSortBy", this.respSortBy);
		},
	},

	methods: {
		loadConfigData() {
			this.showHiddenProjects = store.getDefault(this, "projects.showHiddenProjects");
			var ctx = {};
			ctx.showHiddenProjects = this.showHiddenProjects;
			let call = ConfigService.listProjectsShallow;
			if (this.user.role.limit_auditing) {
				call = ConfigService.listMyProjectsShallow;
			}

			call(ctx)
				.then((r) => {
					this.projects = r.data;
					this.selectedProject = store.resolveFromList(
						this,
						this.projects,
						"id",
						0,
						"audit.c.selectedProject"
					);
					if (!this.selectedProject && this.projects && this.projects.length > 0) {
						this.selectedProject == this.projects[0];
					}
					this.loading = false;
				})
				.catch((e) => {
					console.log(e);
					Notie.error("Failed to load configuration", e);
				});
		},

		provider(ctx) {
			console.log("Table provider", ctx);
			store.set(this, "audit.c.sortBy", this.sortBy);
			store.set(this, "audit.c.sortDesc", this.sortDesc);
			store.set(this, "audit.c.currentPage", this.currentPage);
			if (this.loading) {
				return [];
			}
			let query = this.getQuery();

			if (query.project_id === "") {
				return;
			}

			let requestTime = new Date().getTime();
			return AuditingService.candidateAuditQuery(query, ctx)
				.then((resp) => {
					if (requestTime < this.latestFulfilledRequest) {
						// This request is outdated, so do nothing
					}
					this.latestFulfilledRequest = requestTime;

					if (this.queueResetSelection) {
						this.resetSelection();
						this.queueResetSelection = false;
					}

					this.query = query;
					this.totalRows = resp.data.totalRows;
					this.currentItems = resp.data.rows;

					let sections = resp.data.sections;
					let items = resp.data.items;
					let rubrics = resp.data.rubrics;

					this.anyExtraScore = false;
					_.each(this.currentItems, (group) => {
						_.each(group.responses, (row) => {
							row.section = _.find(sections, { id: row.section_id });
							row.item = _.find(items, { id: row.item_id });
							if (!row.item) return;
							row.rubric = _.find(rubrics, { id: row.item.rubric_id });

							row.section_name =
								(row.section && row.section.name) || `(${this.$i18n.t("data_description.none")})`;
							row.item_name = (row.item && row.item.name) || `(${this.$i18n.t("data_description.none")})`;
						});

						group.labels = this.getUnionedLabels(group.responses);
						if (group.meta && group.meta.extra_score != undefined) {
							this.anyExtraScore = true;
						}
					});

					return this.currentItems;
				})
				.catch((err) => {
					console.log(err);
					Notie.error("Failed to load responses", err);
					return [];
				});
		},

		getQuery() {
			if (!this.selectedProject) return;

			let user_ids = [];
			_.each(this.users, (u) => {
				if (u.users) {
					_.each(u.users, (tu) => {
						user_ids.push(tu.id);
					});
				} else {
					user_ids.push(u.id);
				}
			});

			let trait_scores = [];
			_.each(this.audit_trait_scores, (ats) => {
				let ts = _.cloneDeep(ats);
				if (isNaN(ts.score)) {
					ts.condition = ts.score;
					ts.score = -1;
				}
				trait_scores.push(ts);
			});

			let query = {
				client_id: this.user && this.user.client.id,
				project_id: this.selectedProject.id,
				state: this.selectedState && this.selectedState.value,
				export_state:
					this.selectedItem &&
					this.selectedItem.api_export_config.enabled &&
					this.selectedExportState &&
					this.selectedExportState.value,
				response_id: this.response_id,
				user_ids: user_ids,
				trait_scores: trait_scores,
				score_types: _.map(this.filter_types, (t) => {
					return t.type;
				}),
				complete: this.complete,
			};

			if (this.minScore != null) {
				query["min_score"] = this.minScore;
			}
			if (this.maxScore != null) {
				query["max_score"] = this.maxScore;
			}
			query["include_extra_scores"] = this.includeExtraScores;

			return query;
		},

		getState(state) {
			// console.log(state);
			var stateObj = _.find(this.states, { value: state });
			if (stateObj) {
				return stateObj.label;
			}
			return "?";
		},

		updateATSDisplay() {
			let rows = [];
			let scoreTypeNumMap = {};
			_.each(this.audit_trait_scores, (ats) => {
				var trait = _.find(this.selectedItem.rubric.traits, {
					id: ats.trait_id,
				});
				if (trait) {
					scoreTypeNumMap[ats.score_type] = scoreTypeNumMap[ats.score_type] || 0;
					scoreTypeNumMap[ats.score_type]++;
					rows.push({
						name: trait.reported_name || trait.name,
						score_type: ats.score_type,
						score: ats.score,
					});
				}
			});

			rows = _.orderBy(rows, ["score_type", "name"]);
			let currentScoreType = -1;
			_.each(rows, (row) => {
				if (currentScoreType != row.score_type) {
					row.first_of_type = true;
					row.rowspan = scoreTypeNumMap[row.score_type] || 1;
					currentScoreType = row.score_type;
				}
			});

			this.ats_display = rows;
		},

		format(date) {
			return moment(date).format(this.$i18n.t("Auditing.date_format"));
		},

		tsChange(trait_id, score_type, sp) {
			this.traitSelectionMap[trait_id][score_type].selected = sp;
			//Remove all entries for this trait_id and score_type
			_.remove(this.audit_trait_scores, (e) => {
				return e.trait_id == trait_id && e.score_type == score_type;
			});

			if (sp) {
				this.audit_trait_scores.push({
					trait_id: trait_id,
					score_type: score_type,
					score: sp.value,
				});
			}
		},

		setupTraitScoreSelections() {
			//For each trait, and each score type, we need to create selection lists
			this.traitSelectionMap = null;
			var traitSelectionMap = {};
			var _this = this;
			_.each(this.selectedItem.rubric.traits, (t) => {
				if (t.separator) return;
				if (t.is_parent) return;

				var scoreTypeMap = {};
				var sps = [];
				for (var i = t.min; i < t.max + 1; i++) {
					sps.push({
						trait_id: t.id,
						label: "" + i,
						value: i,
					});
				}

				_.each(t.condition_codes, (cc) => {
					if (cc.action == 1) {
						sps.push({
							trait_id: t.id,
							label: cc.symbol,
							value: cc.symbol,
						});
					}
				});

				let selectedATS = _.find(this.audit_trait_scores, {
					trait_id: t.id,
					score_type: 0,
				});
				let selectedSP = selectedATS ? _.find(sps, { value: selectedATS.score }) : null;
				scoreTypeMap[0] = { selected: selectedSP, sps: sps };
				_.each(_this.score_types, (st) => {
					let selectedATS = _.find(this.audit_trait_scores, {
						trait_id: t.id,
						score_type: 0,
					});
					let selectedSP = selectedATS ? _.find(sps, { value: selectedATS.score }) : null;
					scoreTypeMap[st.type] = { selected: selectedSP, sps: sps };
				});

				_.each(scoreTypeMap, (st) => {
					_.each(st.sps, (sp) => {
						sp.st = st.type;
					});
				});

				traitSelectionMap[t.id] = scoreTypeMap;
			});
			this.traitSelectionMap = traitSelectionMap;
		},

		clearScoreSearch() {
			this.audit_trait_scores = [];
			this.score_specific_query = true;
			this.score_specific_query = false;
			this.traitSelectionMap = null;
			this.setupTraitScoreSelections();
			this.$forceUpdate();
			this.refreshTable();
		},

		//Scoring stuff
		canScore(resp) {
			return ScoringService.canScore(this.user, resp);
		},

		score(resp) {
			if (!this.user.active) {
				Notie.error(this.$i18n.t("notie.cannot_score_response"), this.$i18n.t("notie.user_not_active"));
				return;
			}

			this.selected_resp = resp;
			var scr = _.find(resp.scores, { user_id: this.user.id });
			if (scr) {
				this.showAlreadyScoredModal(resp);
				return;
			}
			this.scoreResponse(resp);
		},

		scoreResponse(resp, forcedRepeatScore) {
			store.set(this, "audit.c.responseGroup", this.responseGroup);
			store.set(this, "audit.c.query", this.getQuery());
			ScoringService.auditScore(resp, this.$router, null, forcedRepeatScore);
		},

		auditView(resp) {
			console.log("AUDIT VIEW", resp);
			store.set(this, "audit.c.responseGroup", this.responseGroup);
			store.set(this, "audit.c.query", this.getQuery());
			this.$router.push(`/audit/${resp.section_id}/${resp.item_id}/${resp.id}`);
		},

		showAlreadyScoredModal(resp) {
			$("#alreadyScoredWarning").modal("show");
		},

		unlock(resp) {
			ScoringService.unlockResponse(resp)
				.then(() => {
					Notie.info(this.$i18n.t("notie.response_unlocked"));
					this.refreshTable();
				})
				.catch((e) => {
					Notie.error(this.$i18n.t("notie.unlock_response_fail"));
					this.refreshTable();
				});
		},

		resetSelection() {
			this.selectedIDs = [];
			this.selectAll = false;
		},

		refreshAndResetSelection() {
			this.queueResetSelection = true;
			this.refreshTable();
		},

		showQueryModal() {
			this.oldScoreQuery = _.cloneDeep(this.audit_trait_scores);
			this.applyATS = false;
			this.updateTraitScoreMap();
			this.queryModal = true;
		},

		updateTraitScoreMap() {
			_.each(this.traitSelectionMap, (trait) => {
				_.each(trait, (opt) => {
					opt.selected = null;
				});
			});

			_.each(this.audit_trait_scores, (ats) => {
				let trait = this.traitSelectionMap[ats.trait_id];
				if (trait) {
					let opt = trait[ats.score_type];
					if (opt) {
						let choice = _.find(opt.sps, { value: ats.score });
						if (choice) {
							opt.selected = choice;
						}
					}
				}
			});

			this.$forceUpdate();
		},

		hideQueryModal() {
			if (!this.applyATS) {
				this.audit_trait_scores = _.cloneDeep(this.oldScoreQuery);
			} else {
				this.applyATS = false;
			}
		},

		applyQueryModal() {
			this.applyATS = true;
			this.queryModal = false;
			this.refreshTable();
		},

		click(id) {
			var el = document.getElementById(id);
			if (el) {
				el.click();
			}
		},

		pickResponseGroup(respGroup) {
			this.responseGroup = respGroup;
		},

		unpickResponseGroup() {
			this.responseGroup = null;
		},

		doExport(exportType) {
			if (this.exporting) return;
			if (!exportType && exportType.id) return;

			let query = this.getQuery();
			this.exporting = true;
			AuditingService.candidateAuditQuery(query, {})
				.then((r) => {
					this.exporting = false;
					let results = r.data.rows;
					if (exportType.id == 1) {
						FileService.candidateAuditingCSV(this.selectedProject, results);
					} else if (exportType.id == 2) {
						AuditingService.loadSectionItemNames(results).then((r) => {
							FileService.candidateAuditingCSVDetailed(this.selectedProject, results);
						});
					} else {
						Notie.error("Failed to export rows", `Unknown export type '${exportType.id}'`);
					}
					$("#exportModal").modal("hide");
				})
				.catch((e) => {
					console.log(e);
					Notie.error("Failed to export rows", e);
				});
		},

		doCandidateExport() {
			if (!this.responseGroup) {
				Notie.error("Export failed", "No response group found");
				return;
			}
			FileService.candidateAuditingCSVDetailed(this.selectedProject, [this.responseGroup]);
		},

		getUnionedLabels(responses) {
			let labelMap = {};
			_.each(responses, (r) => {
				let responseLabelMap = {};
				_.each(r.labels, (l) => {
					responseLabelMap[l] = true;
				});

				_.each(responseLabelMap, (_, l) => {
					if (!labelMap[l]) {
						labelMap[l] = 1;
					} else {
						labelMap[l]++;
					}
				});
			});

			let ret = [];
			_.each(labelMap, (n, l) => {
				if (n == responses.length) {
					ret.push(l);
				}
			});

			return ret;
		},

		addLabels(labels) {
			let selection = {
				selected_ids: this.selectedIDs,
				select_all: this.selectAll,
				query: this.getQuery(),
			};
			this.addingLabels = true;
			AuditingService.addCandidateLabels(selection, labels)
				.then(() => {
					this.addingLabels = false;
					Notie.success(this.$i18n.t("notie.labels_added"));
					this.pickLabels = [];
					$("#addLabels").modal("hide");
					this.refreshAndResetSelection();
				})
				.catch((err) => {
					this.addingLabels = false;
					Notie.error(this.$i18n.t("notie.add_labels_fail"), err);
				});
		},

		removeLabels(labels) {
			let selection = {
				selected_ids: this.selectedIDs,
				select_all: this.selectAll,
				query: this.getQuery(),
			};
			this.addingLabels = true;
			AuditingService.removeCandidateLabels(selection, labels)
				.then(() => {
					this.addingLabels = false;
					Notie.success(this.$i18n.t("notie.labels_removed"));
					this.pickLabels = [];
					$("#removeLabels").modal("hide");
					this.refreshAndResetSelection();
				})
				.catch((err) => {
					this.addingLabels = false;
					Notie.error(this.$i18n.t("notie.remove_labels_fail"), err);
				});
		},

		extraScoresTooltip(meta) {
			let tip = `<div class="text-muted">${this.$i18n.t("CandidateAuditing.extra_scores_tooltip")}</div>`;
			if (!(meta && meta.extra_scores)) {
				return tip;
			}

			tip += "<table>";

			let title = "Extra Scores";
			if (meta.meta?.extra_score_type) {
				title = meta.meta?.extra_score_type;
			}
			tip += `<tr class="b-b"><th colspan="4" class="text-center pt-3 pb-1"><h6 class="mb-0">${title}: <strong>${meta.extra_score}</strong></h6></th></tr>`;

			let i = 0;
			for (let key of Object.keys(meta.extra_scores)) {
				if (i % 2 == 0) {
					if (i != 0) {
						tip += "</tr>";
					}
					tip += "<tr>";
				}
				tip += `<td class="text-right"><strong>${key}:</strong></td><td class="pl-2 pr-4">${meta.extra_scores[key]}</td>`;
				i++;
			}
			tip += "</tr>";

			return tip;
		},
	},
};
</script>
