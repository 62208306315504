<template>
	<page customNavBar customBody>
		<template #navbar>
			<div class="navbar navbar-expand-lg">
				<!-- Page title -->
				<div class="navbar-text nav-title flex" id="pageTitle">{{ $t("TrainingSetup.title") }}</div>

				<!-- Create new -->
				<div class="py-0">
					<a href="#/training/new" class="btn btn-sm hover-darken theme-accent btn-block">
						<i class="far fa-fw fa-plus"></i>
						<span class="hidden-folded d-inline">{{ $t("TrainingSetup.new_course") }}</span>
					</a>
				</div>

				<!-- Search field -->
				<div class="collapse navbar-collapse no-grow order-lg-1" id="navbarToggler">
					<form class="input-group m-2 my-lg-0">
						<span class="input-group-btn">
							<button
								v-if="!filter"
								type="button"
								class="btn no-border no-bg no-shadow"
								style="cursor: default; width: 35px"
							>
								<i class="fa fa-search"></i>
							</button>
							<button
								v-if="filter"
								type="button"
								class="btn no-border no-bg no-shadow"
								style="width: 35px"
								@click="filter = ''"
							>
								<i class="fa fa-times"></i>
							</button>
						</span>
						<input
							type="text"
							v-model="filter"
							class="form-control"
							:placeholder="$t('TrainingSetup.search_placeholder')"
						/>
					</form>
				</div>
			</div>
		</template>

		<!-- Page Content -->
		<div class="content-main">
			<div class>
				<!-- Page content goes here -->
				<b-table
					ref="table"
					responsive="lg"
					striped
					hover
					:show-empty="true"
					:items="provider"
					:fields="fields"
					:current-page="currentPage"
					:per-page="perPage"
					:filter="filter"
					:sort-by.sync="sortBy"
					:sort-desc.sync="sortDesc"
				>
					<!-- Priority -->
					<template #cell(priority)="data">
						<span v-if="data.item.priority">
							<i class="fas fa-sliders-h faw"></i>
							{{ data.item.priority }}
						</span>
						<span v-else class="text-muted">{{ $t("data_description.none") }}</span>
					</template>

					<!-- Elements -->
					<template #cell(elements)="data">
						<span v-if="data.item.elements && data.item.elements.length > 0">
							<i class="fas fa-sliders-h faw"></i>
							{{ data.item.elements.length }}
						</span>
						<span v-else class="text-muted">{{ $t("data_description.none") }}</span>
					</template>

					<!-- Sections -->
					<template #cell(sections)="{ item: { sections } }">
						<span v-if="sections && sections.length > 0" v-tippy :title="listSections(sections)">
							<i class="fas fa-font faw"></i>
							{{ sections.length }}
						</span>
						<span v-else class="text-muted">{{ $t("data_description.none") }}</span>
					</template>

					<!-- Items -->
					<template #cell(items)="{ item: { items } }">
						<span v-if="items && items.length > 0" v-tippy :title="listItems(items)">
							<i class="fas fa-font faw"></i>
							{{ items.length }}
						</span>
						<span v-else class="text-muted">{{ $t("data_description.none") }}</span>
					</template>


					<!-- Edit -->
					<template #cell(edit)="data">
						<div class="mx-auto w60">
							<a
								:href="`#/training/${data.item.id}`"
								class="btn btn-sm btn-icon btn-rounded theme-accent text-white m-0"
								v-tippy
								:title="$t('tooltip.edit')"
							>
								<i class="far fa-edit"></i>
							</a>
							<button
								v-if="!data.item.inProgress"
								@click="deleting = data.item"
								class="btn btn-sm btn-icon btn-rounded hover-darken red text-white m-0"
								v-tippy
								:title="$t('tooltip.delete')"
							>
								<i class="far fa-trash"></i>
							</button>
							<button
								v-if="data.item.inProgress"
								class="btn btn-sm btn-icon btn-rounded m-0"
								v-tippy
								title="Removing..."
							>
								<loading type="icon" />
							</button>
						</div>
					</template>

					<template slot="empty">
						<loading type="table" v-if="loading" />
						<div v-if="!loading">{{ $t("data_description.no_records") }}</div>
					</template>
				</b-table>
			</div>
		</div>
		<div class="p-1 b-t mt-auto white d-flex flex-row align-items-center">
			<a class="flex mx-3"
				>{{ $t("pagination.page") }} {{ totalRows > 0 ? currentPage : 0 }} {{ $t("pagination.of") }}
				{{ Math.ceil(totalRows / perPage) }} ({{ totalRows }})</a
			>
			<div>
				<b-pagination
					size="md"
					class="m-1"
					:total-rows="totalRows"
					v-model="currentPage"
					:per-page="perPage"
				></b-pagination>
			</div>
		</div>

		<b-modal id="deleteConfirm" :visible="deleting != false" v-if="deleting">
			<template slot="modal-title">{{ $t("TrainingSetup.delete_modal.title") }}</template>
			<div class="row p-4">
				{{ $t("TrainingSetup.delete_modal.confirm_delete") }}&nbsp;
				<strong class="text-theme">{{ deleting.name }}</strong
				>?
			</div>
			<template slot="modal-footer">
				<button class="btn primary btn-flat" @click="deleting = false">
					{{ $t("buttons.cancel") }}
				</button>
				<button class="btn danger btn-flat" @click="deleteTrainingCourse(deleting)">
					{{ $t("buttons.delete") }}
				</button>
			</template>
		</b-modal>
	</page>
</template>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.w40 {
	width: 40px;
}
.w60 {
	width: 62px;
}
</style>

<script>
import SectionService from "@/services/SectionService";
import ItemService from "@/services/ItemService";
import TrainingService from "@/services/TrainingService";
import notie from "@/services/NotieService";
import store from "@/services/Store";

export default {
	name: "TrainingSetup",
	data() {
		return {
			fields: [
				{ key: "name", label: this.$i18n.t("fields.name"), sortable: true, thStyle: { width: "60%" } },
				{
					key: "priority",
					label: this.$i18n.t("fields.priority"),
					sortable: false,
					tdClass: "text-center",
					thClass: "text-center",
				},
				{
					key: "elements",
					label: this.$i18n.t("fields.elements"),
					sortable: false,
					tdClass: "text-center",
					thClass: "text-center",
				},
				{
					key: "sections",
					label: this.$i18n.t("fields.sections"),
					sortable: false,
					tdClass: "text-center",
					thClass: "text-center",
				},
				{
					key: "items",
					label: this.$i18n.t("fields.items"),
					sortable: false,
					tdClass: "text-center",
					thClass: "text-center",
				},
				{
					key: "edit",
					label: this.$i18n.t("fields.actions"),
					thStyle: { "text-align": "center" },
					tdClass: "p-custom p-0 v-mid",
				},
			],
			currentPage: 1,
			perPage: 15,
			totalRows: 0,
			filter: "",
			sortBy: null,
			sortDesc: false,
			loading: true,

			deleting: false,
		};
	},

	components: {},

	created() {
		this.sortBy = store.get(this, "training.sortBy");
		this.sortDesc = store.getDefault(this, "training.sortDesc", false);
	},

	watch: {
		sortBy() {
			store.set(this, "training.sortBy", this.sortBy);
		},
		sortDesc() {
			store.set(this, "training.sortDesc", this.sortDesc);
		},
	},

	methods: {
		async provider(ctx) {
			const sections = await this.getSections();
			const items = await this.getItems();
			return TrainingService.listTrainingCourses(ctx)
				.then((resp) => {
					const { courses, totalRows } = resp.data;
					this.totalRows = totalRows;
					courses.forEach((course) => {
						course.sections = sections.filter(({ training_course_ids }) =>
							(training_course_ids || []).includes(course.id)
						);
						course.items = items.filter(({ training_course_ids }) =>
							(training_course_ids || []).includes(course.id)
						);

					});
					console.log(courses)
					this.loading = false;
					return courses;
				})
				.catch((err) => {
					console.log(err);
					notie.error(this.$i18n.t("notie.load_training_courses_fail"), err);
					this.loading = false;
					return [];
				});
		},

		deleteTrainingCourse(course) {
			course.inProgress = true;
			TrainingService.deleteTrainingCourse(course.id)
				.then(() => {
					notie.info(this.$i18n.t("notie.training_course_deleted"));
					this.refreshTable();
					course.inProgress = false;
					this.$forceUpdate();
				})
				.catch((err) => {
					notie.error(this.$i18n.t("notie.delete_training_course_fail"), err);
					course.inProgress = false;
					this.$forceUpdate();
				});
			this.deleting = false;
		},

		refreshTable() {
			this.$refs.table.refresh();
		},

		listSections(sections) {
			return `${this.$i18n.t("tooltip.sections")}:<br /><br /><ul class='pl-4 pr-3'>${sections
				.map(({ name }) => `<li class="text-left">${name}</li>`)
				.join("")}</ul>`;
		},

		listItems(items) {
			return `${this.$i18n.t("tooltip.items")}:<br /><br /><ul class='pl-4 pr-3'>${items
				.map(({ name }) => `<li class="text-left">${name}</li>`)
				.join("")}</ul>`;
		},

		async getSections() {
			try {
				const {
					data: { sections },
				} = await SectionService.listSections();
				console.log(sections)
				return sections;
			} catch (err) {
				notie.error("Failed to get Sections", err);
				return [];
			}
		},

		async getItems() {
			try {
				const {
					data: { items },
				} = await ItemService.listItems();
				console.log(items)
				return items;
			} catch (err) {
				notie.error("Failed to get Items", err);
				return [];
			}
		},
	},
};
</script>
