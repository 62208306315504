<template>
	<tr>
		<td style="width: 32px">
			<div class="w-32 avatar circle text-white" :style="{ 'background-color': avatarColor }">
				{{ initials }}
			</div>
		</td>
		<td>
			<div class="item-title _500">
				{{ user.full_name }}
				<span class="text-muted">({{ user.scorer_id }})</span>
			</div>
			<div class="text-muted">{{ role_name }}</div>
		</td>
		<td>
			<div class="float-right" :class="{ 'text-muted': !current_session }">
				<div :class="{ _500: current_session }" style="text-align: right">
					{{ activityPage }}
				</div>
				<div style="text-align: right">{{ sinceString(time) }}</div>
			</div>
		</td>
		<td style="width: 32px" class="b-r">
			<div class="item-action dropdown pr-2">
				<div v-b-popover :id="`u-${user.id}-activity`" :class="{ 'text-very-muted': !current_session }">
					<i class="fa fa-fw fa-lg" :class="{ [activityIcon]: true }"></i>
				</div>
				<b-popover :target="`u-${user.id}-activity`" triggers="hover" placement="left">
					<template slot="title">{{ $t("Dashboard.current_activity") }}</template>
					<div class="w">
						<div class="form-group">
							<div v-if="current_session" class="text-muted">
								{{ $t("pagination.page") }}
							</div>
							<div class="text">
								{{ activityPage }}
								<span v-if="activitySubtask" class="text text-muted">({{ activitySubtask }})</span>
							</div>
							<div v-if="current_session && current_session.params.section_name" class="text">
								<i class="fas fa-briefcase fa-fw text-muted"></i>
								{{ current_session.params.section_name }}
							</div>
							<div v-if="current_session && current_session.params.item_name" class="text">
								<i class="fas fa-book fa-fw text-muted"></i>
								{{ current_session.params.item_name }}
							</div>
						</div>
						<div class="form-group">
							<div v-if="current_session" class="text-muted">
								{{ $t("Dashboard.last_activity") }}
							</div>
							<div v-if="!current_session" class="text-muted">
								{{ $t("Dashboard.last_login") }}
							</div>
							<div class="text">{{ medDate(time) }}</div>
						</div>
					</div>
				</b-popover>
			</div>
		</td>
		<td class="side-actions">
			<div class="d-flex flex-row align-items-center justify-content-end h-100">
				<user-lockout-icon
					v-for="type in lockoutTypes"
					:key="type.id"
					:lockoutType="type"
					:lockoutStat="lockoutStat"
				/>

				<div
					class="switch d-flex flex-column align-items-center"
					v-tippy
					:title="$t('tooltip.inactive_users_cannot_score')"
				>
					<div style="height: 23px; padding-top: 2px">
						<label class="ui-switch ui-switch-sm theme-accent">
							<input @change="setActive()" type="checkbox" v-model="active" />
							<i></i>
						</label>
					</div>
					<div class="text-muted">{{ $t("Dashboard.active") }}</div>
				</div>
				<a v-if="activeUser.role.page_mail" class="text-center icon" @click="composeMail">
					<div class>
						<i class="fa fa-envelope fa-lg"></i>
					</div>
					<div class>{{ $t("Dashboard.mail") }}</div>
				</a>
				<a
					v-if="activeUser.role.page_user_summary"
					class="text-center icon"
					data-toggle="dropdown"
					aria-expanded="false"
				>
					<div>
						<i class="fa fa-chart-bar fa-lg"></i>
					</div>
					<div>{{ $t("Dashboard.report") }}</div>
				</a>
				<div class="dropdown-menu w-xxl scroll-y" style="max-height: 421px">
					<a
						v-for="link in userItemRows"
						:key="link.section_id + link.item_id"
						class="dropdown-item"
						@click="goToSummary(link)"
					>
						<label class="mb-0">
							<i class="far fa-briefcase"></i>
							{{ link.section_name }}
						</label>
						<i class="far fa-angle-right"></i>
						<label class="mb-0">
							<i class="far fa-book"></i>
							{{ link.item_name }}
						</label>
					</a>
				</div>
			</div>
		</td>
	</tr>
</template>

<style scoped>
a:not(.exception) {
	opacity: 0.6;
	transition: opacity 0.25s;
}
a:not(.exception):hover {
	opacity: 1;
}
a.exception {
	opacity: 1;
	transition: opacity 0.25s;
}
.item-action > a {
	margin-top: -0.6rem;
	margin-bottom: -0.6rem;
	padding-top: 0.6rem;
	padding-bottom: 0.6rem;
}
table {
	width: 100%;
}
td {
	padding: 0.75rem 0.5rem;
	vertical-align: middle;
}
.dropdown-item {
	white-space: normal;
	padding: 0.25rem 0.75rem;
}
.dropdown-item i {
	width: 1.2rem;
	margin-left: 0;
	margin-right: 0;
	text-align: center;
	opacity: 0.7;
}
::-webkit-scrollbar {
	width: 7px;
	background-color: #fff;
	border-radius: 4px;
}
::-webkit-scrollbar-thumb {
	border-radius: 4px;
	background-color: rgba(0, 0, 0, 0.2);
	-webkit-box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
}
.side-actions {
	width: 1px;
}
.icon {
	width: 50px;
}
.switch {
	width: 50px;
}
</style>

<script>
import UserService from "@/services/UserService";
import ThemeService from "@/services/ThemeService";
import ExceptionService from "@/services/ExceptionService";
import store from "@/services/Store";
import notie from "@/services/NotieService";
import fs from "@/services/FormatService";
import { LOCKOUT_REASONS } from "@/services/Constants";

import UserLockoutIcon from "./UserLockoutIcon";

require("@/directives/chartjs");

const ICONS = {
	Login: "fa-browser", //Yes, Login should map to 'Landing'
	Landing: "fa-browser",
	BulletinBoard: "fa-browser",
	Dashboard: "fa-tachometer-alt",
	SupervisorDashboard: "fa-tachometer-alt",
	ProjectDashboard: "fa-tachometer-alt",
	Profile: "Profile",
	Scoring: "fa-check",
	Backreading: "fa-check",
	Mail: "fa-envelope",
	Viewer: "fa-check",
	Resolution: "fa-check",
	BackreadViewer: "fa-check",
	QueueBackreadViewer: "fa-check",
	FlagQueueViewer: "fa-check",
	QCTrueScoreViewer: "fa-balance-scale",
	TrainingViewer: "fa-balance-scale",
	RangefindingViewer: "fa-drafting-compass",
	QCUserSetReport: "fa-balance-scale",
	ReportCompletion: "fa-chart-bar",
	ReportScorers: "fa-chart-bar",
	ReportDaily: "fa-chart-bar",
	ReportUserSummary: "fa-chart-bar",
	ReportItemSummary: "fa-chart-bar",
	ReportUserAgreement: "fa-chart-bar",
	ReportProjectAgreement: "fa-chart-bar",
	Burndown: "fa-chart-bar",
	Auditing: "fa-barcode-alt",
	ScoreAuditing: "fa-barcode-alt",
	CandidateAuditing: "fa-barcode-alt",
	ResetHistory: "fa-barcode-alt",
	QCSetup: "fa-balance-scale",
	QCSetResponses: "fa-balance-scale",
	QCReport: "fa-balance-scale",
	QCUserSetReport: "fa-balance-scale",
	RFSetup: "fa-drafting-compass",
	RFSetResponses: "fa-drafting-compass",
	RFReport: "fa-drafting-compass",
	RFResponseReport: "fa-drafting-compass",
	RFUserSetReport: "fa-drafting-compass",
	UserSetup: "fa-user",
	UserEdit: "fa-user",
	TeamSetup: "fa-users",
	TeamEdit: "fa-users",
	TenantSetup: "fa-building",
	TenantEdit: "fa-building",
	ProjectSetup: "fa-box-open",
	ProjectEdit: "fa-box-open",
	SectionSetup: "fa-briefcase",
	SectionEdit: "fa-briefcase",
	ItemSetup: "fa-book",
	ItemEdit: "fa-book",
	RubricSetup: "fa-tasks",
	RubricEdit: "fa-tasks",
};

const SUBTASKS = {
	Resolution: "resolution",
	BackreadViewer: "backreading",
	QueueBackreadViewer: "backreading",
	FlagQueueViewer: "flag_queue",
	TrainingViewer: "training",
	QCUserSetReport: "qc_user_set",
};

export default {
	name: "SuperDashUserActivityRow",

	components: {
		UserLockoutIcon,
	},

	props: {
		activeUser: Object,
		user: Object,
		role_name: String,
		current_session: Object,
		time: String,
		initActive: Boolean,
		team_assignments: Array,
	},

	data() {
		const { initActive } = this;
		return {
			active: initActive,
			theme: null,
			lockoutStat: {
				reason: "",
			},
		};
	},

	created() {
		this.loadLockoutReason();
	},

	watch: {
		time() {
			this.loadLockoutReason();
		},
	},

	computed: {
		initials() {
			let names = this.user.full_name.split(" ");
			let first_initial = names[0] ? names[0].substring(0, 1).toUpperCase() : "";
			let last_initial = names[1] ? names[1].substring(0, 1).toUpperCase() : "";
			return first_initial + last_initial;
		},

		avatarColor() {
			if (!this.current_session) {
				return "#E0E0E0";
			}

			if (!this.theme) {
				this.theme = "info";
			}
			return ThemeService.getThemeColorHex(this.theme);
		},

		activityPage() {
			const { current_session } = this;
			let page = current_session && current_session.page;
			if (!page) {
				return this.$i18n.t("Dashboard.logged_out");
			}
			return this.$i18n.t(`ActivityPages.${page}`);
		},

		activityIcon() {
			const { current_session } = this;
			let page = current_session && current_session.page;
			if (!page) {
				return "fa-minus";
			}
			return ICONS[page] || "fa-map-marker-question";
		},

		activitySubtask() {
			const { current_session } = this;
			let page = current_session && current_session.page;
			if (!page) {
				return null;
			}
			let subtask = SUBTASKS[page];
			if (!subtask) {
				return null;
			}
			return subtask;
		},

		userItemRows() {
			//Construct a section/item row for each item in the relevant sections
			let rows = [];
			_.each(this.sectionMap, (section) => {
				_.each(section.items, (item) => {
					rows.push({
						section_id: section.id,
						section_name: section.name,
						item_id: item.id,
						item_name: item.name,
					});
				});
			});

			return rows;
		},

		sectionMap() {
			const { user, team_assignments } = this;
			const sections = {};
			_.each(team_assignments, (ta) => {
				let assigned = _.find(ta.users, (r) => {
					return r.user.id == user.id;
				});
				if (assigned) {
					_.each(ta.sections, (section) => {
						sections[section.id] = section;
					});
				}
			});
			return sections;
		},

		lockoutTypes() {
			return LOCKOUT_REASONS;
		},
	},

	methods: {
		async loadLockoutReason() {
			const { user } = this;
			try {
				const { data } = await ExceptionService.getLastLockoutStat(user.id);
				this.lockoutStat = data;
				if (this.lockoutStat.reason !== "") {
					this.active = false;
				}
			} catch (e) {
				notie.error("failed to get last lockout stat", e);
			}
		},

		goToSummary(link) {
			const { activeUser, user } = this;
			if (!activeUser.role.page_user_summary) {
				return;
			}
			const userCopy = { ...user };
			userCopy.name = fs.nameWithScorerID(user);
			store.set(this, "userSummary.selectedUser", userCopy);
			store.set(this, "userSummary.selectedSection", {
				id: link.section_id,
			});
			store.set(this, "userSummary.selectedItem", { id: link.item_id });
			store.set(this, "userSummary.auto", true);
			this.$router.push("/reporting/user_summary");
		},

		isZeroDate(date) {
			if (date.substring(0, 4) == "0001") {
				return true;
			} else {
				return false;
			}
		},

		medDate(date) {
			return this.isZeroDate(date) ? this.$i18n.t("Dashboard.never") : fs.medDate(date);
		},

		sinceString(date) {
			return this.isZeroDate(date) ? this.$i18n.t("Dashboard.never") : fs.sinceString(date);
		},

		setActive() {
			const { active, user } = this;
			const action = active ? "activate" : "deactivate";
			UserService[action](user)
				.then((resp) => {
					notie.info(this.$i18n.t("notie.user_action", { action }));
					this.loadLockoutReason();
				})
				.catch((err) => {
					notie.error(this.$i18n.t("notie.user_action_fail", { action }));
				});
		},

		composeMail() {
			const { activeUser, user } = this;
			if (!activeUser.role.page_mail) {
				return;
			}
			const userClone = { ...user };
			userClone.name = fs.nameWithScorerID(user);
			store.set(this, "mail.composeTo", userClone);
			this.$router.push("/mail");
		},
	},
};
</script>
