import axios from "axios"
import fs from "@/services/FormatService"

export default {
	getMySites() {
		return axios.get(`/v1/my/observation/sites`)
	},

	getMyForms() {
		return axios.get(`/v1/my/observation/forms`)
	},

	getMyScheduleCounts() {
		return axios.get(`/v1/my/observation/schedule_counts`)
	},

	getSiteUsers(siteID, ctx) {
		return axios.get(`/v1/admin/observation/site_users/${siteID}`, { params: ctx })
	},

	addSiteUser(siteID, userID) {
		return axios.post(`/v1/admin/observation/site_users/${siteID}/${userID}/add`);
	},

	removeSiteUser(siteID, userID) {
		return axios.post(`/v1/admin/observation/site_users/${siteID}/${userID}/remove`);
	},

	querySchedules(query, ctx) {
		console.log("querySchedules", query, ctx)
		query.currentPage = ctx.currentPage
		query.perPage = ctx.perPage
		query.sortBy = ctx.sortBy
		query.sortDesc = ctx.sortDesc
		return axios.post(`/v1/query/observation/schedules`, query)
	},

	querySessions(query, ctx) {
		query.currentPage = ctx.currentPage
		query.perPage = ctx.perPage
		query.sortBy = ctx.sortBy
		query.sortDesc = ctx.sortDesc
		return axios.post(`/v1/query/observation/sessions`, query)
	},

	saveSession(session) {
		return axios.post(`/v1/observation/sessions`, session)
	},

	checkInSession(session) {
		return axios.post(`/v1/checkin/observation/sessions`, session)
	},

	getSessionCandidatesAndSchedules(id) {
		return axios.get(`/v1/observation/sessions/${id}/candidates+schedules`)
	},

	deleteSession(id) {
		return axios.delete(`/v1/observation/sessions/${id}`)
	},

	deleteSchedule(id) {
		return axios.delete(`/v1/observation/schedules/${id}`)
	},

	reExportSchedule(id) {
		return axios.post(`/v1/observation/schedules/${id}/reexport`)
	},

	getSession(id) {
		return axios.get(`/v1/observation/sessions/${id}`)
	},

	saveScore(score) {
		return axios.post(`/v1/observation/score`, score)
	},

	saveScores(scores) {
		return axios.post(`/v1/observation/scores`, scores)
	},

	getScoreBySchedule(scheduleID) {
		return axios.get(`/v1/observation/schedule/${scheduleID}/score`)
	},

	getScoresBySession(sessionID) {
		return axios.get(`/v1/observation/session/${sessionID}/scores`)
	},

	saveSectionProgress(sessionID, sectionID) {
		return axios.post(`/v1/observation/session/${sessionID}/section`, { string: sectionID })
	},

	completeSession(sessionID) {
		return axios.post(`/v1/observation/session/${sessionID}/complete`)
	},

	listSites(ctx) {
		return axios.get(`/v1/admin/observation/sites`, { params: ctx })
	},

	getSite(id) {
		return axios.get(`/v1/admin/observation/sites/${id}`)
	},

	saveSite(site) {
		return axios.post(`/v1/admin/observation/sites`, site)
	},

	removeSite(id) {
		return axios.delete(`/v1/admin/observation/sites/${id}`)
	},

	listSchedules(ctx) {
		return axios.get(`/v1/admin/observation/shedules`, { params: ctx })
	},

	listSessions(ctx) {
		return axios.get(`/v1/admin/observation/sessions`, { params: ctx })
	},

	getForm(id) {
		return axios.get(`/v1/admin/observation/forms/${id}`)
	},

	isFormScored(id) {
		return axios.get(`/v1/admin/observation/form_scored/${id}`)
	},

	saveForm(form) {
		return axios.post(`/v1/admin/observation/forms`, form)
	},

	removeForm(id) {
		return axios.delete(`/v1/admin/observation/forms/${id}`)
	},

	listForms(ctx) {
		return axios.get(`/v1/admin/observation/forms`, { params: ctx })
	},

	saveAudioFile(form) {
		return axios.post("/v1/admin/observation/form/audio", form, {
			headers: { "Content-Type": undefined },
			// onUploadProgress: progressFunc
		});
	},

	markNoShows(sessionID, noShowIDs) {
		return axios.post(`/v1/observation/session/${sessionID}/noshows`, noShowIDs)
	}
}