<template>
	<div class="flex d-flex flex-column">
		<div class="flex d-flex flex-row flex-wrap">
			<list-table
				:label="leftLabel"
				:search="leftSearch"
				:items="leftItems"
				:fields="leftFields"
				:control="leftControl"
			>
				<template v-for="(slot, scopedSlot) in slotPairings('left')" v-slot:[scopedSlot]="data">
					<slot :name="slot" v-bind="data"></slot>
				</template>
			</list-table>
			<list-table
				:label="rightLabel"
				:search="rightSearch"
				:items="rightItems"
				:fields="rightFields"
				:control="rightControl"
			>
				<template v-for="(slot, scopedSlot) in slotPairings('right')" v-slot:[scopedSlot]="data">
					<slot :name="slot" v-bind="data"></slot>
				</template>
			</list-table>
		</div>
	</div>
</template>

<script>
import ListTable from "./ListTable";

export default {
	components: { ListTable },
	name: "DualTable",
	props: {
		leftLabel: { type: String, default: "Left Table" },
		leftSearch: { type: String, default: "Search..." },
		leftItems: {
			default: () => {
				return [];
			},
		},
		leftFields: { type: Array, default: [] },
		leftControl: {
			type: Object,
			default: {
				totalRows: 0,
				currentPage: 1,
				perPage: 20,
				filter: "",
				sortBy: "",
				sortDesc: false,
				busy: false,
			},
		},
		rightLabel: { type: String, default: "Right Table" },
		rightSearch: { type: String, default: "Search..." },
		rightItems: {
			default: () => {
				return [];
			},
		},
		rightFields: { type: Array, default: [] },
		rightControl: {
			type: Object,
			default: {
				totalRows: 0,
				currentPage: 1,
				perPage: 20,
				filter: "",
				sortBy: "",
				sortDesc: false,
				busy: false,
			},
		},
	},

	methods: {
		slotPairings(prefix) {
			const scopedSlots = {};
			_.each(this.$scopedSlots, (_, slotName) => {
				const parts = slotName.substr(5, slotName.length - 6).split(":");

				if (parts[0] == prefix) {
					scopedSlots[`cell(${parts[1]})`] = slotName;
				}
			});
			return scopedSlots;
		},
	},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.page-footer {
	position: absolute;
	bottom: 0;
	width: 100%;
}
::-webkit-scrollbar {
	width: 7px;
}
::-webkit-scrollbar-thumb {
	border-radius: 4px;
	background-color: rgba(0, 0, 0, 0.2);
	-webkit-box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
}
</style>
