<template>
	<div class="tab-pane h-100" :class="{ active: defaultTab, show: defaultTab }">
		<div class="flex-v h-100">
			<div class="p-4 b-b _600 nav-title flex-fixed">{{ $t("TrainingEdit.Sections.title") }}</div>

			<div class="flex-grow flex-v">
				<dual-table
					:leftLabel="$t('TrainingEdit.Sections.assign_items')"
					:leftSearch="$t('TrainingEdit.Sections.search_assigned')"
					:leftItems="assignedSections"
					:leftFields="hasFields"
					:leftControl="hasControl"
					:rightLabel="$t('TrainingEdit.Sections.add')"
					:rightSearch="$t('TrainingEdit.Sections.search_available')"
					:rightItems="availableSections"
					:rightFields="canFields"
					:rightControl="canControl"
				>
					<!-- Actions -->
					<template #cell(left:actions)="{ item: section }">
						<nobr>
							<div class="mx-auto w30">
								<button
									class="btn btn-sm btn-icon btn-rounded red text-white m-0"
									@click="removeSection(section)"
									v-tippy
									title="Remove from Section"
								>
									<i class="far fa-minus"></i>
								</button>
							</div>
						</nobr>
					</template>

					<!-- Actions -->
					<template #cell(right:actions)="{ item: section }">
						<nobr>
							<div class="mx-auto w30">
								<button
									class="btn btn-sm btn-icon btn-rounded success text-white m-0"
									@click="addSection(section)"
									v-tippy
									title="Assign to Section"
								>
									<i class="far fa-plus"></i>
								</button>
							</div>
						</nobr>
					</template>
				</dual-table>
			</div>
		</div>
	</div>
</template>

<style scoped>
.w90 {
	width: 90px;
}
.w30 {
	width: 30px;
}
.loader {
	font-size: 128px;
	line-height: 128px;
	color: gray;
	background-color: lightgray;
	text-align: center;
}
</style>

<script>
import DualTable from "@/components/DualTable";
import draggable from "vuedraggable";

export default {
	name: "TrainingEditSections",

	props: {
		course: Object,
		defaultTab: Boolean,
		allSections: Array,
		value: Array,
	},

	emits: ["update:value"],

	components: {
		"dual-table": DualTable,
		draggable,
	},

	data() {
		return {
			sections: [],
			saveReqModal: false,
		};
	},

	computed: {
		assignedSections() {
			return this.value;
		},

		availableSections() {
			const { allSections, value } = this;
			return allSections.filter(({ id }) => !value.some((selectedSection) => selectedSection.id === id));
		},

		hasFields() {
			return [
				{
					key: "name",
					label: this.$i18n.t("fields.name"),
					sortable: true,
				},
				{ key: "ref_id", label: this.$i18n.t("fields.ref_id") },
				{
					key: "actions",
					label: this.$i18n.t("fields.actions"),
					thStyle: { "text-align": "center" },
					tdClass: "p-custom py-0 v-mid",
				},
			];
		},

		hasControl() {
			return {
				totalRows: 0,
				currentPage: 1,
				perPage: 10,
				filter: "",
				sortBy: "",
				sortDesc: false,
				emptyText: "",
			};
		},

		canFields() {
			return [
				{
					key: "name",
					label: this.$i18n.t("fields.name"),
					sortable: true,
				},
				{ key: "ref_id", label: this.$i18n.t("fields.ref_id") },
				{
					key: "actions",
					label: this.$i18n.t("fields.actions"),
					thStyle: { "text-align": "center" },
					tdClass: "p-custom py-0 v-mid",
				},
			];
		},

		canControl() {
			return {
				totalRows: this.availableSections.length,
				currentPage: 1,
				perPage: 10,
				filter: "",
				sortBy: "",
				sortDesc: false,
				emptyText: "",
			};
		},
	},

	methods: {
		emitSections(sections) {
			this.$emit("input", sections);
		},

		addSection(section) {
			this.emitSections(this.value.concat(section));
		},

		removeSection(section) {
			this.emitSections(this.value.filter(({ id }) => id !== section.id));
		},
	},
};
</script>
