<template>
	<div>
		<div class="lrn-box d-flex flex-column align-items-center">
			<h5 class="mb-3">This file cannot be displayed in the scoring viewer</h5>
			<a @click="download" class="btn btn-primary text-white">
				<i class="fas fa-2x fa-file-export my-2" />
				<h4>Download</h4>
				<div class="text-xxs text-muted">{{ niceFileName }}</div>
			</a>
		</div>
	</div>
</template>

<script>
import ResponseMediaService from "@/services/ResponseMediaService";

export default {
	name: "DownloadMedia",
	props: ["item", "page", "response"],
	data() {
		return {};
	},

	computed: {
		niceFileName() {
			let origFileName = this.page.media.value;
			let parts = origFileName.split(".");
			let ext = parts[parts.length - 1];
			let niceFileName = `${this.item.name} - ${this.response.ref_id}.${ext}`;
			return niceFileName;
		},
	},

	watch: {},

	created() {},

	mounted() {},

	methods: {
		download() {
			let url = this.page.media.signed_url;

			$.ajax({
				url: url,
				xhrFields: {
					responseType: "blob",
				},
				success: (data) => {
					var url = window.URL || window.webkitURL;
					var path = url.createObjectURL(data);
					const link = document.createElement("a");
					link.href = path;
					link.setAttribute("download", this.niceFileName);
					document.body.appendChild(link);
					link.click();
					document.body.removeChild(link);
				},
				error: (e) => {
					console.error(e);
				},
			});
		},
	},
};
</script>

<style scoped>
.lrn-box {
	margin-top: 20px;
	position: relative;
	margin-left: auto;
	margin-right: auto;
	padding: 20px;
	max-width: 1000px;
	-webkit-box-shadow: 3px 3px 11px 1px rgba(184, 182, 184, 1);
	-moz-box-shadow: 3px 3px 11px 1px rgba(184, 182, 184, 1);
	box-shadow: 3px 3px 11px 1px rgba(184, 182, 184, 1);
	background-color: white;
	color: black;
	font-family: "Times New Roman", Times, Georgia, serif;
}
</style>
