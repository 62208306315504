<template>
	<page customNavBar customBody>
		<template #navbar>
			<div class="navbar navbar-light bg-light b-b flex-nowrap flex-fixed">
				<div class="navbar-text nav-title flex" id="pageTitle">
					{{ $t("ReportDailyScoringMgmt.reporting") }}
					<span class="far fa-fw fa-angle-right"></span>{{ $t("ReportingSla.sla") }}
				</div>
			</div>
		</template>

		<div class="d-flex flex-column flex scroll-x scroll-y px-0">
			<div class="row mx-0 flex-fixed pt-3 box no-shrink" v-spinner-overlay="loading">
				<div class="col-12 col-lg-4">
					<div class="form-group">
						<label>{{ $t("ReportDailyScoringMgmt.project") }}</label>
						<config-select
							:options="projects"
							v-model="selectedProject"
							:mustBeFilled="true"
						></config-select>
					</div>
				</div>
				<div class="col-12 col-lg-4">
					<div class="form-group">
						<label>{{ $t("ReportDailyScoringMgmt.section") }}</label>
						<config-select
							:options="sectionOptions"
							v-model="selectedSection"
							:mustBeFilled="true"
						></config-select>
					</div>
				</div>
				<div class="col-12 col-lg-2">
					<div class="form-group">
						<label>{{ $t("fields.hours") }}</label>
						<config-select
							:options="hourOptions"
							v-model="selectedHour"
							:mustBeFilled="true"
							sortBy="id"
						></config-select>
					</div>
				</div>
				<div class="col-12 col-lg-2 d-flex flex-row align-items-end justify-content-end">
					<div class="d-flex flex-row align-items-end form-group">
						<button
							v-if="rows && rows.length > 0"
							class="btn btn-sm btn-icon btn-rounded theme-accent mx-1"
							style="padding-top: 10px"
							v-tippy
							:title="$t('tooltip.export')"
							@click="exportCSV()"
						>
							<i class="fas fa-file-export" style="margin-left: 2px"></i>
						</button>
						<div class="py-0 ml-1">
							<button
								class="btn btn-sm btn-secondary theme-accent btn-block flex-fixed"
								v-b-tooltip
								:disabled="!selectedProject"
								@click="runReport"
								:title="$t('tooltip.run_report')"
							>
								<i v-if="!running" class="far fa-clipboard-list"></i>
								<loading v-if="running" type="icon" />
								<span class="hidden-folded d-inline"
									>&nbsp;{{ $t("ReportDailyScoringMgmt.run_report") }}</span
								>
							</button>
						</div>
					</div>
				</div>
			</div>

			<div
				v-if="rows && rows.length > 0"
				class="flex d-flex flex-row box-shadow-3 pb-3 scroll-y scroll-x"
				style="min-height: 100px"
			>
				<div class="flex">
					<div class="card border-0 box-shadow-3">
						<report-table
							class="condensed-table mb-0"
							:fields="fields"
							:content="rows"
							per-page="15"
							:totals="totals"
							totalsClass="theme-lighten-3 text-on-color "
						>
							<template #header v-if="dateComputed">
								<b-col align-self="end">
									<h4 class="text-end pl-5" style="text-align: center">
										Last Calculated At: {{ dateComputed.toLocaleDateString() }}
									</h4>
								</b-col>
							</template>

							<template #cell(item)="{ item: { rows } }">
								<div v-for="{ item } in rows" :key="item.id">{{ item.name }}</div>
							</template>

							<template #cell(count)="{ item: { rows } }">
								<div v-for="{ item, count } in rows" :key="item.id">{{ count }}</div>
							</template>
						</report-table>
					</div>
				</div>
			</div>
			<div v-if="ranReport && rows && rows.length == 0">
				<h3 class="text-muted d-flex justify-content-center flex-row">
					{{ $t("ReportDaily.report_returned_no_results") }}
				</h3>
				<h3 class="text-muted d-flex justify-content-center flex-row">
					{{ $t("ReportDailyScoringMgmt.not_computed_yet") }}
				</h3>
			</div>
		</div>
	</page>
</template>

<script>
import ReportingService from "@/services/ReportingService";
import FileService from "@/services/FileService";
import ConfigService from "@/services/ConfigService";
import notie from "@/services/NotieService";

import ConfigSelect from "@/components/ConfigSelect";
import ReportTable from "@/components/ReportTable";

export default {
	name: "ReportSla",

	components: {
		ConfigSelect,
		ReportTable,
	},

	data() {
		return {
			projects: [],
			selectedProject: null,

			selectedSection: null,

			selectedHour: 120,

			slaReportData: {},

			loading: false,
			running: false,
			ranReport: false,

			rows: [],
		};
	},

	created() {
		this.getProjects();
	},

	computed: {
		sectionOptions() {
			const { allSectionOption, selectedProject } = this;
			return [allSectionOption].concat((selectedProject && selectedProject.sections) || []);
		},

		allSectionOption() {
			return {
				name: "All",
				id: "",
			};
		},

		hourOptions() {
			return [24, 48, 72, 96, 120].map((hour) => ({
				id: hour,
				name: `${hour}`,
			}));
		},

		dateComputed() {
			const {
				slaReportData: { updated_at },
			} = this;

			return new Date(updated_at);
		},

		fields() {
			const { $i18n } = this;
			return [
				{
					key: "margin-left",
					label: "",
					tdClass: "t-margin",
					thClass: "text-center",
				},
				{
					key: "hour",
					label: $i18n.t("fields.hours"),
				},
				{
					key: "item",
					label: $i18n.t("fields.item"),
				},
				{
					key: "count",
					label: $i18n.t("ReportingSla.num_resps"),
				},
			];
		},

		totals() {
			return {
				hour: "Total",
				rows: [
					{
						item: "",
						count: this.rows
							.map(({ rows }) => rows)
							.flat()
							.reduce((val, newRow) => val + newRow.count, 0),
					},
				],
			};
		},

		hoursPlusRowName() {
			return `${this.selectedHour.id}+`;
		},
	},

	methods: {
		async getProjects() {
			try {
				this.loading = true;
				const { data } = await ConfigService.listProjects();
				this.projects = data;
			} catch (e) {
				notie.error("Failed to get Projects & Sections", e);
			} finally {
				this.loading = false;
			}
		},

		async runReport() {
			try {
				const { selectedProject, selectedSection, selectedHour } = this;

				this.running = true;
				const { data } = await ReportingService.getSLAReportStats(
					selectedProject.id,
					selectedSection.id,
					selectedHour.id
				);
				this.slaReportData = data;
				this.ranReport = true;
			} catch (e) {
				notie.error("Failed to generate SLA Report", e);
			} finally {
				this.running = false;
				this.calcRows();
			}
		},

		calcRows() {
			const {
				slaReportData: { resp_count_at_hour },
				selectedHour,
			} = this;

			if (!resp_count_at_hour) return;

			this.rows = _.range(24, selectedHour.id + 1, 24)
				.concat(-1)
				.map((hour) => ({
					hour: hour === -1 ? this.hoursPlusRowName : hour,
					rows: (resp_count_at_hour[hour] || []).sort((a, b) => a.item.name.localeCompare(b.item.name)),
				}));
		},

		exportCSV() {
			const { rows, dateComputed, selectedProject, selectedSection } = this;

			const headers = ["Hours", "Item Name", "Number of Responses"];
			const body = rows.map(({ hour, rows }) => rows.map(({ item, count }) => [hour, item.name, count])).flat();

			const dateStr = dateComputed.toLocaleDateString().split("/").join(".");
			const timeStr = dateComputed.toLocaleTimeString("en-US").split(":").join(".").split(" ").join("");
			const selectedFilter = selectedSection.id === "" ? selectedProject.name : selectedSection.name;

			FileService.exportCSV([headers].concat(body), ["SLA_Report", selectedFilter, dateStr, timeStr]);
		},
	},
};
</script>

<style>
</style>