<template>
	<div class="card p-3 mx-3 mb-3" :class="{ clickable: !expanded }" @click="expanded = true">
		<h3 class="text-center clickable" :class="{ 'mb-0': !expanded }" @click.stop="expanded = !expanded">
			Tenant Options
		</h3>
		<h5
			v-if="expanded"
			class="refresh-button clickable mb-0"
			@click.stop="getTenantOptions"
			v-tippy
			title="Refresh"
		>
			<i class="fas fa-sync no-select" :class="{ 'fa-spin': running }" />
		</h5>
		<h3 class="expand-button clickable mb-0" @click.stop="expanded = !expanded">
			<i class="fas fa-caret-left anim-rotate no-select" :class="{ 'rotate-90': expanded }" />
		</h3>
		<div v-if="expanded" class="row">
			<table class="table condensed-table mb-0">
				<template v-for="account in accounts">
					<tr :key="account.id">
						<td style="width: 10px"></td>
						<td>
							<span class="pl-4 text-xxs text-muted">Account {{ account.id }}</span>
						</td>
						<th class="v-mid text-center">
							<span v-tippy title="Pearson Compensation Reports">P-Comp</span>
						</th>
						<th class="v-mid text-center"><span v-tippy title="IEA Integration">IEA</span></th>
						<th class="v-mid text-center"><span v-tippy title="Video Meetings">Video</span></th>
						<th class="v-mid text-center"><span v-tippy title="Altus">Altus</span></th>
						<th class="v-mid text-center"><span v-tippy title="Previewer">Previewer</span></th>
						<th class="v-mid text-center"><span v-tippy title="PEACS Integration">PEACS</span></th>
						<th class="v-mid text-center"><span v-tippy title="Active">Active</span></th>
						<td style="width: 10px"></td>
					</tr>

					<tr v-for="client in account.clients" :key="client.id">
						<td style="width: 10px"></td>
						<td>
							<div>
								{{ client.name }} <span class="text-xxs">({{ client.ref_id }})</span>
							</div>
						</td>
						<td class="v-mid text-center">
							<label class="md-check md-check-lg check-label">
								<input
									type="checkbox"
									v-model="client.pearson_comp"
									@input="setOption(client, 'pearson_comp', $event.target)"
									:disabled="running"
								/>
								<i class="theme-accent"></i>
							</label>
						</td>
						<td class="v-mid text-center">
							<label class="md-check md-check-lg check-label">
								<input
									type="checkbox"
									v-model="client.iea_integration"
									@input="setOption(client, 'iea_integration', $event.target)"
									:disabled="running"
								/>
								<i class="theme-accent"></i>
							</label>
						</td>
						<td class="v-mid text-center">
							<label class="md-check md-check-lg check-label">
								<input
									type="checkbox"
									v-model="client.video_meetings"
									@input="setOption(client, 'video_meetings', $event.target)"
									:disabled="running"
								/>
								<i class="theme-accent"></i>
							</label>
						</td>
						<td class="v-mid text-center">
							<label class="md-check md-check-lg check-label">
								<input
									type="checkbox"
									v-model="client.altus"
									@input="setOption(client, 'altus', $event.target)"
									:disabled="running"
								/>
								<i class="theme-accent"></i>
							</label>
						</td>
						<td class="v-mid text-center">
							<label class="md-check md-check-lg check-label">
								<input
									type="checkbox"
									v-model="client.previewer"
									@input="setOption(client, 'previewer', $event.target)"
									:disabled="running"
								/>
								<i class="theme-accent"></i>
							</label>
						</td>
						<td class="v-mid text-center">
							<label class="md-check md-check-lg check-label">
								<input
									type="checkbox"
									v-model="client.peacs_integration"
									@input="setOption(client, 'peacs_integration', $event.target)"
									:disabled="running"
								/>
								<i class="theme-accent"></i>
							</label>
						</td>
						<td class="v-mid text-center">
							<label class="md-check md-check-lg check-label">
								<input
									type="checkbox"
									v-model="client.active"
									@input="setOption(client, 'active', $event.target)"
									:disabled="running"
								/>
								<i class="theme-accent"></i>
							</label>
						</td>
						<td style="width: 10px"></td>
					</tr>
				</template>
			</table>
			<div v-if="!(accounts && accounts.length > 0)" class="w-100 my-3 mx-4 text-center">
				<loading v-if="running" type="header" />
				<h5 v-else class="mb-0 text-muted">No Tenants Found</h5>
			</div>
		</div>

		<b-modal id="ieaTenantIdModal" v-model="showIeaTenantIdModal" @hide="showIeaTenantIdModal = false">
			<template slot="modal-header">
				<div class="mx-3">
					<h5 class="modal-title">IEA Tenant ID</h5>
				</div>
			</template>

			<div class="pt-3" v-if="ieaTenantClient">
				<div class="row">
					<div class="col-12">
						<label>Oscar Tenant ID</label>
						<input class="form-control form-group" readonly type="text" :value="ieaTenantClient.ref_id" />
					</div>
					<div class="col-12">
						<label>IEA Tenant ID</label>
						<input class="form-control form-group" type="text" v-model="ieaTenantId" />
					</div>
					<b-col>
						<div><label>Use Prod CF Environment</label></div>
						<label
							class="ui-switch ui-switch-md theme-accent"
							v-tippy="{ theme: 'popover' }"
							title="Use Prod Env (otherwise Staging Env will be used)"
						>
							<input type="checkbox" v-model="useProdCfEnv" />
							<i></i>
						</label>
					</b-col>
				</div>
			</div>

			<template slot="modal-footer">
				<button class="mx-auto btn btn-secondary btn-lg" @click="showIeaTenantIdModal = false">
					{{ $t("buttons.cancel") }}
				</button>
				<button
					class="mx-auto btn btn-primary btn-lg"
					@click="initializeIeaTenant(ieaTenantClient, ieaTenantEl)"
				>
					{{ $t("buttons.okay") }}
				</button>
			</template>
		</b-modal>
	</div>
</template>

<style scoped>
.clickable {
	cursor: pointer;
}

.expand-button {
	position: absolute;
	top: 1rem;
	right: 1rem;
}

.refresh-button {
	position: absolute;
	top: 1.25rem;
	right: 3rem;
	opacity: 0.5;
}

.result-panel {
	background-color: #f7f7f7;
	border-radius: 10px;
	border: 1px solid #f0f0f0;
}
</style>

<script>
import axios from "axios";
import Store from "@/services/Store";
import Notie from "@/services/NotieService";
import _ from "lodash";

export default {
	name: "TenantOptions",
	props: ["environment", "clients"],
	components: {},

	data() {
		return {
			expanded: Store.get(this, "adminTasks.tenantOptions.expanded"),
			running: false,

			accounts: [],

			showIeaTenantIdModal: false,
			ieaTenantClient: null,
			ieaTenantEl: null,
			ieaTenantId: "",
			useProdCfEnv: false,
		};
	},
	created() {
		this.getTenantOptions();
	},
	watch: {
		expanded() {
			Store.set(this, "adminTasks.tenantOptions.expanded", this.expanded);
		},
		environment() {
			this.getTenantOptions();
		},
	},
	methods: {
		getTenantOptions() {
			let config = {};
			if (this.environment) {
				config.headers = {
					"X-Mongo-Connection": this.environment.connection,
					"X-Mongo-Database": this.environment.db,
				};
			}

			this.running = true;
			axios
				.get("/v1/admin_tasks/tenant_options", config)
				.then((r) => {
					this.running = false;
					let clients = r.data;

					// Collate clients into accounts
					let accounts = {};
					for (let client of clients) {
						if (!accounts[client.account_id]) {
							accounts[client.account_id] = {
								id: client.account_id,
								clients: [],
							};
						}
						accounts[client.account_id].clients.push(client);
					}

					// Sort accounts and clients
					this.accounts = Object.values(accounts);
					for (let i in this.accounts) {
						this.accounts[i].clients = _.sortBy(this.accounts[i].clients, ["ref_id"]);
					}
					accounts = _.sortBy(accounts, ["id"]);
				})
				.catch((e) => {
					this.running = false;
					console.error(e);
					Notie.error("Failed to list Tenants", e);
				});
		},

		setOption(client, option, el) {
			let value = el.checked;

			console.log(option, value, client.iea_credential_id);
			if (option == "iea_integration" && value == true && !client.iea_credential_id) {
				this.openIeaTenantIdModal(client, el);
				return;
			}

			let config = {};
			if (this.environment) {
				config.headers = {
					"X-Mongo-Connection": this.environment.connection,
					"X-Mongo-Database": this.environment.db,
				};
			}

			this.running = true;
			axios
				.post(`/v1/admin_tasks/tenant_options/${client.id}/${option}/${value}`, null, config)
				.then(() => {
					this.running = false;
					Notie.info(`Set \"${option}\" of \"${client.name}\" to ${value}`);
				})
				.catch((e) => {
					this.running = false;
					console.error(e);
					Notie.error("Failed to set tenant option", e);
					el.checked = !value;
				});
		},

		openIeaTenantIdModal(client, el) {
			this.ieaTenantClient = client;
			this.ieaTenantEl = el;
			this.ieaTenantId = this.ieaTenantClient.ref_id;
			this.showIeaTenantIdModal = true;
		},

		initializeIeaTenant(client, el) {
			let config = {};
			if (this.environment) {
				config.headers = {
					"X-Mongo-Connection": this.environment.connection,
					"X-Mongo-Database": this.environment.db,
				};
			}

			let ctx = {
				iea_tenant_id: this.ieaTenantId,
				use_prod_cf_env: this.useProdCfEnv,
			};

			this.running = true;
			axios
				.post(`/v1/admin_tasks/initialize_iea_tenant/${client.id}`, ctx, config)
				.then(() => {
					this.running = false;
					Notie.info(`Initialized IEA integration for \"${client.name}\"`);
				})
				.catch((e) => {
					this.running = false;
					console.error(e);
					Notie.error("Failed to initialize IEA integration", e);
					el.checked = false;
				})
				.finally(() => {
					this.showIeaTenantIdModal = false;
				});
		},
	},
};
</script>
