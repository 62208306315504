<template>
	<page customNavBar>
		<template #navbar>
			<div class="navbar navbar-light bg-light b-b">
				<a class="flex navbar-brand" style="cursor: default">
					<span v-if="set" @click="back()" style="cursor: pointer">{{ set.name }}</span>
					<i v-if="set" class="far fa-angle-right"></i>
					<span v-if="set && user">{{ user.first_name }} {{ user.last_name }}</span>
					<span v-if="!set">Loading</span>
					<i v-if="!set" class="far fa-sync-alt fa-spin" />

					<div class="position-relative dropright ml-2" style="display: inline-block" v-if="item">
						<a
							class="no-select"
							data-toggle="dropdown"
							v-tippy
							:title="$t('tooltip.resources')"
							aria-expanded="false"
						>
							<i class="far fa-lg fa-info-circle"></i>
						</a>
						<div class="dropdown-menu" style="line-height: 1.6">
							<div v-if="!item.resources || item.resources.length == 0">
								<h6 class="text-muted text-center">{{ $t("data_description.no_resources") }}</h6>
							</div>
							<template v-if="item.resources && item.resources.length > 0" v-for="res in item.resources">
								<a
									v-if="Utils.isRenderableResource(res)"
									:key="res.id"
									class="dropdown-item"
									@click="viewResource(item, res)"
								>
									<i
										class="far fa-lg faw"
										:class="{
											[fs.mimeTypeIcon(res.mime_type)]: true,
										}"
									></i>
									<label class="mb-0">{{ res.name }}</label>
								</a>
								<a
									v-if="!Utils.isRenderableResource(res) && !user.role.prevent_resource_download"
									:key="res.id"
									class="dropdown-item theme-hover d-flex flex-row align-items-center"
									@click="downloadResource(item, res)"
								>
									<i
										class="far fa-lg faw"
										:class="{
											[fs.mimeTypeIcon(res.mime_type)]: true,
										}"
									></i>
									<div class="flex d-flex flex-column">
										<div>
											<span class="flex">{{ res.name }}</span>
										</div>
										<div class="text-xxs text-muted mb-1" style="line-height: 0.8">
											{{ $t("ExternalResource.download_only") }}
										</div>
									</div>
									<i
										class="far ml-3 fa-lg fa-download text-extra-muted float-right"
										style="margin-right: -16px"
										@click="viewResource(res)"
									></i>
								</a>
								<div
									v-if="!Utils.isRenderableResource(res) && user.role.prevent_resource_download"
									:key="res.id"
									class="dropdown-item"
								>
									<div>
										<i
											class="far fa-lg faw"
											:class="{
												[fs.mimeTypeIcon(res.mime_type)]: true,
											}"
										></i>
										<span class="flex">{{ res.name }}</span>
									</div>
									<div class="text-xxs text-muted">
										{{ $t("ExternalResource.cannot_render_or_download") }}
									</div>
								</div>
							</template>
						</div>
					</div>
				</a>
				<!-- <a v-if="set" @click="back()" class="navbar-brand">
						{{set.name}} > {{user.first_name}}
						{{user.last_name}}
					</a>-->
				<div class="title-bar-button b-l ml-0 d-flex flex-column justify-content-center" @click="back()">
					{{ $t("buttons.back") }}
				</div>
			</div>
		</template>

		<div class="d-flex flex-row h100">
			<div class="col col-sm-3 scroll">
				<table v-show="!hideScores" class="table mb-0">
					<tbody v-if="item && traitMap">
						<tr>
							<td>{{ $t("QCUserSetReport.overall") }}</td>
							<td>
								<agreement-row :exact="exact" :adj="adj" :nonadj="nonadj" />
							</td>
							<td>
								<span
									v-tippy="{
										theme: 'popover',
										html: `#pf`,
										placement: 'right',
									}"
									class="_600"
									:class="{
										'text-success': reportData.stat && reportData.stat.state == 2,
										'text-danger': reportData.stat && reportData.stat.state == 3,
									}"
									>{{ fs.fixed1d(reportData.stat.percent) }}%</span
								>
								<div
									v-if="
										reportData.stat &&
										reportData.stat.explanations &&
										reportData.stat.explanations.length > 0
									"
									:id="`pf`"
									class="d-none p-0"
								>
									<table class="table table-hover mb-0">
										<tbody>
											<tr v-for="(expRow, i) in reportData.stat.explanations" :key="i">
												<td>{{ expRow.label }}</td>
												<td
													:class="{
														'text-danger': expRow.color == 1,
														'text-warning': expRow.color == 2,
														'text-success': expRow.color == 3,
													}"
												>
													{{ expRow.stat }}
												</td>
											</tr>
										</tbody>
									</table>
								</div>
							</td>
						</tr>
						<template v-for="(trait, i) in item.rubric.traits">
							<tr v-if="!(trait.separator || trait.is_parent)" :key="trait.id">
								<td>
									Trait {{ i + 1 }}:
									{{ trait.reported_name || trait.name }}
								</td>
								<td>
									<agreement-row
										:exact="traitMap[trait.id].exact"
										:adj="traitMap[trait.id].adj"
										:nonadj="traitMap[trait.id].nonadj"
									/>
								</td>
								<td>
									{{
										pc(
											traitMap[trait.id].exact,
											traitMap[trait.id].exact +
												traitMap[trait.id].adj +
												traitMap[trait.id].nonadj
										)
									}}%
								</td>
							</tr>
						</template>
					</tbody>
				</table>

				<table class="table">
					<thead>
						<tr>
							<th>{{ $t("QCUserSetReport.responses") }}</th>
							<th></th>
						</tr>
					</thead>
					<tbody>
						<tr
							class="pointer"
							:class="{ active: row == selectedRow }"
							v-for="row in ((reportData && reportData.responses) || []).filter(({ show }) => show)"
							:key="row.response.id"
							@click="selectedRow = row"
						>
							<td>{{ row.response.ref_id }}</td>
							<td v-if="row.response.true_score">
								<agreement-row
									:exact="row.response.exact"
									:adj="row.response.adj"
									:nonadj="row.response.nonadj"
								/>
							</td>
							<td v-if="!row.response.true_score">--</td>
						</tr>
					</tbody>
				</table>
			</div>
			<div v-if="selectedRow" class="col col-sm-9 light scroll">
				<div class="row light">
					<div class="col-sm-4" v-if="selectedRow.user_score">
						<score
							:compareScore="selectedRow.response.true_score"
							:score="selectedRow.user_score"
							:rubric="item && item.rubric"
							:type="set.qc_type + 10"
						/>
					</div>
					<div class="col-sm-4" v-if="!selectedRow.user_score">
						<div class="box h100">
							<div class="box-header light">
								<h3>{{ $t("QCUserSetReport.note") }}</h3>
							</div>
							<div class="box-body">
								<p class="m-0">{{ $t("QCUserSetReport.user_not_scored") }}</p>
							</div>
						</div>
					</div>
					<div class="col-sm-8">
						<div class="box h100">
							<div class="box-header light">
								<h3>{{ $t("QCUserSetReport.feedback") }}</h3>
							</div>
							<div class="box-body">
								<p class="m-0" v-html="selectedRow.response.feedback"></p>
							</div>
						</div>
					</div>
				</div>
				<div class="row scroll">
					<div class="col-sm-12">
						<media :response="selectedRow.response" :highlighter.sync="highlighter" />
					</div>
				</div>
			</div>
		</div>
	</page>
</template>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.scroll {
	overflow: auto;
}
.title-bar-button {
	margin: -8px -16px;
	width: 56px;
	height: 56px;
	text-align: center;
	cursor: pointer;
}
.title-bar-button:hover {
	background-color: rgba(0, 0, 0, 0.075);
}
.title-bar-button a {
	line-height: 56px;
}
</style>

<script>
import AuthService from "@/services/AuthService";
import QCService from "@/services/QCService";
import ScoringService from "@/services/ScoringService";
import Notie from "@/services/NotieService";
import Score from "../../components/Score";
import InlineScore from "@/components/InlineScore";
import AgreementRow from "@/components/AgreementRow";
import Media from "../../components/viewer/Media";
import UserService from "@/services/UserService";
import store from "../../services/Store";
import ResourceService from "../../services/ResourceService";
import fs from "../../services/FormatService";
import Utils from "../../services/Utils";

import $ from "jquery";

export default {
	name: "QCUserSetReport",
	data() {
		return {
			Utils: Utils,
			fs: fs,
			user: null,
			set: null,

			item: null,
			reportData: null,
			selectedRow: null,
			highlighter: null,

			traitMap: null,
			exact: 0,
			adj: 0,
			nonadj: 0,
			loading: true,

			userID: null,
			hideScores: false,
		};
	},
	components: { Score, InlineScore, AgreementRow, Media },
	async created() {
		store.set(this, "viewerScale", 3);
		this.loadData();
	},

	watch: {
		selectedRow() {
			this.$nextTick(() => {
				console.log(
					this.highlighter,
					this.selectedRow,
					this.selectedRow.response,
					this.selectedRow.response.true_score,
					this.selectedRow.response.true_score.annotations
				);
				Utils.throttleMathJAX();

				if (
					this.highlighter &&
					this.selectedRow &&
					this.selectedRow.response &&
					this.selectedRow.response.true_score &&
					this.selectedRow.response.true_score.annotations
				) {
					this.loadAnnotations(this.selectedRow.response.true_score.annotations);
				}
			});
		},
	},
	beforeRouteEnter(to, from, next) {
		next((vm) => {
			let fromReport = from.path.indexOf("qc_reporting") != -1;
			let fromDashboard = from.path.indexOf("dashboard") != -1;
			let fromPortfolio = from.path.indexOf("qc_portfolio") != -1;
			if (fromReport || fromDashboard || fromPortfolio) {
				vm.return = "back";
			} else {
				vm.return = "scoring";
			}

			next();
		});
	},
	methods: {
		async loadData() {
			try {
				const user = await AuthService.getUser();
				this.user = user;
				if (this.$route.query.userID) {
					this.userID = this.$route.query.userID;
				} else {
					this.userID = user.id;
				}

				const { data: set } = await QCService.getQCSet(this.$route.params.set_id);
				this.set = set;

				const [{ data: item }, { data: reportData }, { data: newUser }] = await Promise.all([
					ScoringService.getItem(set.item_id),
					QCService.getUserQcSetReport(set.id, this.userID),
					UserService.getUser(this.userID),
				]);

				this.item = item;

				this.user = newUser;

				this.reportData = reportData || { responses: [] };
				this.parseStats();
				this.loading = false;
				if (this.reportData.responses.length > 0) {
					this.selectedRow = this.reportData.responses[0];
					if (!this.selectedRow.show) {
						this.selectedRow = null;
					}
				}
				Utils.throttleMathJAX();
			} catch (err) {
				Notie.error("Failed to load Qc Set", err);
			}
		},

		parseStats() {
			//Build map of traitIds
			var traitMap = {};
			_.each(this.item.rubric.traits, (t) => {
				traitMap[t.id] = { exact: 0, adj: 0, nonadj: 0 };
			});

			if (!this.reportData) {
				this.reportData = { responses: [] };
			}

			_.each(this.reportData.responses || [], (resp) => {
				resp.response.exact = 0;
				resp.response.adj = 0;
				resp.response.nonadj = 0;
				if (!resp.response.true_score) {
					return;
				}
				_.each(resp.response.true_score.trait_scores, (ts) => {
					if (resp.user_score) {
						resp.show = true;
						_.each(resp.user_score.trait_scores, (uts) => {
							if (ts.trait_id == uts.trait_id) {
								let diff = Math.abs(ts.score - uts.score);
								if (diff == 0) {
									this.exact++;
									traitMap[uts.trait_id].exact++;
									resp.response.exact++;
								} else if (diff <= 1) {
									this.adj++;
									traitMap[uts.trait_id].adj++;
									resp.response.adj++;
								} else {
									this.nonadj++;
									traitMap[uts.trait_id].nonadj++;
									resp.response.nonadj++;
								}
							}
						});
					}
				});
			});

			this.reportData.responses = _.sortBy(this.reportData.responses || [], (r) => {
				return r.response.sequence;
			});

			this.traitMap = traitMap;
		},

		viewResource(item, resource) {
			window.open(`#/resource/${item.id}/${resource.id}`);
		},

		downloadResource(item, resource) {
			ResourceService.downloadResource(item, resource);
		},

		back() {
			if (this.return == "back") {
				this.$router.go(-1);
			} else {
				this.$router.push("/scoring");
			}
		},
		pc(num, den) {
			return ((num / den) * 100).toFixed(1);
		},

		loadAnnotations(annos) {
			if (!this.highlighter) {
				Notie.error("Can't load annotations", "An error occurred");
				return;
			}
			console.log("qcusersetreport set annotations", this.highlighter, this.highlighter.setAnnotations, annos);
			this.highlighter.setAnnotations(annos);
		},
	},
};
</script>
