<template>
	<div class="div">
		<navbar></navbar>
		<div id="logDisplay"></div>

		<div class="lrn-box d-flex flex-column div">
			<div
				style="padding-top: 8px; padding-bottom: 8px; text-align: center"
				class="d-flex flex-row align-items-center"
			>
				<div class="d-flex flex-row align-items-center">
					<h5 class="ml-3 mb-0 mr-3">
						<i class="fas fa-lg fa-chalkboard-teacher" />
					</h5>
					<div class="text-left">
						<h5 class="mb-0">{{ trainingScormDetails.course_name }}</h5>
						<div>{{ trainingScormDetails.element_name }}</div>
					</div>
				</div>
				<div
					class="ml-auto exit-button b-l mr-0 d-flex flex-column justify-content-center"
					@click="goHome()"
					v-tippy="{ placement: 'bottom' }"
					:title="$t('tooltip.exit')"
				>
					<!-- Exit -->
					<i class="fas fa-lg fa-times" />
				</div>
			</div>
			<loading v-if="loading" type="page" />
			<iframe
				name="API"
				ref="api"
				:src="scormApi.iframeSrc"
				@load="apiIframeLoaded"
				style="display: none"
				v-if="scormApi.iframeSrc != ''"
			></iframe>
			<iframe
				name="API_1484_11"
				ref="api_2004"
				:src="scormApi.iframeSrc"
				@load="apiIframeLoaded"
				style="display: none"
				v-if="scormApi.iframeSrc != ''"
			></iframe>
			<iframe id="module" :src="scormCourse.iframeSrc" class="scorm-module" allowfullscreen="true" />
		</div>
	</div>
</template>

<script>
import Navbar from "../global/Navbar.vue";
import TrainingScormService from "../../services/TrainingScormService";
import BB from "bluebird";

import { SCORM_PACKAGE_TYPES } from "@/services/Constants";

const MESSAGE = "only_one_active_tab_or_window_allowed_for_training"
var bc;

export default {
	name: "TrainingScormMedia",

	components: { Navbar },

	data() {
		let params = _.extend(this.$route.params, this.$route.query);
		return {
			loading: false,
			scormCourse: {
				iframeSrc: "",
				name: "",
				folder: "",
			},
			scormApi: {
				iframeSrc: "",
				S3Dir: "api/122/",
				folder: "",
			},
			trainingScormDetails: {
				scorm_data: "",
				score: "",
				status: "",
				element_name: "",
				course_name: "",
				cookie_restricted_cloudfront_url: "",
				scorm_element_folder: "",
				hash: "",
			},
			miscLmsData: {},
			params: params,
		};
	},

	created() {
		bc = new BroadcastChannel("oscar_broadcast_channel");
		bc.onmessage = (event) => {
			if (event.data == MESSAGE){
				console.log(event.data)
				this.$router.go(-1);
			}
		};
		bc.postMessage(MESSAGE)

		this.loading = true;
		BB.props({
			signedScormMedia: TrainingScormService.getSignedTrainingScormMedia(this.scormApi.S3Dir),
			trainingScormDetails: this.loadTrainingDetails(),
		}).then((results) => {
			this.signedScormMedia = results.signedScormMedia.data;
			this.trainingScormDetails = results.trainingScormDetails.data;
			//get the api
			this.scormApi.folder = this.scormApi.S3Dir;
			this.scormApi.signing_cookies = this.signedScormMedia.signing_cookies;
			const url = this.getRedirectURL(this.scormApi);
			this.scormApi.iframeSrc = url;
			this.loading = false;
		});
	},

	mounted() {
		window.addEventListener("message", this.recieveMessageEvent);
	},

	beforeDestroy() {
		window.removeEventListener("message", this.recieveMessageEvent);
		bc.close()
	},

	watch: {},

	computed: {
		preview() {
			return !this.params.course_id;
		},

		isArticulatePkg() {
			const { ARTICULATE_RISE, ARTICULATE_STORYLINE } = SCORM_PACKAGE_TYPES;
			return [ARTICULATE_RISE.id, ARTICULATE_STORYLINE.id].includes(this.trainingScormDetails.package_type);
		},
	},
	methods: {
		async loadTrainingDetails() {
			const { params } = this;
			if (this.preview) {
				return await TrainingScormService.getScormPreview(params.import_id);
			} else {
				const { course_id, element_id } = params;
				return await TrainingScormService.getTrainingScormDetails(course_id, element_id);
			}
		},

		apiIframeLoaded() {
			//initialize the scorm api with user scorm data - if it exists
			//then load the course
			BB.props({
				signedScormMedia: TrainingScormService.getSignedTrainingScormMedia(
					this.trainingScormDetails.scorm_element_folder
				),
			}).then((results) => {
				this.signedScormMedia = results.signedScormMedia.data;

				this.initializeScorm();

				//get the scorm content
				this.scormCourse.folder = this.trainingScormDetails.scorm_element_folder + "/";
				this.scormCourse.signing_cookies = this.signedScormMedia.signing_cookies;
				var url = this.getRedirectURL(this.scormCourse);
				this.scormCourse.iframeSrc = url;
			});
		},

		recieveMessageEvent(event) {
			switch (event.data[0]) {
				case "Get":
					this.LMSGetValue(event.data[1]);
					break;
				case "Set":
					this.LMSSetValue(event.data[1], event.data[2]);
					break;
				case "Terminate":
					this.goHome();
					break;
			}
		},

		send(msg) {
			this.$refs.api.contentWindow.postMessage(msg, this.trainingScormDetails.cookie_restricted_cloudfront_url);
			this.$refs.api_2004.contentWindow.postMessage(
				msg,
				this.trainingScormDetails.cookie_restricted_cloudfront_url
			);

			return false;
		},

		getRedirectURL(page) {
			if (!page.signing_cookies) return;

			let dir = encodeURIComponent(page.folder);
			let pol = "";
			let sig = "";
			let key = "";

			for (let cookie of page.signing_cookies) {
				if (cookie.Name == "CloudFront-Policy") {
					pol = cookie.Value;
				}
				if (cookie.Name == "CloudFront-Signature") {
					sig = cookie.Value;
				}
				if (cookie.Name == "CloudFront-Key-Pair-Id") {
					key = cookie.Value;
				}
			}

			let url = `/v1/redirect/content_package?dir=${dir}&pol=${pol}&sig=${sig}&key=${key}`;
			url += "&scorm=true";
			url += "&application_domain=" + window.location.hostname;
			console.log("REDIRECT URL");
			return url;
		},

		initializeScorm() {
			const initKeys = ["cmi.suspend_data", "cmi.core.lesson_status", "cmi.core.score.raw", "cmi.success_status"];
			initKeys.forEach(this.LMSGetValue);
		},

		LMSGetValue(key) {
			const { scorm_data, status, score, location } = this.trainingScormDetails;
			const scormData = {
				"cmi.suspend_data": scorm_data,
				"cmi.core.lesson_status": status,
				"cmi.success_status": status,
				"cmi.core.score.raw": score,
				"cmi.location": location,
			};

			let value = scormData[key];
			if (!value) value = this.miscLmsData[key];
			this.send(["Get", key, value || ""]);
		},

		LMSSetValue(varname, varvalue) {
			this.miscLmsData[varname] = varvalue;
			if (varname == "cmi.core.exit") {
				this.goHome();
			}
			if (!this.preview) {
				TrainingScormService.updateTrainingScormStat(
					this.trainingScormDetails.import_record_id,
					varname,
					varvalue
				);
				TrainingScormService.updateTrainingTime(
					this.trainingScormDetails,
					this.params.course_id,
					this.params.element_id
				);
			} else {
				console.log("in preview mode");
			}
			return "true";
		},

		goHome() {
			if (this.isArticulatePkg) {
				if (this.preview) {
					if (this.$route.query.courseID) {
						this.$router.push(`/training/${this.$route.query.courseID}`);
					} else {
						this.$router.push("/scorm_pool");
					}
				} else {
					this.$router.push("/my_training");
				}
			} else {
				this.$router.go(-1);
			}
		},
	},
};
</script>



<style scoped>
.exit-button {
	margin: -8px -16px;
	width: 64px;
	height: 64px;
	text-align: center;
	cursor: pointer;
}

.exit-button:hover,
.exit-button.show-print {
	background-color: rgba(0, 0, 0, 0.075);
}

.exit-button a {
	line-height: 64px;
}

.scorm-module {
	width: 100%;
	height: calc(100vh - 128px);
}
</style>













