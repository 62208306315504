<template>
	<div class="card p-3 mx-3 mb-3" :class="{ clickable: !expanded }" @click="expanded = true">
		<h3 class="text-center clickable" :class="{ 'mb-0': !expanded }" @click.stop="expanded = !expanded">
			Auth Accounts
		</h3>
		<h3 class="expand-button clickable mb-0" @click.stop="expanded = !expanded">
			<i class="fas fa-caret-left anim-rotate no-select" :class="{ 'rotate-90': expanded }" />
		</h3>
		<div v-if="expanded" class="row">
			<div class="col-12 col-sm-6">
				<div class="form-group">
					<label> Account Username </label>
					<input class="form-control" type="text" v-model="username" />
				</div>
				<div class="form-group">
					<label> Tenants granted access </label>
					<v-select :options="clients || []" v-model="selectedClients" multiple>
						<template slot="selected-option" slot-scope="option">
							<span>{{ option.name }}</span>
							<span class="ml-1 text-muted text-xxs">({{ option.ref_id }})</span>
						</template>
						<template slot="option" slot-scope="option">
							<span>{{ option.name }}</span>
							<div class="text-muted pb-1" style="line-height: 0.85rem">
								{{ option.ref_id }} • {{ option.id }}
							</div>
						</template>
					</v-select>
				</div>
				<div class="d-flex flex-row">
					<div class="flex">
						<label class="mt-2">Auth Method</label>
						<div class="d-flex flex-row align-items-center">
							<span
								class="mr-2"
								v-tippy="{ theme: 'popover' }"
								title="Basic Authorization (simple username/password)"
							>
								Basic
							</span>
							<label class="ui-switch ui-switch-md theme-accent">
								<input type="checkbox" v-model="useTokenMethod" />
								<i></i>
							</label>
							<span
								class="ml-2"
								v-tippy="{ theme: 'popover' }"
								title="OAuth-esque token grant (client must request a token from /v1/auth/token, then add it to the request header with X-Auth-Token)"
							>
								Token
							</span>
						</div>
					</div>
					<div class="align-self-end">
						<button v-if="!running" @click="newAuthAccount" class="btn btn-success">
							Create New Auth Account
						</button>
						<button v-if="running" class="btn btn-success" disabled>
							<loading type="icon" class="mr-1" />Running...
						</button>
					</div>
				</div>
			</div>
			<div v-if="result" class="col-12 col-sm-6">
				<hr class="d-sm-none" />
				<label>Result</label>
				<div class="result-panel p-3">
					<pre v-if="result" class="form-control mb-0">{{ result }}</pre>
				</div>
			</div>
		</div>
	</div>
</template>

<style scoped>
.clickable {
	cursor: pointer;
}
.expand-button {
	position: absolute;
	top: 1rem;
	right: 1rem;
}
.result-panel {
	background-color: #f7f7f7;
	border-radius: 10px;
	border: 1px solid #f0f0f0;
}
</style>

<script>
import axios from "axios";
import Store from "@/services/Store";
import Notie from "@/services/NotieService";
import ConfigDate from "@/components/ConfigDate";
import ConfigSelect from "@/components/ConfigSelect";
import UserTimezone from "@/components/UserTimezone";

export default {
	name: "AuthAccounts",
	props: ["environment", "clients"],
	components: { ConfigDate, ConfigSelect, UserTimezone },

	data() {
		return {
			expanded: Store.get(this, "adminTasks.authAccounts.expanded"),
			running: false,

			username: null,
			selectedClients: [],
			useTokenMethod: false,

			result: null,
		};
	},
	created() {},
	watch: {
		expanded() {
			Store.set(this, "adminTasks.authAccounts.expanded", this.expanded);
		},
	},
	methods: {
		newAuthAccount() {
			this.result = null;
			this.running = true;

			let ctx = {
				username: this.username,
				client_ref_ids: this.selectedClients.map((o) => o.ref_id),
				use_token_method: this.useTokenMethod,
			};

			let config = {};
			if (this.environment) {
				config.headers = {
					"X-Mongo-Connection": this.environment.connection,
					"X-Mongo-Database": this.environment.db,
				};
			}

			axios
				.post("/v1/admin_tasks/new_auth_account", ctx, config)
				.then((r) => {
					this.running = false;
					this.result = r.data;
				})
				.catch((e) => {
					this.running = false;
					console.error(e);
					Notie.error("Failed to add new auth account", e);
				});
		},
	},
};
</script>
