"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.VALID_QC_TYPES = exports.processScoresCSV = void 0;
function processScoresCSV(scoreCsv, scoresOnly) {
    let output = {
        requests: [],
        messages: [],
        num: 0,
        proceed: false,
    };
    let anyErrors = false;
    if (scoreCsv.length == 0 || scoreCsv.length == 1) {
        output.messages.push({
            message: "Could not parse input CSV",
            result: "",
            type: "error",
        });
        anyErrors = true;
    }
    // Ignore the first row, it should be a header
    scoreCsv.shift();
    scoreCsv.forEach((row, index) => {
        const out = processRow(row, index + 1, scoresOnly);
        if (out.req) {
            output.num++;
            output.requests.push(out.req);
        }
        else {
            anyErrors = true;
        }
        output.messages.push(...out.messages); // Could contain errors
    });
    // Figure out, based on the processed output, if the import requests should proceed to import or not
    output.proceed = output.requests.length > 0 && !anyErrors;
    return output;
}
exports.processScoresCSV = processScoresCSV;
const ResponseCSVElementMap = {
    section_ref_id: 0,
    item_ref_id: 1,
    set_name: 2,
    set_type: 3,
    response_ref_id: 4,
    response_text: 5,
    feedback: 6,
    set_sequence: 7,
    set_pass_percent: 8,
    qual_rule: 9,
    set_reviewable: 10,
    trait_scores: 11, // starting index of 2+ trait scores
};
const MIN_RESPONSE_N_COLS = 12;
const ScoresCSVElementMap = {
    section_ref_id: 0,
    item_ref_id: 1,
    set_name: 2,
    set_type: 3,
    response_ref_id: 4,
    feedback: 5,
    trait_scores: 6, // Starting index of 2+ trait scores
};
const MIN_SCORES_N_COLS = 7;
exports.VALID_QC_TYPES = [
    "non_qc",
    "practice",
    "qualification",
    "validity",
    "calibration",
    "rangefinding",
    "exemplar",
];
function validateScoresOnlyRow(row, rowNum) {
    const output = {
        valid: true,
        messages: [],
    };
    // Check if the min number of cols are present
    if (row.length < MIN_SCORES_N_COLS) {
        output.messages.push({
            message: `Line ${rowNum}: Not enough columns for QC scores only import`,
            result: `Response not processed`,
            type: "error",
        });
        output.valid = false;
    }
    else {
        // min colums satisfied
        // Check if vals conform to what is expected
        const sectionRefID = row[ScoresCSVElementMap["section_ref_id"]];
        const itemRefID = row[ScoresCSVElementMap["item_ref_id"]];
        const setType = row[ScoresCSVElementMap["set_type"]];
        const responseRefID = row[ScoresCSVElementMap["response_ref_id"]];
        if (!sectionRefID) {
            output.messages.push({
                message: `Line ${rowNum}: A non-blank Section Ref ID must be provided`,
                result: `Response not processed`,
                type: "error",
            });
            output.valid = false;
        }
        if (!itemRefID) {
            output.messages.push({
                message: `Line ${rowNum}: A non-blank Item Ref ID must be provided`,
                result: `Response not processed`,
                type: "error",
            });
            output.valid = false;
        }
        if (!setType) {
            output.messages.push({
                message: `Line ${rowNum}: A non-blank Set Type must be provided`,
                result: `Response not processed`,
                type: "error",
            });
            output.valid = false;
        }
        if (!responseRefID) {
            output.messages.push({
                message: `Line ${rowNum}: A non-blank Response Ref ID must be provided`,
                result: `Response not processed`,
                type: "error",
            });
            output.valid = false;
        }
        // Check the feedback for s3 paths
        // make sure it is valid s3 paths if specified
        const feedback = row[ScoresCSVElementMap["feedback"]].trim().toLowerCase();
        if (feedback.startsWith("s3://")) {
            // The s3 path must be a valid s3 path
            let s3PathSplit = feedback.split("/");
            if (s3PathSplit.length < 3 ||
                s3PathSplit[s3PathSplit.length - 1] == "" ||
                s3PathSplit[s3PathSplit.length - 1].split(".").length < 2) {
                output.messages.push({
                    message: `Line ${rowNum}: Feedback s3 path is not valid`,
                    result: `Response not processed`,
                    type: "error",
                });
                output.valid = false;
            }
        }
        // Check if set type is an acceptable value
        const lowerTrimmedSetType = setType.trim().toLowerCase();
        exports.VALID_QC_TYPES.some((value) => {
            value == lowerTrimmedSetType;
        });
        // check that all traitscores are valid numbers
        const rowLength = row.length;
        for (let i = ScoresCSVElementMap["trait_scores"]; i < rowLength; i++) {
            const traitScore = parseFloat(row[i]);
            if (isNaN(traitScore)) {
                // Check if the value is a blank string, if so assume 0 for the score, otherwise, push error message
                const traitString = row[i];
                if (traitString != "") {
                    output.messages.push({
                        message: `Line ${rowNum}: Trait Score in column ${i} is invalid. Trait Scores must either be blank or a valid number`,
                        result: `Response not processed`,
                        type: "error",
                    });
                    output.valid = false;
                }
            }
        }
    }
    return output;
}
function validateResponseRow(row, rowNum) {
    const output = {
        valid: true,
        messages: [],
    };
    // Check if the min number of cols are present
    if (row.length < MIN_RESPONSE_N_COLS) {
        output.messages.push({
            message: `Line ${rowNum}: Not enough columns for QC response import`,
            result: `Response not processed`,
            type: "error",
        });
        output.valid = false;
    }
    else {
        // min colums satisfied
        // Check if vals conform to what is expected
        const sectionRefID = row[ResponseCSVElementMap["section_ref_id"]];
        const itemRefID = row[ResponseCSVElementMap["item_ref_id"]];
        const setType = row[ResponseCSVElementMap["set_type"]];
        const responseRefID = row[ResponseCSVElementMap["response_ref_id"]];
        if (!sectionRefID) {
            output.messages.push({
                message: `Line ${rowNum}: A non-blank Section Ref ID must be provided`,
                result: `Response not processed`,
                type: "error",
            });
            output.valid = false;
        }
        if (!itemRefID) {
            output.messages.push({
                message: `Line ${rowNum}: A non-blank Item Ref ID must be provided`,
                result: `Response not processed`,
                type: "error",
            });
            output.valid = false;
        }
        if (!setType) {
            output.messages.push({
                message: `Line ${rowNum}: A non-blank Set Type must be provided`,
                result: `Response not processed`,
                type: "error",
            });
            output.valid = false;
        }
        if (!responseRefID) {
            output.messages.push({
                message: `Line ${rowNum}: A non-blank Response Ref ID must be provided`,
                result: `Response not processed`,
                type: "error",
            });
            output.valid = false;
        }
        // Check the response and feedback for s3 paths
        // make sure they are valid s3 paths if specified
        const responseText = row[ResponseCSVElementMap["response_text"]].trim().toLowerCase();
        const feedback = row[ResponseCSVElementMap["feedback"]].trim().toLowerCase();
        if (responseText.startsWith("s3://")) {
            // The s3 path must be a valid s3 path
            let s3PathSplit = responseText.split("/");
            if (s3PathSplit.length < 3 ||
                s3PathSplit[s3PathSplit.length - 1] == "" ||
                s3PathSplit[s3PathSplit.length - 1].split(".").length < 2) {
                output.messages.push({
                    message: `Line ${rowNum}: Feedback s3 path is not valid`,
                    result: `Response not processed`,
                    type: "error",
                });
                output.valid = false;
            }
        }
        if (feedback.startsWith("s3://")) {
            // The s3 path must be a valid s3 path
            let s3PathSplit = feedback.split("/");
            if (s3PathSplit.length < 3 ||
                s3PathSplit[s3PathSplit.length - 1] == "" ||
                s3PathSplit[s3PathSplit.length - 1].split(".").length < 2) {
                output.messages.push({
                    message: `Line ${rowNum}: Feedback s3 path is not valid`,
                    result: `Response not processed`,
                    type: "error",
                });
                output.valid = false;
            }
        }
        // Check if set type is an acceptable value
        const lowerTrimmedSetType = setType.trim().toLowerCase();
        exports.VALID_QC_TYPES.some((value) => {
            value == lowerTrimmedSetType;
        });
        // check that all traitscores are valid numbers
        const rowLength = row.length;
        for (let i = ResponseCSVElementMap["trait_scores"]; i < rowLength; i++) {
            const traitScore = parseFloat(row[i]);
            if (isNaN(traitScore)) {
                // Check if the value is a blank string, if so assume 0 for the score, otherwise, push error message
                const traitString = row[i];
                if (traitString != "") {
                    output.messages.push({
                        message: `Line ${rowNum}: Trait Score in column ${i} is invalid. Trait Scores must either be blank or a valid number`,
                        result: `Response not processed`,
                        type: "error",
                    });
                    output.valid = false;
                }
            }
        }
    }
    return output;
}
function validateRow(row, rowNum, scoresOnly) {
    const output = {
        valid: true,
        messages: [],
    };
    // Does the row have enough data?
    if (row.length == 0) {
        output.messages.push({
            message: `Line ${rowNum}: Not enough columns`,
            result: `Response not processed`,
            type: "error",
        });
        output.valid = false;
    }
    else {
        if (scoresOnly) {
            const validationOutput = validateScoresOnlyRow(row, rowNum);
            if (!validationOutput.valid) {
                output.messages.push(...validationOutput.messages);
                output.valid = false;
            }
        }
        else {
            // !scoresOnly
            const validationOutput = validateResponseRow(row, rowNum);
            if (!validationOutput.valid) {
                output.messages.push(...validationOutput.messages);
                output.valid = false;
            }
        }
    }
    return output;
}
function processScoresOnlyRow(row) {
    let request = {};
    // Expect 6+ elements in line if this is a scores only import (minimum of 2 traits needed otherwise everyone gets a 100!)
    request.section_ref_id = row[ScoresCSVElementMap["section_ref_id"]];
    request.item_ref_id = row[ScoresCSVElementMap["item_ref_id"]];
    request.set_name = row[ScoresCSVElementMap["set_name"]];
    request.set_type = row[ScoresCSVElementMap["set_type"]];
    request.response_ref_id = row[ScoresCSVElementMap["response_ref_id"]];
    request.feedback = row[ScoresCSVElementMap["feedback"]];
    const rowLength = row.length;
    request.trait_scores = [];
    for (let i = ScoresCSVElementMap["trait_scores"]; i < rowLength; i++) {
        // If the row is blank, assume the score is zero
        if (row[i] == "") {
            request.trait_scores.push(0);
        }
        else {
            const traitScore = parseFloat(row[i]);
            request.trait_scores.push(traitScore);
        }
    }
    return request;
}
function processResponseRow(row) {
    let request = {};
    // Expect 15+ elements in line if this is a response import (minimum of 2 traits needed otherwise everyone gets a 100!)
    request.section_ref_id = row[ResponseCSVElementMap["section_ref_id"]];
    request.item_ref_id = row[ResponseCSVElementMap["item_ref_id"]];
    request.set_name = row[ResponseCSVElementMap["set_name"]];
    request.set_type = row[ResponseCSVElementMap["set_type"]];
    request.response_ref_id = row[ResponseCSVElementMap["response_ref_id"]];
    request.set_sequence = row[ResponseCSVElementMap["set_sequence"]];
    request.set_pass_percent = row[ResponseCSVElementMap["set_pass_percent"]];
    request.qual_rule = row[ResponseCSVElementMap["qual_rule"]];
    request.set_reviewable = row[ResponseCSVElementMap["set_reviewable"]];
    request.response_text = row[ResponseCSVElementMap["response_text"]];
    request.feedback = row[ResponseCSVElementMap["feedback"]];
    const rowLength = row.length;
    request.trait_scores = [];
    for (let i = ResponseCSVElementMap["trait_scores"]; i < rowLength; i++) {
        // If the row is blank, assume the score is zero
        if (row[i] == "") {
            request.trait_scores.push(0);
        }
        else {
            const traitScore = parseFloat(row[i]);
            request.trait_scores.push(traitScore);
        }
    }
    return request;
}
function doProcessRow(row, scoresOnly) {
    let processedRequest = {};
    if (scoresOnly) {
        const request = processScoresOnlyRow(row);
        processedRequest = request;
    }
    else {
        const request = processResponseRow(row);
        processedRequest = request;
    }
    return processedRequest;
}
function processRow(row, rowNum, scoresOnly) {
    let output = {
        req: {},
        messages: [],
    };
    // Validate the row first. If not valid, return only messages
    const validationOutput = validateRow(row, rowNum, scoresOnly);
    if (!validationOutput.valid) {
        output.req = null;
        output.messages.push(...validationOutput.messages);
        return output;
    }
    // If validation succeeds, process the row
    const request = doProcessRow(row, scoresOnly);
    request.line = rowNum;
    output.req = request;
    return output;
}
