<template>
	<b-modal size="xl" :visible="visible" no-close-on-esc no-close-on-backdrop hide-header-close>
		<template slot="modal-title">{{ title }}</template>

		<div class="row p-4">
			<div class="col-12 form-group">
				<h5>{{ subtitle }}</h5>
			</div>

			<div class="col-13">
				<div class="form-group">
					<input ref="fileInput" class="d-none" type="file" @change="selectImportFile" />
					<button class="btn hover-darken theme-accent btn-block" @click="$refs.fileInput.click()">
						{{ $t("QCSetup.import_modal.select_file") }}
					</button>
				</div>
				<div class="form-group">
					<input type="text" class="form-control" :value="importingFile && importingFile.name" readonly />
				</div>
				<div class="form-group" v-if="importStep == 'file select'">
					<p>{{ $t("QCSetup.import_modal.things_to_remember.title") }}</p>
					<ul style="list-style-type: circle" class="mb-0">
						<li style="list-style-type: circle">
							{{ $t("QCSetup.import_modal.things_to_remember.only_csv") }}
						</li>
						<li style="list-style-type: circle">
							{{ $t("QCSetup.import_modal.things_to_remember.header_row") }}
						</li>
						<li style="list-style-type: circle">
							{{ $t("QCSetup.import_modal.things_to_remember.data_format") }}
						</li>
						<li v-if="!scoresOnly" style="list-style-type: circle">
							{{ $t("QCSetup.import_modal.things_to_remember.reviewable_set") }}
						</li>
						<li v-if="!scoresOnly" style="list-style-type: circle">
							{{ $t("QCSetup.import_modal.things_to_remember.qual_rule") }}
						</li>
					</ul>
					<table style="border: 0px solid transparent" class="my-2 mx-auto">
						<tr>
							<td class="p-1">{{ $t("fields.section_ref_id") }}</td>
							<td class="p-1">{{ $t("fields.item_ref_id") }}</td>
							<td class="p-1">{{ $t("fields.set_name") }}</td>
							<td class="p-1">{{ $t("fields.set_type") }}</td>
							<td class="p-1">{{ $t("fields.response_ref_id") }}</td>
							<td class="p-1" v-if="!scoresOnly">{{ $t("fields.response_text") }}</td>
							<td class="p-1">{{ $t("fields.feedback") }}</td>
							<td class="p-1" v-if="!scoresOnly">{{ $t("fields.set_sequence") }}</td>
							<td class="p-1" v-if="!scoresOnly">{{ $t("fields.set_pass_percent") }}</td>
							<td class="p-1" v-if="!scoresOnly">{{ $t("fields.qual_rule") }}</td>
							<td class="p-1" v-if="!scoresOnly">{{ $t("fields.reviewable_set") }}</td>
							<td class="p-1">{{ $t("fields.trait_1_true_score") }}</td>
							<td class="p-1">{{ $t("fields.trait_2_true_score") }}</td>
							<td class="p-1">{{ $t("fields.etc") }}</td>
						</tr>
						<tr>
							<td colspan="13" style="border: 0px solid transparent">
								<a class="float-right text-theme actual-link" :href="templatePath" download>{{
									$t("QCSetup.import_modal.things_to_remember.download_template")
								}}</a>
							</td>
						</tr>
					</table>
				</div>

				<div class="col-12 form-group" v-if="importStep != 'file select'">
					<div v-for="(error, i) in importMessages" :key="i">
						<span
							class="_600"
							:class="{
								'text-danger': error.type == 'error',
								'text-warning': error.type == 'warning',
							}"
							>{{ error.message }}</span
						>
						<span class="text-small text-muted">{{ error.result }}</span>
					</div>
				</div>
			</div>
		</div>

		<template slot="modal-footer">
			<button
				class="btn primary btn-flat"
				@click="stopImport"
				v-if="!(importStep == 'failed' || importStep == 'succeeded')"
			>
				{{ $t("buttons.cancel") }}
			</button>
			<button
				class="btn btn-flat"
				@click="importCsv"
				v-if="importStep == 'file select' || importStep == 'processed'"
				:disabled="!importingFile"
				:class="{ success: importingFile }"
			>
				Import
			</button>
			<button class="btn btn-flat" v-if="importStep == 'processing'" disabled>
				{{ $t("QCSetup.import_modal.processing") }}&nbsp;&nbsp;
				<loading type="icon" />
			</button>
			<button class="btn btn-flat" v-if="importStep == 'importing'" disabled>
				{{ $t("QCSetup.import_modal.importing") }}&nbsp;&nbsp;
				<loading type="icon" />
			</button>
			<button class="btn primary btn-flat" @click="stopImport" v-if="importStep == 'failed'">
				{{ $t("buttons.ok") }}
			</button>
			<button class="btn primary btn-flat" @click="stopImport" v-if="importStep == 'succeeded'">
				{{ $t("buttons.done") }}
			</button>
		</template>
	</b-modal>
</template>

<script>
import Notie from "@/services/NotieService";
import FileService from "@/services/FileService";
import QCService from "@/services/QCService";
import { processScoresCSV } from "@/utils/QCImportModalUtils";

export default {
	name: "QCImportModal",

	props: {
		visible: Boolean,
		scoresOnly: Boolean,
	},

	data() {
		return {
			importingFile: null,
			importStep: "file select",
			importDone: 0,
			importTotal: 1,
			importRequests: null,
			importMessages: [],
		};
	},

	methods: {
		stopImport() {
			this.importingFile = null;
			this.importMessages = [];
			this.importStep = "file select";
			this.importDone = 0;
			this.importTotal = 1;
			this.importRequests = null;
			this.close();
		},
		selectImportFile(event) {
			if (event.target.files[0]) {
				this.importingFile = event.target.files[0];
				this.validateImport(this.importingFile);
			}
		},
		close() {
			this.$emit("close");
		},

		validateImport(file) {
			if (file.type != "text/csv" && file.type != "application/vnd.ms-excel") {
				this.importingFile = null;
				Notie.error(this.$i18n.t("notie.must_be_csv"));
				return;
			}

			const validateFunc = this.scoresOnly ? QCService.validateImportScores : QCService.validateImportResponses;

			this.importStep = "processing";

			const reader = new FileReader();
			reader.onload = (e) => {
				console.log("File loaded");
				const usersCSV = FileService.CSVToArray(e.target.result);
				const processed = this.processScoresCSV(usersCSV);
				this.importMessages = processed.messages;
				this.importRequests = processed.requests;
				console.log(processed);

				if (processed.num == 0) {
					this.importMessages.push({
						message: "No valid responses to import",
						result: "",
						type: "error",
					});
				} else {
					this.importMessages.unshift({
						message: "",
						result: `Processing: ${processed.num}`,
					});
				}

				if (processed.proceed) {
					validateFunc(this.importRequests)
						.then((resp) => {
							let result = resp.data;

							if (result.success) {
								if (result.warnings && result.warnings.length > 0) {
									this.importMessages.push({
										message: `Warnings:`,
										type: "warning",
									});
									_.each(result.warnings, (warning) => {
										this.importMessages.push({
											message: `Line ${warning.line}:`,
											result: warning.message,
											type: "warning",
										});
									});
								}
								this.importMessages.push({
									message: `Ready to import`,
								});
								this.importStep = "processed";
							} else {
								this.importMessages.push({
									message: `Errors:`,
									type: "error",
								});
								_.each(result.errors, (error) => {
									this.importMessages.push({
										message: `Line ${error.line}:`,
										result: error.message,
										type: "error",
									});
								});
								this.importMessages.push({
									message: `Please fix these errors and upload again`,
									type: "error",
								});
								this.importStep = "failed";
							}
						})
						.catch((err) => {
							this.importMessages.push({
								message: Notie.extractErrorMessage(err),
								result: "Import aborted",
								type: "error",
							});
							this.importStep = "failed";
						});
				} else {
					this.importStep = "failed";
				}
			};

			reader.readAsText(file);
		},

		importCsv() {
			this.importStep = "importing";
			const importFunc = this.scoresOnly ? QCService.importScores : QCService.importResponses;

			importFunc(this.importRequests)
				.then((resp) => {
					let result = resp.data;
					if (result.success) {
						this.importMessages = [
							{
								result: `${result.created} created, ${result.updated} updated`,
							},
							{
								message: "Import successful",
							},
						];
						this.importStep = "succeeded";
						Notie.success("Import successful", `${result.created} created, ${result.updated} updated`);
						this.importModal = false;
					} else {
						this.importStep = "failed";
					}
				})
				.catch((err) => {
					this.importMessages = [
						{
							message: Notie.extractErrorMessage(err),
							result: "Import failed",
							type: "error",
						},
					];
					this.importStep = "failed";
				});
		},

		processScoresCSV(scoreCsv) {
			return processScoresCSV(scoreCsv, this.scoresOnly);
		},
	},

	computed: {
		title() {
			const { scoresOnly, $i18n } = this;
			if (scoresOnly) {
				return $i18n.t("QCSetup.import_modal.title_scores");
			}
			return $i18n.t("QCSetup.import_modal.title");
		},

		subtitle() {
			const { scoresOnly, $i18n } = this;
			if (scoresOnly) {
				return $i18n.t("QCSetup.import_modal.batch_import_scores");
			}
			return $i18n.t("QCSetup.import_modal.batch_import");
		},

		templatePath() {
			if (this.scoresOnly) {
				return "/static/templates/OSCAR Template - Import Scores.csv";
			}
			return "/static/templates/OSCAR Template - Import Responses.csv";
		},
	},
};
</script>
