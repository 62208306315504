<template>
	<page customNavBar>
		<template #navbar>
			<div class="navbar navbar-light bg-light b-b">
				<a class="navbar-brand">
					{{ $t("QCSetup.title") }}
					<loading v-if="loading" type="icon" />
				</a>
				<div class="py-0 d-flex flex-row">
					<button
						v-if="selectedSection && selectedItem"
						@click="exportToCSV"
						class="btn btn-sm btn-secondary mr-3"
					>
						<i class="far fa-fw fa-file-csv"></i>
						<span class="hidden-folded d-inline">{{ $t("QCSetup.export_to_csv") }}</span>
					</button>
					<b-dropdown :text="$t('QCSetup.import')" toggle-class="theme-accent">
						<b-dropdown-item @click="importModal = true">
							<i class="far fa-fw fa-file-plus"></i>
							<span class="hidden-folded d-inline">{{ $t("QCSetup.import_responses") }}</span>
						</b-dropdown-item>
						<b-dropdown-item @click="showImportScores = true">
							<i class="far fa-fw fa-box-check"></i>
							<span class="hidden-folded d-inline">{{ $t("QCSetup.import_scores") }}</span>
						</b-dropdown-item>
					</b-dropdown>
				</div>
			</div>
		</template>
		<div class>
			<div data-v-14f1ec3c class="row mx-0 no-grow px-2 pt-3 b-b box mb-0">
				<div class="col-12 col-sm-6 d-flex align-items-center pb-3">
					<label class="no-grow mb-0 mr-3">{{ $t("QCSetup.section") }}</label>
					<div class="flex">
						<config-select
							:nullText="$t('data_description.no_sections')"
							:options="sections"
							v-model="selectedSection"
							:mustBeFilled="true"
						></config-select>
					</div>
				</div>

				<div class="col-12 col-sm-6 d-flex align-items-center pb-3">
					<label class="no-grow mb-0 mr-3">{{ $t("QCSetup.item") }}</label>
					<div class="flex">
						<config-select
							:nullText="$t('data_description.no_items')"
							:options="items"
							v-model="selectedItem"
							:mustBeFilled="true"
						></config-select>
					</div>
				</div>
			</div>

			<div class="row no-gutters white nav-active-bg">
				<div class="card-header" style="width: 100%">
					<ul class="nav nav-tabs card-header-tabs">
						<li class="nav-item">
							<a :class="{ active: qc_type == 6 }" class="nav-link no-select" @click="qc_type = 6">{{
								$t("QCSetup.exemplar")
							}}</a>
						</li>
						<li class="nav-item">
							<a :class="{ active: qc_type == 1 }" class="nav-link no-select" @click="qc_type = 1">{{
								$t("QCSetup.practice")
							}}</a>
						</li>
						<li class="nav-item">
							<a :class="{ active: qc_type == 2 }" class="nav-link no-select" @click="qc_type = 2">{{
								$t("QCSetup.qualification")
							}}</a>
						</li>
						<li class="nav-item">
							<a :class="{ active: qc_type == 3 }" class="nav-link no-select" @click="qc_type = 3">{{
								$t("QCSetup.validity")
							}}</a>
						</li>
						<li class="nav-item">
							<a :class="{ active: qc_type == 4 }" class="nav-link no-select" @click="qc_type = 4">{{
								$t("QCSetup.calibration")
							}}</a>
						</li>
					</ul>
				</div>
			</div>
			<div class="row no-gutters white">
				<!-- Exemplar -->
				<div class="col-sm-6" v-if="!loading && qc_type == 6">
					<div class="p-lg-3">
						<div class="box-header">
							<h3>
								{{ $t("QCSetup.exemplar") }}
								<span
									@click="newSet(6)"
									class="float-right pointer"
									v-tippy
									:title="$t('tooltip.add_exemplar_set')"
								>
									<i class="fal fa-plus"></i>
								</span>
							</h3>
						</div>
						<ul class="list inset" v-if="!exemplarSets.length">
							<li class="list-item">
								<div class="list-body">
									<div>{{ $t("data_description.no_sets_configured") }}</div>
								</div>
							</li>
						</ul>
						<ul class="list inset">
							<draggable
								v-model="exemplarSets"
								:options="{ handle: '.drag-handle' }"
								@sort="exemplarSorted"
							>
								<li class="list-item hover" v-for="set in exemplarSets" :key="set.id">
									<div class="m-auto px-2 drag-handle flex-fixed pointer">
										<i class="far fa-sort text-muted"></i>
									</div>
									<a>
										<span class="w-40 circle theme avatar">
											<span>{{ set.responses.length }}</span>
										</span>
									</a>
									<div class="list-body align-middle">
										<div class="align-middlea">
											<a>{{ set.name }}</a>
											<br />
											<a>
												<span v-if="set.active" class="text-success text-xxs">{{
													$t("data_description.active")
												}}</span>
												<span v-if="!set.active" class="text-muted text-xxs">{{
													$t("data_description.inactive")
												}}</span>
											</a>
										</div>
									</div>
									<div class="dropdown">
										<button class="btn" data-toggle="dropdown" aria-expanded="false">
											<i class="fas fa-ellipsis-v faw"></i>
										</button>
										<div
											class="dropdown-menu dropdown-menu-right w"
											x-placement="bottom-end"
											style="
												position: absolute;
												transform: translate3d(-70px, 35px, 0px);
												top: 0px;
												left: 0px;
												will-change: transform;
											"
										>
											<a class="dropdown-item" @click="modSet(set)">
												<i class="far fa-edit faw"></i>
												<label class="mb-0">{{ $t("QCSetup.edit_set") }}</label>
											</a>
											<a class="dropdown-item" :href="`#/qc_setup/set/${set.id}`">
												<i class="far fa-list faw"></i>
												<label class="mb-0">{{ $t("QCSetup.manage_responses") }}</label>
											</a>
											<a
												class="dropdown-item"
												:href="`#/qc_set_document/${selectedSection.id}/${selectedItem.id}/${set.id}`"
											>
												<i class="far fa-clipboard-list faw"></i>
												<label class="mb-0">{{ $t("QCSetup.view_as_document") }}</label>
											</a>
											<a class="dropdown-item" @click="copySetModal(set)">
												<i class="far fa-copy faw"></i>
												<label class="mb-0">{{ $t("QCSetup.copy_set") }}</label>
												<span class="text-xxs text-muted float-right"></span>
											</a>
											<a
												class="dropdown-item"
												@click="maybeDeleteSet(set)"
												data-toggle="modal"
												data-target="#setDeleteModal"
											>
												<i class="far fa-trash faw"></i>
												<label class="mb-0">{{ $t("QCSetup.delete") }}</label>
											</a>
										</div>
									</div>
								</li>
							</draggable>
						</ul>
					</div>
				</div>
				<!-- Training -->
				<div class="col-sm-6" v-if="!loading && qc_type == 1">
					<div class="p-lg-3">
						<div class="box-header">
							<h3>
								{{ $t("QCSetup.practice") }}
								<span
									@click="newSet(1)"
									class="float-right pointer"
									v-tippy
									:title="$t('tooltip.add_practice_set')"
								>
									<i class="fal fa-plus"></i>
								</span>
							</h3>
						</div>
						<ul class="list inset" v-if="!practiceSets.length">
							<li class="list-item">
								<div class="list-body">
									<div>{{ $t("data_description.no_sets_configured") }}</div>
								</div>
							</li>
						</ul>
						<ul class="list inset">
							<draggable
								v-model="practiceSets"
								:options="{ handle: '.drag-handle' }"
								@sort="practiceSorted"
							>
								<li class="list-item hover" v-for="set in practiceSets" :key="set.id">
									<div class="m-auto px-2 drag-handle flex-fixed pointer">
										<i class="far fa-sort text-muted"></i>
									</div>
									<a>
										<span class="w-40 circle theme avatar">
											<span>{{ set.responses.length }}</span>
										</span>
									</a>
									<div class="list-body align-middle">
										<div class="align-middlea">
											<a>{{ set.name }}</a>
											<br />
											<a>
												<span v-if="set.active" class="text-success text-xxs">{{
													$t("data_description.active")
												}}</span>
												<span v-if="!set.active" class="text-muted text-xxs">{{
													$t("data_description.inactive")
												}}</span>
											</a>
										</div>
									</div>
									<div class="dropdown">
										<button class="btn" data-toggle="dropdown" aria-expanded="false">
											<i class="fas fa-ellipsis-v faw"></i>
										</button>
										<div
											class="dropdown-menu dropdown-menu-right w"
											x-placement="bottom-end"
											style="
												position: absolute;
												transform: translate3d(-70px, 35px, 0px);
												top: 0px;
												left: 0px;
												will-change: transform;
											"
										>
											<a
												class="dropdown-item"
												data-toggle="modal"
												data-target="#setModal"
												@click="modSet(set)"
											>
												<i class="far fa-edit faw"></i>
												<label class="mb-0">{{ $t("QCSetup.edit_set") }}</label>
											</a>
											<a class="dropdown-item" :href="`#/qc_setup/set/${set.id}`">
												<i class="far fa-list faw"></i>
												<label class="mb-0">{{ $t("QCSetup.manage_responses") }}</label>
											</a>
											<a
												class="dropdown-item"
												:href="`#/qc_set_document/${selectedSection.id}/${selectedItem.id}/${set.id}`"
											>
												<i class="far fa-clipboard-list faw"></i>
												<label class="mb-0">{{ $t("QCSetup.view_as_document") }}</label>
											</a>
											<a class="dropdown-item" @click="copySetModal(set)">
												<i class="far fa-copy faw"></i>
												<label class="mb-0">{{ $t("QCSetup.copy_set") }}</label>
												<span class="text-xxs text-muted float-right"></span>
											</a>
											<a
												class="dropdown-item"
												@click="maybeDeleteSet(set)"
												data-toggle="modal"
												data-target="#setDeleteModal"
											>
												<i class="far fa-trash faw"></i>
												<label class="mb-0">{{ $t("QCSetup.delete") }}</label>
											</a>
										</div>
									</div>
								</li>
							</draggable>
						</ul>
					</div>
				</div>
				<!-- Qual -->
				<div class="col-sm-6" v-if="!loading && qc_type == 2">
					<div class="p-lg-3">
						<div class="box-header">
							<h3>
								{{ $t("QCSetup.qualification") }}
								<span
									@click="newSet(2)"
									class="float-right pointer"
									v-tippy
									:title="$t('tooltip.add_qualification_set')"
								>
									<i class="fal fa-plus"></i>
								</span>
							</h3>
						</div>
						<ul class="list inset" v-if="!qualSets.length">
							<li class="list-item">
								<div class="list-body">
									<div>{{ $t("data_description.no_sets_configured") }}</div>
								</div>
							</li>
						</ul>
						<ul class="list inset">
							<draggable v-model="qualSets" :options="{ handle: '.drag-handle' }" @sort="qualSorted">
								<li class="list-item hover" v-for="set in qualSets" :key="set.id">
									<div class="m-auto px-2 drag-handle flex-fixed pointer">
										<i class="far fa-sort text-muted"></i>
									</div>
									<a>
										<span class="w-40 circle theme avatar">
											<span>{{ set.responses.length }}</span>
										</span>
									</a>
									<div class="list-body align-middle">
										<div class="align-middlea">
											<a>{{ set.name }}</a>
											<br />
											<a>
												<span v-if="set.active" class="text-success text-xxs">{{
													$t("data_description.active")
												}}</span>
												<span v-if="!set.active" class="text-muted text-xxs">{{
													$t("data_description.inactive")
												}}</span>
											</a>
										</div>
									</div>
									<div class="dropdown">
										<button class="btn" data-toggle="dropdown" aria-expanded="false">
											<i class="fas fa-ellipsis-v faw"></i>
										</button>
										<div
											class="dropdown-menu dropdown-menu-right w"
											x-placement="bottom-end"
											style="
												position: absolute;
												transform: translate3d(-70px, 35px, 0px);
												top: 0px;
												left: 0px;
												will-change: transform;
											"
										>
											<a
												class="dropdown-item"
												data-toggle="modal"
												data-target="#setModal"
												@click="modSet(set)"
											>
												<i class="far fa-edit faw"></i>
												<label class="mb-0">{{ $t("QCSetup.edit_set") }}</label>
											</a>
											<a class="dropdown-item" :href="`#/qc_setup/set/${set.id}`">
												<i class="far fa-list faw"></i>
												<label class="mb-0">{{ $t("QCSetup.manage_responses") }}</label>
											</a>
											<a
												class="dropdown-item"
												:href="`#/qc_set_document/${selectedSection.id}/${selectedItem.id}/${set.id}`"
											>
												<i class="far fa-clipboard-list faw"></i>
												<label class="mb-0">{{ $t("QCSetup.view_as_document") }}</label>
											</a>
											<a class="dropdown-item" @click="copySetModal(set)">
												<i class="far fa-copy faw"></i>
												<label class="mb-0">{{ $t("QCSetup.copy_set") }}</label>
												<span class="text-xxs text-muted float-right"></span>
											</a>
											<a
												class="dropdown-item"
												@click="maybeDeleteSet(set)"
												data-toggle="modal"
												data-target="#setDeleteModal"
											>
												<i class="far fa-trash faw"></i>
												<label class="mb-0">{{ $t("QCSetup.delete") }}</label>
											</a>
										</div>
									</div>
								</li>
							</draggable>
						</ul>
					</div>
				</div>
				<!-- Validity -->
				<div class="col-sm-6" v-if="!loading && qc_type == 3">
					<div class="p-lg-3">
						<div class="box-header">
							<h3>
								{{ $t("QCSetup.validity") }}
								<span
									@click="newSet(3)"
									class="float-right pointer"
									v-tippy
									:title="$t('tooltip.add_validity_set')"
								>
									<i class="fal fa-plus"></i>
								</span>
							</h3>
						</div>
						<ul class="list inset" v-if="!validitySets.length">
							<li class="list-item">
								<div class="list-body">
									<div>{{ $t("data_description.no_sets_configured") }}</div>
								</div>
							</li>
						</ul>
						<ul class="list inset">
							<draggable
								v-model="validitySets"
								:options="{ handle: '.drag-handle' }"
								@sort="validitySorted"
							>
								<li class="list-item hover" v-for="set in validitySets" :key="set.id">
									<div class="m-auto px-2 drag-handle flex-fixed pointer">
										<i class="far fa-sort text-muted"></i>
									</div>
									<a>
										<span class="w-40 circle theme avatar">
											<span>{{ set.responses.length }}</span>
										</span>
									</a>
									<div class="list-body align-middle">
										<div class="align-middlea">
											<a>{{ set.name }}</a>
											<br />
											<a>
												<span v-if="set.active" class="text-success text-xxs">{{
													$t("data_description.active")
												}}</span>
												<span v-if="!set.active" class="text-muted text-xxs">{{
													$t("data_description.inactive")
												}}</span>
											</a>
										</div>
									</div>
									<div class="dropdown">
										<button class="btn" data-toggle="dropdown" aria-expanded="false">
											<i class="fas fa-ellipsis-v faw"></i>
										</button>
										<div
											class="dropdown-menu dropdown-menu-right w"
											x-placement="bottom-end"
											style="
												position: absolute;
												transform: translate3d(-70px, 35px, 0px);
												top: 0px;
												left: 0px;
												will-change: transform;
											"
										>
											<a
												class="dropdown-item"
												data-toggle="modal"
												data-target="#setModal"
												@click="modSet(set)"
											>
												<i class="far fa-edit faw"></i>
												<label class="mb-0">{{ $t("QCSetup.edit_set") }}</label>
											</a>
											<a class="dropdown-item" :href="`#/qc_setup/set/${set.id}`">
												<i class="far fa-list faw"></i>
												<label class="mb-0">{{ $t("QCSetup.manage_responses") }}</label>
											</a>
											<a
												class="dropdown-item"
												:href="`#/qc_set_document/${selectedSection.id}/${selectedItem.id}/${set.id}`"
											>
												<i class="far fa-clipboard-list faw"></i>
												<label class="mb-0">{{ $t("QCSetup.view_as_document") }}</label>
											</a>
											<a class="dropdown-item" @click="copySetModal(set)">
												<i class="far fa-copy faw"></i>
												<label class="mb-0">{{ $t("QCSetup.copy_set") }}</label>
												<span class="text-xxs text-muted float-right"></span>
											</a>
											<a
												class="dropdown-item"
												@click="maybeDeleteSet(set)"
												data-toggle="modal"
												data-target="#setDeleteModal"
											>
												<i class="far fa-trash faw"></i>
												<label class="mb-0">{{ $t("QCSetup.delete") }}</label>
											</a>
											<a
												v-if="
													selectedItem &&
													selectedItem.emma_config &&
													selectedItem.emma_config.enabled
												"
												class="dropdown-item"
												@click="getEmmaScores(set)"
												data-toggle="modal"
												data-target="#emmaScoreModal"
											>
												<i class="far fa-bullseye faw"></i>
												<label class="mb-0">{{ $t("QCSetup.check_against_emma") }}</label>
											</a>
										</div>
									</div>
								</li>
							</draggable>
						</ul>
					</div>
				</div>
				<!-- Calibration -->
				<div class="col-sm-6" v-if="!loading && qc_type == 4">
					<div class="p-lg-3">
						<div class="box-header">
							<h3>
								{{ $t("QCSetup.calibration") }}
								<span
									@click="newSet(4)"
									class="float-right pointer"
									v-tippy
									:title="$t('tooltip.add_calibration_set')"
								>
									<i class="fal fa-plus"></i>
								</span>
							</h3>
						</div>
						<ul class="list inset" v-if="!calibrationSets.length">
							<li class="list-item">
								<div class="list-body">
									<div>{{ $t("data_description.no_sets_configured") }}</div>
								</div>
							</li>
						</ul>
						<ul class="list inset">
							<draggable
								v-model="calibrationSets"
								:options="{ handle: '.drag-handle' }"
								@sort="calibrationSorted"
							>
								<li class="list-item hover" v-for="set in calibrationSets" :key="set.id">
									<div class="m-auto px-2 drag-handle flex-fixed pointer">
										<i class="far fa-sort text-muted"></i>
									</div>
									<a>
										<span class="w-40 circle theme avatar">
											<span>{{ set.responses.length }}</span>
										</span>
									</a>
									<div class="list-body align-middle">
										<div class="align-middlea">
											<a>{{ set.name }}</a>
											<br />
											<a>
												<span v-if="set.active" class="text-success text-xxs">{{
													$t("data_description.active")
												}}</span>
												<span v-if="!set.active" class="text-muted text-xxs">{{
													$t("data_description.inactive")
												}}</span>
											</a>
										</div>
									</div>
									<div class="dropdown">
										<button class="btn" data-toggle="dropdown" aria-expanded="false">
											<i class="fas fa-ellipsis-v faw"></i>
										</button>
										<div
											class="dropdown-menu dropdown-menu-right w"
											x-placement="bottom-end"
											style="
												position: absolute;
												transform: translate3d(-70px, 35px, 0px);
												top: 0px;
												left: 0px;
												will-change: transform;
											"
										>
											<a
												class="dropdown-item"
												data-toggle="modal"
												data-target="#setModal"
												@click="modSet(set)"
											>
												<i class="far fa-edit faw"></i>
												<label class="mb-0">{{ $t("QCSetup.edit_set") }}</label>
											</a>
											<a class="dropdown-item" :href="`#/qc_setup/set/${set.id}`">
												<i class="far fa-list faw"></i>
												<label class="mb-0">{{ $t("QCSetup.manage_responses") }}</label>
											</a>
											<a
												class="dropdown-item"
												:href="`#/qc_set_document/${selectedSection.id}/${selectedItem.id}/${set.id}`"
											>
												<i class="far fa-clipboard-list faw"></i>
												<label class="mb-0">{{ $t("QCSetup.view_as_document") }}</label>
											</a>
											<a class="dropdown-item" @click="copySetModal(set)">
												<i class="far fa-copy faw"></i>
												<label class="mb-0">{{ $t("QCSetup.copy_set") }}</label>
												<span class="text-xxs text-muted float-right"></span>
											</a>
											<a
												class="dropdown-item"
												@click="maybeDeleteSet(set)"
												data-toggle="modal"
												data-target="#setDeleteModal"
											>
												<i class="far fa-trash faw"></i>
												<label class="mb-0">{{ $t("QCSetup.delete") }}</label>
											</a>
										</div>
									</div>
								</li>
							</draggable>
						</ul>
					</div>
				</div>
			</div>
		</div>

		<div class="modal" id="setDeleteModal" data-backdrop="static" data-keyboard="false">
			<div class="modal-dialog">
				<div class="modal-content" v-if="setToDelete">
					<div class="modal-header">
						<h5>{{ $t("modal.confirm_delete_title") }}</h5>
					</div>
					<div class="modal-body">
						{{ $t("modal.confirm_delete_set") }}
						<span class="text-danger">
							<strong>{{ setToDelete.name }}</strong>
						</span>
						{{ $t("special_chars.question_mark") }}
					</div>
					<div class="modal-footer">
						<button class="btn btn-flat" data-dismiss="modal">{{ $t("buttons.cancel") }}</button>
						<button @click="deleteSet()" class="btn btn-flat red">{{ $t("buttons.delete") }}</button>
					</div>
				</div>
			</div>
		</div>

		<div id="copySetModal" class="modal" data-backdrop="static" data-keyboard="false" style>
			<div class="modal-dialog modal-lg">
				<div class="modal-content" v-if="copySet">
					<div class="modal-header">
						<h5>{{ $t("QCSetup.copy_set_modal.title1") }}</h5>
					</div>
					<div class="modal-body">
						<div class="row">
							<div class="col col-sm-12">
								<h5>{{ $t("QCSetup.copy_set_modal.title2", { name: copySet.name }) }}</h5>
								<p>{{ $t("QCSetup.copy_set_modal.description") }}</p>
							</div>
							<div class="col col-sm-12">
								<p></p>
								<div class="form-group col-sm-12">
									<label class="active">{{ $t("QCSetup.copy_set_modal.duplicate_set_name") }}</label>
									<input type="text" class="form-control" v-model="copySetDest.name" />
								</div>
							</div>
							<div class="col-sm-12">
								<div class="form-group col-sm-6">
									<label>{{ $t("QCSetup.copy_set_modal.new_set_type") }}</label>
									<v-select
										class="v-select-apply"
										label="name"
										:options="qcTypes"
										v-model="copyType"
									></v-select>
								</div>
							</div>
							<div class="col-sm-12">
								<div class="form-group col-sm-6">
									<label>{{ $t("QCSetup.copy_set_modal.section") }}</label>
									<v-select
										class="v-select-apply"
										label="name"
										:options="copySections"
										v-model="copyToSection"
									></v-select>
								</div>
							</div>
							<div class="col-sm-12">
								<div class="col-sm-6">
									<label
										>{{ $t("QCSetup.copy_set_modal.item") }}
										<small class="text-muted">{{
											$t("QCSetup.copy_set_modal.matching_rubrics_only")
										}}</small></label
									>
									<v-select
										class="v-select-apply"
										label="name"
										:options="copyToSection.items"
										v-model="copyToItem"
									></v-select>
								</div>
							</div>
						</div>
					</div>
					<div class="modal-footer">
						<button data-dismiss="modal" class="btn modal-action modal-closebtn-flat">
							{{ $t("buttons.cancel") }}
						</button>
						<button data-dismiss="modal" @click="saveCopySet()" class="btn btn-flat btn-success">
							{{ $t("buttons.copy") }}
						</button>
					</div>
				</div>
			</div>
		</div>

		<qc-import-modal :visible="importModal" @close="closeRespImport" />
		<qc-import-modal scoresOnly :visible="showImportScores" @close="closeScoreImport" />

		<qc-set-modal
			v-if="setEditModal && editSet"
			:editSet="editSet"
			:selectedItem="selectedItem"
			@close="qcSetClose"
		/>

		<b-modal :visible="emmaSet != null" @hidden="emmaSet = null" size="lg">
			<template slot="modal-title">
				<div>{{ $t("QCSetup.true_scores_vs_emma_scores") }}</div>
				<div v-if="emmaSet" class="text-sm text-muted">{{ emmaSet.name }}</div>
			</template>
			<div class="py-4">
				<table
					v-if="emmaSetResponses"
					class="table mb-0"
					style="
						margin-left: -1rem;
						margin-right: -1rem;
						width: calc(100% + 2rem);
						max-width: calc(100% + 2rem);
					"
				>
					<thead>
						<tr>
							<th style="width: 50px"></th>
							<th>{{ $t("fields.response") }}</th>
							<th>{{ $t("fields.true_score") }}</th>
							<th>{{ $t("fields.emma_score") }}</th>
							<th style="width: 50px"></th>
						</tr>
					</thead>
					<tbody>
						<tr v-for="resp in emmaSetResponses" :key="resp.id">
							<td></td>
							<td>{{ resp.ref_id }}</td>
							<td class="v-mid">
								<inline-score
									:type="20"
									:rubric="selectedItem && selectedItem.rubric"
									tooltip
									:score="resp.true_score"
								/>
							</td>
							<td v-if="resp.emma_score" class="v-mid">
								<inline-score
									:type="30"
									:rubric="selectedItem && selectedItem.rubric"
									tooltip
									:score="resp.emma_score"
								/>
							</td>
							<td v-else-if="resp.emma_error" class="v-mid text-muted">{{ resp.emma_error }}</td>
							<td v-else class="v-mid text-muted">{{ $t("data_description.none_parentheses") }}</td>
							<td></td>
						</tr>
					</tbody>
				</table>
				<div v-else class="w-100 text-center">
					<loading type="large" />
				</div>
			</div>
			<template slot="modal-footer">
				<button class="btn primary btn-flat" @click="hideEmmaModal">OK</button>
			</template>
		</b-modal>
	</page>
</template>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.card-header {
	background-color: white;
}

.btn-label {
	padding: 0px 5px;
	line-height: 1.2;
}
.clickable {
	cursor: pointer;
}
.btn-subtle {
	opacity: 0.5;
	transition:
		opacity 0.15s,
		color 0.15s;
}
.btn-subtle:hover {
	opacity: 1;
	color: red !important;
}

.avatar {
	width: 50px;
	height: 50px;
}

table,
td,
th {
	border: 1px solid lightgray;
	text-align: center;
}
</style>

<script>
import AuthService from "@/services/AuthService";
import QCConfigService from "@/services/QCConfigService";
import ConfigService from "@/services/ConfigService";
import ConfigSelect from "@/components/ConfigSelect";
import QcImportModal from "@/components/QC/QcImportModal";
import QcSetModal from "@/components/QC/QcSetModal";
import Notie from "@/services/NotieService";
import fs from "@/services/FormatService";

import moment from "moment";
import draggable from "vuedraggable";
import $ from "jquery";
import BB from "bluebird";
import PercentInput from "@/components/PercentInput";
import InlineScore from "@/components/InlineScore";
import store from "../../services/Store";
import QCService from "@/services/QCService";
import FileService from "@/services/FileService";

export default {
	name: "QCSetup",
	data() {
		var QC_TYPE_PRACTICE = { name: this.$i18n.t("qc_types.practice"), id: 1 };
		var QC_TYPE_QUALIFICATION = { name: this.$i18n.t("qc_types.qualification"), id: 2 };
		var QC_TYPE_VALIDITY = { name: this.$i18n.t("qc_types.validity"), id: 3 };
		var QC_TYPE_CALIBRATION = { name: this.$i18n.t("qc_types.calibration"), id: 4 };
		var QC_TYPE_EXEMPLAR = { name: this.$i18n.t("qc_types.exemplar"), id: 6 };

		var qc_type = store.getDefault(this, "qc.setup.qc_type", 6);

		let sectionID = store.get(this, "audit.r.selectedSection");
		console.log("selectedSection", sectionID);

		let itemID = store.get(this, "audit.r.selectedItem");
		console.log("selectedItem", itemID);

		return {
			fs: fs,
			loading: true,
			user: null,
			sections: [],
			items: [],
			qcTypes: [QC_TYPE_PRACTICE, QC_TYPE_QUALIFICATION, QC_TYPE_VALIDITY, QC_TYPE_CALIBRATION, QC_TYPE_EXEMPLAR],

			qc_type: qc_type,

			selectedSection: null,
			selectedItem: null,

			editSet: null,
			setToDelete: null,
			templates: null,
			template: null,
			tempTemplate: null,
			savingTemplate: false,
			newTemplateName: null,

			exemplarSets: [],
			practiceSets: [],
			qualSets: [],
			validitySets: [],
			calibrationSets: [],

			copySet: null,
			copyType: QC_TYPE_PRACTICE,
			copySetDest: null,
			copyToSection: null,
			copyToItem: null,
			copySections: [],

			importModal: false,
			setEditModal: false,
			showImportScores: false,

			emmaSet: null,
			emmaSetResponses: null,
		};
	},
	components: { ConfigSelect, draggable, PercentInput, InlineScore, QcImportModal, QcSetModal },
	created() {
		AuthService.getUser().then((user) => {
			this.user = user;
			var ctx = {};
			this.showHiddenProjects = store.getDefault(this, "projects.showHiddenProjects");
			ctx.showHiddenProjects = this.showHiddenProjects;
			ConfigService.listSectionsShallow(ctx).then((resp) => {
				this.sections = resp.data.sections;
				this.selectedSection = store.resolveSelectedSection(this, this.sections, "audit.r.selectedSection");

				if (this.selectedSection) {
					this.items = this.selectedSection.items;
					this.selectedItem = store.resolveSelectedItem(this, this.selectedSection, "audit.r.selectedItem");
				}
			});
		});
	},
	watch: {
		selectedSection() {
			console.log("selectedSection changed");
			store.set(this, "audit.r.selectedSection", this.selectedSection ? this.selectedSection.id : null);
			this.items = this.selectedSection.items;
			this.selectedItem = store.resolveSelectedItem(this, this.selectedSection, "audit.r.selectedItem");
		},
		selectedItem() {
			console.log("selectedItem changed");
			if (!this.selectedItem) {
				store.set(this, "audit.r.selectedItem", null);
			}
			if (!this.selectedItem.validity_config) {
				ConfigService.getItem(this.selectedItem.id)
					.then((r) => {
						this.selectedItem = r.data;
						store.set(this, "audit.r.selectedItem", this.selectedItem.id);
						this.querySets();
					})
					.catch((e) => {
						console.error(e);
						Notie.error("Failed to load item", e);
					});
			}
		},
		qc_type() {
			store.set(this, "qc.setup.qc_type", this.qc_type);
		},
		copyToSection() {
			if (this.copyToSection && this.copyToSection.items && this.copyToSection.items.length > 0) {
				this.copyToItem = this.copyToSection.items[0];
			}
		},
	},

	methods: {
		copySetModal(set) {
			this.copySet = set;
			this.copySetDest = {};
			this.copySetDest.name = set.name + " copy";
			this.copySetDest.QCType = set.qc_type;
			this.copyToSection = this.selectedSection;
			this.copyToItem = this.selectedItem;

			this.copySections = [];
			_.each(this.sections, (section) => {
				let matchingRubricItems = _.filter(section.items, { rubric_id: this.selectedItem.rubric_id });
				if (matchingRubricItems.length > 0) {
					section.items = matchingRubricItems;
					this.copySections.push(section);
				}
			});

			$("#copySetModal").modal("show");
		},
		saveCopySet() {
			var dest_set = {};
			dest_set.name = this.copySetDest.name;
			dest_set.qc_type = this.copyType.id;
			dest_set.section_id = this.copyToSection.id;
			dest_set.item_id = this.copyToItem.id;

			dest_set.is_blind = this.copySet.is_blind;
			dest_set.auto_deliver = this.copySet.auto_deliver;
			dest_set.active = this.copySet.active;

			var request = {
				section_id: this.selectedSection.id,
				item_id: this.selectedItem.id,
				qc_set_id: this.copySet.id,
				qc_type: this.copySet.qc_type,
				dest_set: dest_set,
			};
			QCService.copySet(request)
				.then(() => {
					this.querySets();
				})
				.catch((err) => {
					console.log(err);
				});
		},
		newSet(type) {
			this.editSet = {
				active: false,
				maximum_non_adjacent: 0,
				pass_percent: 0,
				rule: null,
				trait_pass_percent: 0,
				weighted_range_max: 0,
				weighted_range_min: 0,
				weighted_threshold: 0,
				calibration_rules: [],
			};
			this.editSet.qc_type = type;
			this.editSet.client_id = this.selectedSection.client_id;
			this.editSet.item_id = this.selectedItem.id;
			this.editSet.section_id = this.selectedSection.id;
			var typeName = this.getSetTypeString(type);
			this.editSet.name = this.$i18n.t("QCSetup.new_set", { type: typeName });
			var relevantSets = this.getRelevantSets(type);
			this.editSet.sequence = relevantSets.length + 1;

			if (type == 6) {
				this.$set(this.editSet, "score_position", 1);
				this.$set(this.editSet, "anno_position", 1);
			}

			this.setEditModal = true;
		},
		modSet(set) {
			this.editSet = set;
			this.setEditModal = true;
		},
		getSetTypeString() {
			switch (this.editSet.qc_type) {
				case 6:
					return this.$i18n.t("QCSetup.exemplar");
					break;
				case 1:
					return this.$i18n.t("QCSetup.practice");
					break;
				case 2:
					return this.$i18n.t("QCSetup.qualification");
					break;
				case 3:
					return this.$i18n.t("QCSetup.validity");
					break;
				case 4:
					return this.$i18n.t("QCSetup.calibration");
					break;
				default:
					return "???";
					break;
			}
		},
		saveSet(set, noop) {
			QCConfigService.saveSet(set)
				.then(() => {
					Notie.success(this.$i18n.t("notie.set_saved"));
					if (!noop) {
						this.querySets();
					}
				})
				.catch((err) => {
					console.log(err);
					Notie.error(this.$i18n.t("notie.save_set_fail"), err);
				});
		},
		maybeDeleteSet(set) {
			this.setToDelete = set;
		},
		deleteSet() {
			QCConfigService.deleteSet(this.setToDelete)
				.then(() => {
					Notie.success(this.$i18n.t("notie.set_deleted"));
					this.querySets();
					$("#setDeleteModal").modal("hide");
				})
				.catch((err) => {
					console.log(err);
					Notie.error("Failed to delete QC set", err);
				});
		},

		querySets() {
			if (this.selectedItem && this.selectedSection) {
				QCConfigService.listQCSets(this.selectedSection.id, this.selectedItem.id, 0).then((resp) => {
					this.exemplarSets = [];
					this.practiceSets = [];
					this.qualSets = [];
					this.validitySets = [];
					this.calibrationSets = [];

					console.log(resp.data);
					_.each(resp.data, (set) => {
						if (set.qc_type == 6) {
							this.exemplarSets.push(set);
						}
						if (set.qc_type == 1) {
							this.practiceSets.push(set);
						}
						if (set.qc_type == 2) {
							this.qualSets.push(set);
						}
						if (set.qc_type == 3) {
							this.validitySets.push(set);
						}
						if (set.qc_type == 4) {
							this.calibrationSets.push(set);
						}
					});

					this.loading = false;
				});
			}
		},
		getRelevantSets(type) {
			switch (type) {
				case 1:
					return this.practiceSets;
					break;
				case 2:
					return this.qualSets;
					break;
				case 3:
					return this.validitySets;
					break;
				case 4:
					return this.calibrationSets;
					break;
				default:
					return [];
					break;
			}
		},
		//Sorting callbacks
		exemplarSorted(a, b, c) {
			var counter = 1;
			_.each(this.exemplarSets, (set) => {
				// if (set) {
				if (set.sequence != counter) {
					set.sequence = counter;
					this.saveSet(set, true);
				}
				// set.sequence = counter;
				counter++;
				// }
			});
		},

		practiceSorted(a, b, c) {
			var counter = 1;
			_.each(this.practiceSets, (set) => {
				// if (set) {
				if (set.sequence != counter) {
					set.sequence = counter;
					this.saveSet(set, true);
				}
				// set.sequence = counter;
				counter++;
				// }
			});
		},

		qualSorted(a, b, c) {
			var counter = 1;
			_.each(this.qualSets, (set) => {
				// if (set) {
				if (set.sequence != counter) {
					set.sequence = counter;
					this.saveSet(set, true);
				}
				// set.sequence = counter;
				counter++;
				// }
			});
		},
		validitySorted(a, b, c) {
			var counter = 1;
			_.each(this.validitySets, (set) => {
				// if (set) {
				if (set.sequence != counter) {
					set.sequence = counter;
					this.saveSet(set, true);
				}
				// set.sequence = counter;
				counter++;
				// }
			});
		},
		calibrationSorted(a, b, c) {
			var counter = 1;
			_.each(this.calibrationSets, (set) => {
				// if (set) {
				if (set.sequence != counter) {
					set.sequence = counter;
					this.saveSet(set, true);
				}
				// set.sequence = counter;
				counter++;
				// }
			});
		},

		exportToCSV() {
			QCConfigService.exportSetsToCSV(this.selectedSection.id, this.selectedItem.id)
				.then((r) => {
					let filename =
						this.selectedSection.ref_id +
						"_" +
						this.selectedItem.ref_id +
						"_" +
						moment().format("YY-MM-DD") +
						".csv";
					let csv = r.data;
					FileService.downloadText(filename, csv);
				})
				.catch((e) => {
					console.error(e);
					Notie.error("Failed to export sets", e);
				});
		},

		getEmmaScores(set) {
			this.emmaSet = set;

			BB.props({
				responses: QCConfigService.listSetResponses(set.id),
				emma: QCService.getEmmaScores(set.id),
			})
				.then((r) => {
					let resps = r.responses.data;
					let emmas = r.emma.data;
					console.log("SET RESPONSES", r.responses.data);
					console.log("EMMA SCORES", r.emma.data);

					// Associate EMMA scores with their matching responses
					for (let i = 0; i < resps.length; i++) {
						for (let j = 0; j < emmas.length; j++) {
							if (resps[i] && emmas[j] && resps[i].id == emmas[j].response_id) {
								if (emmas[j].score) {
									resps[i].emma_score = emmas[j].score;
								} else if (emmas[j].error) {
									resps[i].emma_error = emmas[j].error;
								}
							}
						}
					}

					this.emmaSetResponses = resps;
				})
				.catch((e) => {
					console.error(e);
					Notie.error("Failed to get EMMA scores", e);
				});
		},

		hideEmmaModal() {
			this.emmaSetResponses = null;
			this.emmaSet = null;
		},

		closeRespImport() {
			this.importModal = false;
			this.querySets();
		},

		closeScoreImport() {
			this.showImportScores = false;
			this.querySets();
		},

		qcSetClose() {
			this.setEditModal = false;
			this.querySets();
		},
	},
};
</script>
