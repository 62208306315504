export function respectPriority(trainingCourses) {
	let currentPriority;
	let currentDoNotAllowToTake;
	for (let course of trainingCourses) {
		if (currentPriority !== null && currentDoNotAllowToTake !== null) {
			if (course.priority == currentPriority && course.progress.overall_progress !== -1) {
				course.progress.DO_NOT_ALLOW_TO_TAKE = currentDoNotAllowToTake;
			}
		}
		currentPriority = course.priority;
		currentDoNotAllowToTake = course.progress.DO_NOT_ALLOW_TO_TAKE;
	}
	return trainingCourses;
}
