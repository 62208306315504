<template>
	<div class="d-flex flex-column h-100">
		<div class="px-3 d-flex flex-row align-items-center" :class="{ 'px-md-5': padWide }">
			<h3 class="flex my-2 _600">{{ mail.subject }}</h3>
			<div class="navbar pr-0" v-if="!sent">
				<ul class="nav nav-sm no-border">
					<!-- Reply -->
					<li class="nav-item" v-if="canReply()" @click="reply">
						<a class="nav-link text-muted" v-tippy title="Reply">
							<span class="nav-text">
								<i class="far fa-fw fa-reply-all"></i>
							</span>
						</a>
					</li>
					<!-- Archive -->
					<li class="nav-item b-l">
						<a
							v-if="!isArchived()"
							@click="changeTag('archive')"
							class="nav-link text-muted no-border"
							v-tippy
							title="Archive"
						>
							<span class="nav-text">
								<i class="far fa-fw fa-archive"></i>
							</span>
						</a>
						<a
							v-if="isArchived()"
							@click="changeTag('unarchive')"
							class="nav-link text-muted no-border"
							v-tippy
							title="Unarchive"
						>
							<span class="nav-text">
								<i class="far fa-fw fa-archive"></i>
							</span>
						</a>
					</li>
					<!-- Trash -->
					<li class="nav-item">
						<a
							v-if="!mail.important"
							@click="deleting = true"
							class="nav-link text-muted"
							v-tippy
							title="Delete"
						>
							<span class="nav-text">
								<i class="far fa-fw fa-trash"></i>
							</span>
						</a>
						<div v-if="mail.important" class="nav-link text-very-muted" v-tippy title="Cannot delete">
							<span class="nav-text">
								<i class="far fa-fw fa-trash"></i>
							</span>
						</div>
					</li>
					<!-- Labels -->
					<li class="nav-item dropdown">
						<a
							class="nav-link text-muted dropdown-toggle no-border"
							data-toggle="dropdown"
							v-tippy
							title="Label"
						>
							<span class="nav-text">
								<i class="fa fa-fw fa-tag"></i>
							</span>
						</a>
						<div class="dropdown-menu dropdown-menu-right mt-3" role="menu">
							<a v-for="label in labels" :key="label" @click="toggleLabel(label)" class="dropdown-item">
								<i v-if="isLabeled(label)" class="fa fa-dot-circle text-theme"></i>
								{{ label }}
							</a>
						</div>
					</li>
					<!-- To listing -->
					<li class="nav-item b-l d-md-none">
						<a @click="exit()" class="nav-link text-muted" v-tippy title="Go Back">
							<span class="nav-text">
								<i class="far fa-fw fa-window-close"></i>
							</span>
						</a>
					</li>
				</ul>
			</div>
		</div>
		<div class="b-b w-100"></div>
		<div class="flex d-flex flex-column scroll-y">
			<div class="d-flex flex-row" :class="{ 'panel-top': response && showResponse }">
				<div class="flex p-3" :class="{ 'px-md-5': padWide }">
					<div class="d-flex flex-row align-items-center">
						<span v-if="!sent" class="w-40 avatar circle" :class="{ blue: true }">{{
							initials(mail.from)
						}}</span>
						<span v-if="sent && mail.to.length == 1" class="w-40 avatar circle" :class="{ blue: true }">{{
							initials(mail.to[0].user)
						}}</span>
						<span v-if="sent && mail.to.length > 1" class="w-40 icon-avatar circle" :class="{ blue: true }">
							<i class="far fa-lg fa-users"></i>
						</span>
						<div class="ml-2">
							<span v-if="!sent" class="_500">from {{ getName(mail.from) }}</span>
							<span v-if="sent" class="_500">to {{ getNames(mail.to) }}</span>
							<span class="text-xs text-muted">at {{ getSentTime(mail.updated_at) }}</span>
						</div>
					</div>
					<div class="mt-3" v-html="mail.body">
						<!-- Mail body -->
					</div>
				</div>
				<div v-if="response && showResponse && score && mail.show_score" class="scroll-y b-l no-grow p-3">
					<score
						class="mb-0"
						:score="score"
						:itemID="response.item_id"
						:compareScore="mail.show_res_score && compScore"
						:rescore="rescore"
					></score>
				</div>
				<div v-if="response && showResponse && flag && mail.show_flag" class="scroll-y b-l no-grow p-3">
					<flag :flag="flag" :itemID="response.item_id" :hideActions="true" :rescore="rescore" />
				</div>
			</div>
			<div v-if="response && showResponse" class="b-b w-100"></div>
			<div v-if="response && showResponse" class="panel-bottom p-3" :class="{ 'px-md-5': padWide }">
				<media :response="response"></media>
			</div>
		</div>

		<b-modal id="deleteConfirm" :visible="deleting != false" v-if="deleting">
			<template slot="modal-title">Delete Mail</template>
			<div class="row p-4">Are you sure you want to delete this mail?</div>
			<template slot="modal-footer">
				<button class="btn primary btn-flat" @click="deleting = false">Cancel</button>
				<button class="btn danger btn-flat" @click="deleteMail">Delete</button>
			</template>
		</b-modal>
	</div>
</template>

<style scoped>
.panel-top {
	/* max-height: 33%; */
}
.panel-bottom {
	flex: 1;
}
</style>

<script>
import moment from "moment";
import Media from "@/components/viewer/Media";
import Score from "@/components/Score";
import Flag from "@/components/Flag";
import notie from "@/services/NotieService";
import MailService from "@/services/MailService";

export default {
	name: "MailView",
	props: {
		mail: { default: null },
		sent: { type: Boolean, default: false },
		padWide: { type: Boolean, default: false },
		labels: { default: null },
		currentUser: { default: null },
	},

	data() {
		return {
			response: null,
			score: null,
			flag: null,
			rescore: null,
			compScore: null,
			showResponse: true,
			deleting: false,
		};
	},
	components: { Media, Score, Flag },

	created() {},

	mounted() {
		if (!this.sent) {
			MailService.view(this.mail.id)
				.then((resp) => {
					console.log("Mail marked as read");
					this.$emit("change", resp.data);
				})
				.catch((err) => {
					console.error("Failed to mark mail as read");
					console.error(err);
				});
		}

		if (this.mail.response_id) {
			MailService.getResponseFrom(this.mail.id)
				.then((resp) => {
					this.response = resp.data;
					console.log("get score", this.getUserScore(this.response));
					this.score = this.getUserScore(this.response);
					this.compScore = this.getResScore(this.response);
					this.flag = this.getUserFlag(this.response, this.mail.flag_id);
				})
				.catch((err) => {
					console.log(err);
					notie.error("Failed to retrieve attached response", err);
				});
		}

		if (this.mail.response_id && this.mail.rescore_score_id) {
			MailService.getRescoreFrom(this.mail.id)
				.then((resp) => {
					this.rescore = resp.data;
				})
				.catch((err) => {
					console.log(err);
					notie.error("Failed to retrieve attached rescore request", err);
				});
		}

		if (this.mail.response_id && this.mail.rescore_flag_id) {
			MailService.getRescoreFrom(this.mail.id)
				.then((resp) => {
					this.rescore = resp.data;
				})
				.catch((err) => {
					console.log(err);
					notie.error("Failed to retrieve attached rescore request", err);
				});
		}
	},

	watch: {},

	methods: {
		toggleLabel(label) {
			if (!this.isLabeled(label)) {
				this.changeTag("label", label);
			} else {
				this.changeTag("unlabel", label);
			}
		},

		changeTag(tagType, val) {
			return MailService[tagType](this.mail.id, val)
				.then((resp) => {
					this.$emit("change", resp.data);
				})
				.catch((err) => {
					console.error(err);
					notie.error(`Failed to ${tagType} mail`, err);
				});
		},

		deleteMail() {
			return MailService.trash(this.mail.id)
				.then((resp) => {
					console.log("Deleted then exit");
					this.exit();
				})
				.catch((err) => {
					console.error(err);
					notie.error(`Failed to delete mail`, err);
				});
		},

		exit() {
			this.$emit("exit", true);
		},

		getUserScore(response) {
			let searchId = this.currentUser.id;
			if (this.sent) {
				let toUsers = this.mail.to;
				if (toUsers.length > 1) {
					console.log("To users:", toUsers);
					console.error("Can't find user score for mail that was sent to multiple people");
					return null;
				}
				searchId = toUsers[0].user.id;
			}
			let score = _.find(response.scores, { user_id: searchId });
			return score;
		},

		getResScore(response) {
			let score = _.find(response.scores, { score_type: 3 });
			return score;
		},

		getUserFlag(response, flagID) {
			let flag = _.find(response.flags, { id: flagID });
			return flag;
		},

		getNames(users) {
			if (users.first_name || users.last_name) {
				//Just a single user
				return this.getName(users);
			} else {
				let names = [];
				_.each(users, (user) => {
					if (user.first_name || user.last_name) {
						//Actual user object
						names.push(this.getName(user));
					} else {
						//UserMail object
						if (user.user && (user.user.first_name || user.user.last_name)) {
							//Yes, UserMail object
							names.push(this.getName(user.user));
						} else {
							//No, we don't know what this is!
							names.push("UNKNOWN");
						}
					}
				});

				return names.join(", ");
			}
		},

		getName(user) {
			let name = "";
			if (user.first_name) {
				name = user.first_name;
				if (user.last_name) {
					name = name + " " + user.last_name;
				}
			} else {
				name = user.last_name;
			}
			return name;
		},

		getSentTime(date) {
			return moment(date).format("LT LL");
		},

		initials(user) {
			let first_initial = user.first_name ? user.first_name.substring(0, 1).toUpperCase() : "";
			let last_initial = user.last_name ? user.last_name.substring(0, 1).toUpperCase() : "";
			return first_initial + last_initial;
		},

		isArchived() {
			let isArchived;
			_.each(this.mail.to, (to) => {
				if (to.user.id == this.currentUser.id) {
					isArchived = to.archived;
				}
			});

			return isArchived;
		},

		isTrashed() {
			let isTrashed;
			_.each(this.mail.to, (to) => {
				if (to.user.id == this.currentUser.id) {
					isTrashed = to.trashed;
				}
			});

			return isTrashed;
		},

		isLabeled(label) {
			let isLabeled;
			_.each(this.mail.to, (to) => {
				if (to.user.id == this.currentUser.id) {
					isLabeled = _.includes(to.labels, label);
				}
			});

			return isLabeled;
		},

		canReply() {
			let r = this.currentUser.role;
			if (r.mail_send_all || r.mail_reply_all) {
				return true;
			}
		},

		reply() {
			this.$emit("reply", this.mail);
		},
	},
};
</script>
