<template>
	<div class="pt-2 px-0 flex b-t b-r d-flex flex-column">
		<div class="flex-fixed flex-h" style="min-height: 30.4px">
			<label class="_600 ml-4 flex-grow">
				{{ label }}
				<a :style="sortItemsAnchorTagVisibility" @click="openSortSectionItemsModal">
					<a>&nbsp;&nbsp;</a>
					<button
						class="fa fa-sort"
						style="font-size: 15px"
						v-tippy
						:title="$t('tooltip.sort_items')"
					></button>
				</a>
			</label>

			<div class="flex-fixed">
				<div class="input-group m-0">
					<span class="py-0">
						<i class="fa fa-search" />
					</span>
					<input
						type="text"
						v-model="filter"
						class="form-control no-border no-bg no-shadow py-0"
						:placeholder="search"
					/>
				</div>
			</div>
		</div>
		<div class="flex-grow flex-shrink scroll-y">
			<b-table
				class="mb-0"
				ref="table"
				striped
				hover
				:showEmpty="true"
				:items="items"
				:fields="fields"
				:currentPage="currentPage"
				:perPage="control.perPage"
				:filter="filter"
				:filter-function="filterFunc"
				:sortBy.sync="control.sortBy"
				:sortDesc.sync="control.sortDesc"
				:emptyText="control.emptyText"
			>
				<template v-for="slotName in Object.keys($scopedSlots)" v-slot:[slotName]="slotScope">
					<slot :name="slotName" v-bind="slotScope"></slot>
				</template>
			</b-table>
		</div>
		<div class="no-grow no-shrink p-1 b-t mt-auto white d-flex flex-row align-items-center">
			<a class="flex mx-3">
				{{ $t("pagination.page") }} {{ control.totalRows > 0 ? control.currentPage : 0 }}{{ " " }}
				{{ $t("pagination.of") }} {{ Math.ceil(control.totalRows / control.perPage) }} (
				{{ control.totalRows }})
			</a>
			<div>
				<b-pagination
					size="md"
					class="m-1"
					:totalRows="control.totalRows"
					v-model="currentPage"
					:perPage="control.perPage"
				/>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	name: "ListTable",
	props: {
		label: String,
		search: String,
		items: {
			default: () => {
				return [];
			},
		},
		fields: Array,
		control: Object,
	},

	mounted() {
		// Expose the table's refresh function to the control object, which should allow it to be easily called
		// at higher levels in the component structure (e.g. DualTable or the DualTable's parent)
		this.control.refresh = this.$refs.table.refresh;
	},

	data() {
		const {
			control: { currentPage, filter },
		} = this;
		return {
			currentPage,
			filter,
		};
	},

	watch: {
		filter() {
			const { items, filter } = this;
			this.control.totalRows = items.filter((row) => this.filterFunc(row, filter)).length;
		},
	},

	computed: {
		sortItemsAnchorTagVisibility() {
			return this.label == "Assign Items" ? "visibility:show" : "visibility:hidden";
		},
	},

	methods: {
		openSortSectionItemsModal() {
			this.$parent.$emit("showsortsectionitemsmodalevent", this.items);
		},

		closeSortSectionItemsModal() {
			this.$refs["sortSectionItemsModal"].hide();
		},

		filterFunc(row, str) {
			return this.fields.some(({ key }) => {
				const value = row[key];
				return value && String(value).includes(str);
			});
		},
	},
};
</script>

<style scoped>
.page-footer {
	position: absolute;
	bottom: 0;
	width: 100%;
}
::-webkit-scrollbar {
	width: 7px;
}
::-webkit-scrollbar-thumb {
	border-radius: 4px;
	background-color: rgba(0, 0, 0, 0.2);
	-webkit-box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
}
</style>
