<template>
	<page customNavBar customBody>
		<template #navbar>
			<div class="navbar navbar-light bg-light b-b flex-nowrap flex-fixed">
				<div class="navbar-text nav-title flex" id="pageTitle">
					{{ $t("ReportTrainingCompletion.reporting") }}
					<span class="far fa-fw fa-angle-right"></span
					>{{ $t("ReportTrainingCompletion.training_completion") }}
				</div>
				<a
					class="text-muted px-2"
					@click="exportCSV"
					v-tippy="{ placement: 'bottom', triggers: 'click' }"
					:title="$t('tooltip.csv_export')"
				>
					<i class="far fa-file-csv fa-2x"></i>
				</a>
				<a
					class="text-muted px-2"
					@click="exportPDF"
					v-tippy="{ placement: 'bottom', triggers: 'click' }"
					:title="$t('tooltip.print')"
				>
					<i class="far fa-print fa-2x"></i>
				</a>
			</div>
		</template>

		<b-container fluid class="white scroll-y">
			<b-row class="py-4">
				<b-col>
					<training-completion-filters
						v-model="selectedFilters"
						@newRows="(newRows) => (userProgress = newRows)"
					></training-completion-filters>
				</b-col>
				<b-col>
					<training-completion-graph :userProgress="userProgress" ref="chart"></training-completion-graph>
				</b-col>
			</b-row>
			<b-row>
				<training-completion-table
					:selectedCourse="selectedFilters.course"
					:userProgress="userProgress"
				></training-completion-table>
			</b-row>
		</b-container>
	</page>
</template>

<script>
import TrainingCompletionGraph from "@/components/training_completion_report/TrainingCompletionGraph.vue";
import TrainingCompletionFilters from "@/components/training_completion_report/TrainingCompletionFilters.vue";
import TrainingCompletionTable from "@/components/training_completion_report/TrainingCompletionTable.vue";

import FileService from "@/services/FileService.js";
import { idToName, TRAINING_PROGRESS_STATES } from "@/services/Constants";

import moment from "moment";

export default {
	name: "ReportTrainingCompletion",

	components: {
		TrainingCompletionFilters,
		TrainingCompletionGraph,
		TrainingCompletionTable,
	},

	data() {
		return {
			selectedFilters: {
				course: null,
				team: null,
			},
			userProgress: [],
			graphImgData: null,
		};
	},

	computed: {
		fileExportRows() {
			const {
				selectedFilters: { course },
				userProgress,
			} = this;

			const sortedElements = course.elements.sort((a, b) => a.sequence - b.sequence);
			const headers = ["Scorer", "State"].concat(sortedElements.map(({ name }) => name));

			const body = userProgress.map(
				({ scorer_id, training_progress: { overall_progress, progress_elements } }) => {
					const progress = idToName(TRAINING_PROGRESS_STATES, overall_progress);
					const elements = sortedElements.map(({ id }) => {
						const progElem = (progress_elements || []).find(({ element_id }) => id === element_id);
						if (progElem) {
							return idToName(TRAINING_PROGRESS_STATES, progElem.progress);
						}
						return TRAINING_PROGRESS_STATES.INCOMPLETE.name;
					});

					return [scorer_id, progress].concat(elements);
				}
			);

			return [headers].concat(body);
		},
	},

	methods: {
		exportCSV() {
			const {
				selectedFilters: { course, team },
				fileExportRows,
			} = this;

			const dateStr = new Date().toLocaleDateString().split("/").join(".");
			FileService.exportCSV(fileExportRows, ["Training Completion", course.name, team.name, dateStr]);
		},

		exportPDF() {
			const imgData = this.$refs.chart.getImageData();

			const {
				fileExportRows,
				selectedFilters: { course, team },
			} = this;

			const docDefinition = {
				pageOrientation: "landscape",
				info: {
					title: "Training Completion",
				},
				styles: {
					all: {
						fontSize: 10,
					},
					datacol: {
						alignment: "right",
					},
				},
				content: [
					{
						columns: [
							{
								width: "*",
								bold: true,
								style: "all",
								text: `Training Completion
								Generated: ${moment().format("dddd, MMMM Do YYYY, h:mm:ss a")}
								Course: ${course.name}
								Team: ${team.name}`,
							},
							{
								width: 500,
								image: imgData,
							},
						],
					},
					{
						layout: "lightHorizontalLines", // optional
						style: "all",
						table: {
							headerRows: 1,
							widths: Array(fileExportRows[0].length).fill("*"),
							body: fileExportRows.map((row) => [...row]), // pdfMake mutates the array, have to copy
						},
					},
				],
			};

			const dateStr = new Date().toLocaleDateString().split("/").join("_");
			FileService.exportPDF(docDefinition, `Training Completion ${course.name} ${team.name} ${dateStr}`);
		},
	},
};
</script>