<template>
	<page :sideNavRefresh="saveCount" customNavBar>
		<template #navbar>
			<div class="navbar navbar-expand-lg">
				<!-- Page title -->
				<loading type="header" v-if="loading" />
				<div v-if="!loading" class="navbar-text nav-title flex" id="pageTitle">
					{{ $t("RolesEdit.title") }}
				</div>
			</div>
		</template>

		<div class="h-100" v-if="!loading">
			<div class="d-sm-flex b-t h-100">
				<edit-pane :cancel="loadData" :save="saveClient" :dirty="dirty" :valid="valid">
					<!-- ROLES -->
					<!-- <div id="tab-4"> -->
					<div class="row m-0">
						<form role="form" class="p-4 col-md-3">
							<p>{{ $t("RolesEdit.select_role") }}</p>
							<div class="list-group box">
								<a
									v-for="(role, i) in orderedRoles"
									:key="role.id"
									@click="sRole = role"
									:class="{
										'text-danger': isDuplicate(role, i),
										'text-white': !isDuplicate(role, i) && isRoleActive(role),
										primary: isRoleActive(role),
									}"
									class="list-group-item d-flex flex-row align-items-center"
								>
									<!-- <span class="float-right">
                                                            <i class="fa fa-chevron-right"></i>
												</span>-->
									<span>{{ role.name }} ({{ role.access }})</span>
									<i
										class="flex ml-1 valid-hint text fa fa-angle-left"
										:class="{ invalid: isDuplicate(role, i) }"
									></i>
									<div class="mx-auto w90">
										<span v-if="user.role.access >= role.access" @click="cloneRole(role)">
											<i class="fa fa-copy text-blue"></i>
										</span>
										<span v-if="user.role.access < role.access">
											<i class="fa fa-copy text-extra-muted"></i>
										</span>
										<span v-if="user.role.access >= role.access" @click="removeRole(role)">
											<i class="fa fa-trash text-danger"></i>
										</span>
										<span v-if="user.role.access < role.access">
											<i class="fa fa-trash text-extra-muted"></i>
										</span>
									</div>
								</a>
							</div>
							<button type="button" @click="addRole()" class="btn green btn btn-block">
								<i class="fa fa-plus"></i> {{ $t("RolesEdit.add_role") }}
							</button>
						</form>
						<div class="p-4 col-md-7">
							<ul class="nav nav-tabs">
								<li class="nav-item">
									<button
										class="nav-link"
										:class="[selectedTab === 'General Settings' && ['active']]"
										data-bs-toggle="tab"
										role="tab"
										@click="selectedTab = 'General Settings'"
									>
										{{ $t("RolesEdit.general_settings") }}
									</button>
								</li>
								<li v-for="(_, tabName) in permissionsTabs" :key="tabName" class="nav-item">
									<button
										class="nav-link"
										:class="[selectedTab === tabName && ['active']]"
										role="tab"
										data-bs-toggle="tab"
										@click="selectedTab = tabName"
									>
										{{ $t(`Sidenav.${tabName}`) }}
									</button>
								</li>
							</ul>
							<div class="box">
								<form
									role="form"
									class="p-4"
									:class="{ 'text-muted': !hasAccess(sRole) }"
									v-if="sRole && selectedTab === 'General Settings'"
								>
									<div class="form-group">
										<label>{{ $t("RolesEdit.name") }}</label>
										<input
											type="text"
											v-model="sRole.name"
											class="form-control"
											:disabled="!hasAccess(sRole)"
										/>
									</div>
									<div class="form-group">
										<label>{{ $t("RolesEdit.ref_id") }}</label>
										<input
											type="text"
											v-model.trim="sRole.id"
											class="form-control"
											:disabled="!hasAccess(sRole) || !sRole.new"
										/>
									</div>
									<div class="form-group">
										<label>{{ $t("RolesEdit.access_level") }}</label>
										<input
											type="number"
											v-model.number="sRole.access"
											class="form-control"
											:disabled="!hasAccess(sRole)"
										/>
									</div>
									<div class="form-group">
										<label>{{ $t("RolesEdit.theme") }}</label>
										<theme-select
											:themes="themes"
											v-model="sRole.theme"
											:disabled="!hasAccess(sRole)"
										></theme-select>
									</div>
								</form>
								<form
									role="form"
									class="p-4 checkboxes"
									:class="{ 'text-muted': !hasAccess(sRole) }"
									v-if="sRole"
								>
									<div class="form-row">
										<template v-for="checkBox in permissionsTabs[selectedTab]">
											<div
												v-if="
													!checkBox.requiresTenantFeature ||
													client[checkBox.requiresTenantFeature]
												"
												:key="checkBox.sRoleKey"
												class="col-md-4"
											>
												<permissions-check-box
													:hasAccess="hasAccess(sRole)"
													:sRole="sRole"
													:client="client"
													v-bind="checkBox"
												>
													<component v-if="checkBox.slot" :is="checkBox.slot" />
												</permissions-check-box>
											</div>
										</template>
									</div>
								</form>
							</div>
						</div>
						<form class="p-4 col-md-2">
							<p>{{ $t("RolesEdit.preview_role") }}</p>
							<sidenav-preview style="height: 65vh" class="shadow white" :role="sRole"></sidenav-preview>
						</form>
					</div>
				</edit-pane>
				<save-optional-modal
					:dirty="dirty"
					:valid="valid"
					s
					:save="saveClient"
					:next="saveOptNext"
					:cancel="saveOptCancel"
					objectText="Tenant"
					:objectName="client.name"
					actionText="leave the page"
					v-model="saveOptModal"
				/>

				<save-required-modal
					:dirty="dirty"
					:valid="valid"
					:save="saveClient"
					:next="saveReqNext"
					objectText="Tenant"
					:objectName="client.name"
					:actionText="saveReqAction"
					v-model="saveReqModal"
				/>
			</div>
		</div>
	</page>
</template>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.bg-auto {
	z-index: -1;
}

.w30 {
	width: 30px;
}

.nav-tabs > .nav-item > button.nav-link {
	padding-left: 0.5rem !important;
	padding-right: 0.5rem !important;
}

.nav-tabs > .nav-item > button.active {
	color: white !important;
	background-color: #6aa4f5 !important;
}
</style>

<script>
import Vue from "vue";

//Libraries
import notie from "@/services/NotieService";

//Services
import TenantService from "@/services/TenantService";
import AuthService from "@/services/AuthService";
import ValidationService from "@/services/ValidationService";
import UserService from "@/services/UserService";
import ThemeService from "@/services/ThemeService";
import fs from "@/services/FormatService";
import Utils from "@/services/Utils";

//Components
import EditPane from "@/components/EditPane";
import PermissionsCheckBox from "@/components/roles_edit/PermissionsCheckBox";
import ThemeSelect from "@/components/ThemeSelect";
import SaveOptionalModal from "@/components/SaveOptionalModal";
import SaveRequiredModal from "@/components/SaveRequiredModal";
import SidenavPreview from "@/components/roles_edit/SidenavPreview";

export default {
	name: "RolesEdit",

	components: {
		EditPane,
		PermissionsCheckBox,
		ThemeSelect,
		SaveOptionalModal,
		SaveRequiredModal,
		SidenavPreview,
	},

	props: ["user", "params", "query"],

	data() {
		const resGroup = {
			label: this.$i18n.t("RolesEdit.res_group_setup"),
			sRoleKey: "page_config_res_groups",
			disabled: true,
			tooltipText: this.$i18n.t("RolesEdit.limit_unique_responses_disabled"),
		};
		const uniqResp = {
			label: this.$i18n.t("RolesEdit.limit_unique_responses"),
			sRoleKey: "limit_unique_responses",
			limitsAccess: true,
			disabled: true,
			tooltipText: this.$i18n.t("RolesEdit.limit_unique_responses_disabled"),
		};

		return {
			fs: fs,
			tab: 1,
			mode: "",
			client: {},
			loading: true,
			sRole: {},
			themes: [],
			selectedTheme: {},
			successMsg: "",
			errMsg: "",
			dirty: false,

			valid: {},
			saveOptModal: false,
			saveOptNext: () => {},
			saveOptCancel: () => {},

			saveReqModal: false,
			saveReqAction: "continue",
			saveReqNext: () => {},

			saveCount: 0,

			selectedTab: "General Settings",
			tenantPermissions: {
				enforce_unique_responses: uniqResp,
				use_res_groups: resGroup,
			},
			permissionsTabs: {
				scoring: [
					{
						label: this.$i18n.t("RolesEdit.scoring"),
						sRoleKey: "page_scoring",
						children: [
							{ label: this.$i18n.t("RolesEdit.score_rangefinding"), sRoleKey: "rf_score" },
							{
								label: this.$i18n.t("RolesEdit.backreading"),
								sRoleKey: "page_backreading",
								children: [
									{
										label: this.$i18n.t("RolesEdit.backread_auto_populate"),
										sRoleKey: "backread_auto_populate",
									},
									{
										label: this.$i18n.t("RolesEdit.backreading_stats"),
										sRoleKey: "backreading_stats",
									},
									{
										label: this.$i18n.t("RolesEdit.limit_backreading"),
										sRoleKey: "limit_backreading",
										limitsAccess: true,
									},
								],
							},
							{ label: this.$i18n.t("RolesEdit.resolution_access"), sRoleKey: "has_resolution" },
							{ label: this.$i18n.t("RolesEdit.adjudication_access"), sRoleKey: "has_adjudication" },
							{ label: this.$i18n.t("RolesEdit.appeals_access"), sRoleKey: "has_appeal" },
							{ label: this.$i18n.t("RolesEdit.flag_queue_scoring"), sRoleKey: "flag_queue_scoring" },
							{ label: this.$i18n.t("RolesEdit.allow_response_printing"), sRoleKey: "allow_print" },
							{ label: this.$i18n.t("RolesEdit.show_help"), sRoleKey: "show_help" },
							{ label: this.$i18n.t("RolesEdit.prevent_auto_enter"), sRoleKey: "prevent_auto_enter" },
							{ label: this.$i18n.t("RolesEdit.can_escalate"), sRoleKey: "can_escalate" },
							{ label: this.$i18n.t("RolesEdit.apply_admin_flags"), sRoleKey: "admin_flags" },
							{
								label: this.$i18n.t("RolesEdit.review_restricted_flags"),
								sRoleKey: "review_restricted_flags",
							},
							{ label: this.$i18n.t("RolesEdit.view_admin_metadata"), sRoleKey: "admin_metadata" },
							{ label: this.$i18n.t("RolesEdit.view_copyleaks_reports"), sRoleKey: "copyleaks_reports" },
							{ label: this.$i18n.t("RolesEdit.skip_validity"), sRoleKey: "skip_validity" },
							{ label: this.$i18n.t("RolesEdit.show_count"), sRoleKey: "show_score_count" },
							{ label: this.$i18n.t("RolesEdit.show_score_and_time"), sRoleKey: "show_score_time_stats" },
							{
								label: this.$i18n.t("RolesEdit.show_live_annotations"),
								sRoleKey: "show_live_annotations",
							},
							{ label: this.$i18n.t("RolesEdit.reset_own_scores"), sRoleKey: "reset_own_scores" },
							{ label: this.$i18n.t("RolesEdit.beginner"), sRoleKey: "is_beginner", limitsAccess: true },
							{
								label: this.$i18n.t("RolesEdit.first_read_only"),
								sRoleKey: "allow_first_read_only",
								limitsAccess: true,
							},
							{
								label: this.$i18n.t("RolesEdit.prevent_resource_download"),
								sRoleKey: "prevent_resource_download",
								limitsAccess: true,
							},
							{
								label: this.$i18n.t("RolesEdit.require_lockdown_browser"),
								sRoleKey: "require_lockdown",
								limitsAccess: true,
							},
							uniqResp,
						],
					},
					{
						label: this.$i18n.t("RolesEdit.bulletin_board"),
						sRoleKey: "page_bulletin_board",
						children: [
							{ label: this.$i18n.t("RolesEdit.post_bulletins"), sRoleKey: "bulletin_board_edit" },
						],
					},
					{
						label: this.$i18n.t("RolesEdit.mail"),
						sRoleKey: "page_mail",
						children: [
							{ label: this.$i18n.t("RolesEdit.send_mail_anyone"), sRoleKey: "mail_send_all" },
							{
								label: this.$i18n.t("RolesEdit.send_mail_supervisors"),
								sRoleKey: "mail_send_supervisor",
							},
							{ label: this.$i18n.t("RolesEdit.reply_mail_anyone"), sRoleKey: "mail_reply_all" },
							{ label: this.$i18n.t("RolesEdit.send_external_email"), sRoleKey: "send_external_email" },
							{ label: this.$i18n.t("RolesEdit.mark_mail_important"), sRoleKey: "mail_mark_important" },
							{
								label: this.$i18n.t("RolesEdit.receive_emails_new_responses"),
								sRoleKey: "receive_new_responses_emails",
								children: [
									{
										label: this.$i18n.t("RolesEdit.receive_unassigned_responses_emails"),
										sRoleKey: "receive_unassigned_responses_emails",
									},
								],
							},
							{
								label: this.$i18n.t("RolesEdit.receive_user_lockout_emails"),
								sRoleKey: "receive_lockout_emails",
							},
							{ label: this.$i18n.t("RolesEdit.receive_alert_emails"), sRoleKey: "receive_alert_emails" },
						],
					},
				],
				observation: [
					{
						label: this.$i18n.t("RolesEdit.obs_scoring"),
						sRoleKey: "page_observation_sessions",
						children: [
							{
								label: this.$i18n.t("RolesEdit.manage_schedules"),
								sRoleKey: "manage_observation_schedules",
							},
							{ label: this.$i18n.t("RolesEdit.access_all_obs_sites"), sRoleKey: "all_obs_sites" },
							{ label: this.$i18n.t("RolesEdit.edit_obs_scores"), sRoleKey: "edit_obs_scores" },
						],
					},
					{ label: this.$i18n.t("RolesEdit.form_setup"), sRoleKey: "page_config_forms" },
					{ label: this.$i18n.t("RolesEdit.site_setup"), sRoleKey: "page_config_sites" },
				],
				quality_management: [
					{ label: this.$i18n.t("RolesEdit.qc_setup"), sRoleKey: "page_qc_setup" },
					{
						label: this.$i18n.t("RolesEdit.qc_report"),
						sRoleKey: "page_qc_report",
						children: [{ label: this.$i18n.t("fields.qc_portfolio"), sRoleKey: "qc_portfolio" }],
					},
					{ label: this.$i18n.t("RolesEdit.rangefinding_setup"), sRoleKey: "page_rf_setup" },
					{ label: this.$i18n.t("RolesEdit.rangefinding_report"), sRoleKey: "page_rf_report" },
					{ label: this.$i18n.t("RolesEdit.candidate_auditing"), sRoleKey: "page_candidate_auditing" },
					{
						label: this.$i18n.t("RolesEdit.score_auditing"),
						sRoleKey: "page_score_auditing",
						children: [
							{
								label: this.$i18n.t("RolesEdit.score_auditing_own_scores"),
								sRoleKey: "limit_score_auditing_self",
								limitsAccess: true,
								children: [
									{
										label: this.$i18n.t("RolesEdit.score_auditing_past_24h"),
										sRoleKey: "limit_score_auditing_24h",
										limitsAccess: true,
									},
								],
							},
							{
								label: this.$i18n.t("RolesEdit.allow_access_to_reset_history"),
								sRoleKey: "reset_history",
							},
						],
					},
					{
						label: this.$i18n.t("RolesEdit.auditing"),
						sRoleKey: "page_auditing",
						children: [
							{
								label: this.$i18n.t("RolesEdit.auditing_assigned_sections"),
								sRoleKey: "limit_auditing",
								limitsAccess: true,
							},
							{
								label: this.$i18n.t("RolesEdit.auditing_school_code"),
								sRoleKey: "limit_auditing_school_code",
								limitsAccess: true,
							},
							{ label: this.$i18n.t("RolesEdit.create_delete_response"), sRoleKey: "delete_data" },
							{ label: this.$i18n.t("RolesEdit.queue_for_export"), sRoleKey: "queue_for_export" },
							{ label: this.$i18n.t("RolesEdit.export_scores"), sRoleKey: "export_scores" },
							{ label: this.$i18n.t("RolesEdit.show_score_history"), sRoleKey: "show_reset_scores" },
							{ label: this.$i18n.t("RolesEdit.assign_scorers"), sRoleKey: "assign_scorers" },
							{ label: this.$i18n.t("RolesEdit.request_rescores"), sRoleKey: "request_rescores" },
							{ label: this.$i18n.t("RolesEdit.escalate_with_score"), sRoleKey: "escalate_with_score" },
						],
					},
					{
						label: this.$i18n.t("RolesEdit.reporting"),
						disabled: true,
						checkedIfChildIs: true,
						children: [
							// {
							// 	// Not sure if this is still needed, no dashboard_own_teams value in backend Model
							// 	label: this.$i18n.t("RolesEdit.reporting_dashboard_own_teams"),
							// 	sRoleKey: "dashboard_own_teams",
							// 	limitsAccess: true,
							// },
							{ label: this.$i18n.t("RolesEdit.completion"), sRoleKey: "page_completion" },
							{ label: this.$i18n.t("RolesEdit.scorers"), sRoleKey: "page_scorers" },
							{ label: this.$i18n.t("RolesEdit.daily"), sRoleKey: "page_daily" },
							{
								label: this.$i18n.t("RolesEdit.daily_scoring_mgmt"),
								sRoleKey: "page_daily_scoring_mgmt",
								tooltipText: this.$i18n.t("RolesEdit.dsm_report_tooltip"),
							},
							{ label: this.$i18n.t("RolesEdit.payroll"), sRoleKey: "page_payroll" },
							{ label: this.$i18n.t("RolesEdit.quotas"), sRoleKey: "page_quotas" },
							{ label: this.$i18n.t("RolesEdit.user_summary"), sRoleKey: "page_user_summary" },
							{ label: this.$i18n.t("RolesEdit.item_summary"), sRoleKey: "page_item_summary" },
							{ label: this.$i18n.t("RolesEdit.user_agreement"), sRoleKey: "page_user_agreement" },
							{
								label: this.$i18n.t("RolesEdit.backread_agreement"),
								sRoleKey: "page_backread_agreement",
							},
							{ label: this.$i18n.t("RolesEdit.project_agreement"), sRoleKey: "page_project_agreement" },
							{
								label: this.$i18n.t("RolesEdit.page_training_completion"),
								sRoleKey: "page_training_completion",
							},
							{
								label: this.$i18n.t("RolesEdit.page_sla_report"),
								sRoleKey: "page_sla_report",
							},
						],
					},
				],
				operations: [
					{ label: this.$i18n.t("Forecast.project_forecast"), sRoleKey: "page_forecast" },
					{ label: this.$i18n.t("RolesEdit.schedule_sign_up"), sRoleKey: "page_my_schedule" },
					{ label: this.$i18n.t("Calendar.supervisor_calendar"), sRoleKey: "page_calendar" },
					{ label: this.$i18n.t("RolesEdit.project_dashboard"), sRoleKey: "page_project_dashboard" },
					{ label: this.$i18n.t("RolesEdit.tenant_dashboard"), sRoleKey: "page_tenant_dashboard" },
					{
						label: this.$i18n.t("RolesEdit.supervisor_dashboard"),
						sRoleKey: "page_supervisor_dashboard",
						children: [
							{
								label: this.$i18n.t("RolesEdit.dashboard_own_teams"),
								sRoleKey: "limit_teams",
								limitsAccess: true,
							},
						],
					},
					{
						label: this.$i18n.t("RolesEdit.dashboard_personal"),
						sRoleKey: "page_dashboard",
						children: [
							{ label: this.$i18n.t("fields.validity"), sRoleKey: "page_dashboard_val" },
							{ label: this.$i18n.t("fields.practice"), sRoleKey: "page_dashboard_practice" },
							{ label: this.$i18n.t("fields.qualification"), sRoleKey: "page_dashboard_qual" },
							{ label: this.$i18n.t("fields.calibration"), sRoleKey: "page_dashboard_cal" },
							{ label: this.$i18n.t("RolesEdit.show_qc_counts"), sRoleKey: "page_dashboard_qc_counts" },
						],
					},
					{
						label: `${this.$i18n.t("RolesEdit.limit_visibility_of_inactive_resources")}`,
						sRoleKey: "limit_inactive_resources",
						limitsAccess: true,
					},
					{
						label: this.$i18n.t("RolesEdit.video_meetings"),
						requiresTenantFeature: "video_meetings",
						sRoleKey: "page_video_meetings",
						children: [
							{ label: this.$i18n.t("RolesEdit.create_meetings"), sRoleKey: "create_video_meetings" },
							{ label: this.$i18n.t("RolesEdit.view_all_meetings"), sRoleKey: "view_all_video_meetings" },
							{
								label: this.$i18n.t("RolesEdit.manage_all_meetings"),
								sRoleKey: "manage_all_video_meetings",
							},
						],
					},
				],
				user_management: [
					{
						label: this.$i18n.t("RolesEdit.user_setup"),
						sRoleKey: "page_config_users",
						children: [
							{ label: this.$i18n.t("RolesEdit.force_password_reset"), sRoleKey: "force_password_reset" },
						],
					},
					{ label: this.$i18n.t("RolesEdit.team_setup"), sRoleKey: "page_config_teams" },
				],
				setup_configuration: [
					{ label: this.$i18n.t("RolesEdit.all_tenants_setup"), sRoleKey: "page_config_tenants" },
					{ label: this.$i18n.t("RolesEdit.current_tenant_config"), sRoleKey: "page_config_current_tenant" },
					{ label: this.$i18n.t("RolesEdit.project_setup"), sRoleKey: "page_config_projects" },
					{ label: this.$i18n.t("RolesEdit.section_setup"), sRoleKey: "page_config_sections" },
					{
						label: this.$i18n.t("RolesEdit.item_setup"),
						sRoleKey: "page_config_items",
						children: [
							{
								label: this.$i18n.t("RolesEdit.item_active_toggle"),
								sRoleKey: "item_active_toggle",
								limitsAccess: true,
							},
						],
					},
					{ label: this.$i18n.t("RolesEdit.rubric_setup"), sRoleKey: "page_config_rubrics" },
					{ label: this.$i18n.t("RolesEdit.training_setup"), sRoleKey: "page_config_training" },
					resGroup,
					{
						label: `${this.$i18n.t("RolesEdit.limit_visibility_of_inactive_objects")}`,
						sRoleKey: "limit_inactive",
						limitsAccess: true,
					},
					// { label: this.$i18n.t("RolesEdit.define_config_sheets"), sRoleKey: "define_config_sheets" },
					{ label: this.$i18n.t("RolesEdit.use_config_sheets"), sRoleKey: "use_config_sheets" },
					{ label: this.$i18n.t("RolesEdit.manage_iea_models"), sRoleKey: "manage_iea_models" },
				],
				api_logs: [{ label: this.$i18n.t("RolesEdit.api_logs"), sRoleKey: "page_api_logs" }],
			},
		};
	},

	created() {
		this.loadData();
		this.initValidation();
	},

	watch: {
		"sRole.page_config_tenants": function (newVal, oldVal) {
			if (newVal && !this.sRole.page_config_current_tenant) {
				this.sRole.page_config_current_tenant = true;
			}
		},
		"sRole.page_config_current_tenant": function (newVal, oldVal) {
			if (!newVal && this.sRole.page_config_tenants) {
				this.sRole.page_config_tenants = false;
			}
		},
		sRole: {
			handler: function (newVal, oldVal) {
				if (newVal && oldVal && newVal.id == oldVal.id) {
					if (newVal.access < 0) {
						this.sRole.access = 0;
					}
					if (newVal.access > this.user.role.access) {
						this.sRole.access = this.user.role.access;
					}
				}
			},
			deep: true,
		},
	},

	computed: {
		orderedRoles() {
			return _.orderBy(this.client.roles, ["access", "name"], ["desc", "asc"]);
		},
		accessibleRoles() {
			return _.filter(this.client.roles, (role) => {
				return this.user.role.access >= role.access;
			});
		},
		duplicates() {
			let indexMap = {};
			_.each(this.orderedRoles, (role, i) => {
				if (!indexMap[role.id]) {
					indexMap[role.id] = [];
				}
				indexMap[role.id].push(i);
			});

			let dupes = [];
			_.each(indexMap, (indices) => {
				if (indices.length > 1) {
					_.each(indices, (index) => {
						dupes[index] = true;
					});
				}
			});
			return dupes;
		},
	},

	beforeRouteLeave(to, from, next) {
		if (this.dirty) {
			this.saveOptNext = () => {
				next();
			};
			this.saveOptCancel = () => {
				next(false);
			};
			this.saveOptModal = true;
		} else {
			next();
		}
	},

	methods: {
		initValidation() {
			this.valid = ValidationService.newValidator({
				// name: {
				// 	group: "main",
				// 	errorMsg: "Each role must have a unique Reference ID",
				// 	func: () => {
				// 		return this.duplicates.length == 0;
				// 	}
				// }
			});
		},

		blankClient() {
			return {
				roles: [
					RoleService.getPrefillRole("DIRECTOR"),
					RoleService.getPrefillRole("ADMIN"),
					RoleService.getPrefillRole("RESOLUTION"),
					RoleService.getPrefillRole("TEAM_LEAD"),
					RoleService.getPrefillRole("SCORER"),
					RoleService.getPrefillRole("BASIC_SCORER"),
				],
				alerts: [],
				credentials: [],
			};
		},

		watchChanges() {
			if (this.unwatch) {
				this.unwatch();
			}
			this.unwatch = this.$watch(
				"client",
				(newc, old) => {
					console.log(newc, old);
					console.log("marking dirty");
					this.dirty = true;
				},
				{ deep: true }
			);
		},

		loadData() {
			this.dirty = false;

			ThemeService.getThemes().then((themes) => {
				themes[0].label = this.$i18n.t("themes.oscar_green");
				themes[1].label = this.$i18n.t("themes.yellow");
				themes[2].label = this.$i18n.t("themes.red");
				themes[3].label = this.$i18n.t("themes.blue");
				themes[4].label = this.$i18n.t("themes.cyan");
				themes[5].label = this.$i18n.t("themes.purple");
				themes[6].label = this.$i18n.t("themes.green");
				themes[7].label = this.$i18n.t("themes.black");
				this.themes = themes;
			});

			return TenantService.getClient().then((resp) => {
				this.client = resp.data;
				if (this.client.roles.length > 0) {
					this.sRole = _.find(_.orderBy(this.client.roles, ["access", "name"], ["desc", "asc"]), (role) => {
						return this.user.role.access >= role.access;
					});
					this.loading = false;
					this.refresh = new Date();
					this.watchChanges();
				}

				Object.entries(this.tenantPermissions).forEach(([tenantKey, permission]) => {
					if (this.client[tenantKey]) {
						permission.disabled = false;
						permission.tooltipText = "";
					} else {
						this.sRole[permission.sRoleKey] = false;
					}
				});
			});
		},

		saveClient() {
			AuthService.queueRefresh();
			if (this.reacceptTerms) {
				this.client.terms_updated_at = moment();
			}

			// Remove permissions that are dependent on other permissions if those parent permissions are no longer set
			_.each(this.client.roles, (role) => {
				if (!(role.page_auditing && !role.page_score_auditing)) {
					role.hide_auditing_scores = false;
				}
				if (!role.mail_send_all) {
					role.request_rescores = false;
				}
				if (!role.page_dashboard) {
					role.page_dashboard_val = false;
					role.page_dashboard_practice = false;
					role.page_dashboard_qual = false;
					role.page_dashboard_cal = false;
				}
				if (!role.limit_score_auditing_self) {
					role.limit_score_auditing_24h = false;
				}
			});

			return TenantService.saveClient(this.client)
				.then((resp) => {
					notie.success("Tenant saved");
					if (this.client.id != resp.data.id) {
						this.$router.replace("/tenants/" + resp.data.id);
					}
					// else {
					// 	this.$router.replace(
					// 		"/tenants/" +
					// 			resp.data.id +
					// 			"?rand=" +
					// 			Math.floor(Math.random() * 1000000)
					// 	);
					// }
					this.loadData();
					ThemeService.refresh();
					this.saveCount++;
				})
				.catch((err) => {
					notie.error("Failed to save tenant", err);
				});
		},

		hasAccess(role) {
			return role && this.user.role.access >= role.access;
		},

		addRole() {
			this.client.roles.push({
				new: true,
				id: Utils.generateUUID(),
				name: this.$i18n.t("RolesEdit.new_role"),
				access: 0,
				theme: "info",
			});
			this.sRole = this.client.roles[this.client.roles.length - 1];
		},

		cloneRole(role) {
			this.client.roles.push({
				...role,
				new: true,
				id: Utils.generateUUID(),
				name: `${role.name} (Copy)`,
			});
			this.sRole = this.client.roles[this.client.roles.length - 1];
		},

		removeRole(role) {
			TenantService.canDeleteRole(role.id)
				.then((resp) => {
					if (resp.data) {
						this.client.roles = _.without(this.client.roles, role);
					} else {
						notie.error("This role is in use and cannot be deleted");
					}
				})
				.catch((err) => {
					notie.error("Failed to determine if role can be deleted", err);
				});
		},

		isRoleActive(role) {
			return role === this.sRole;
		},

		isDuplicate(role, i) {
			return this.duplicates[i] && role.new;
		},
	},
};
</script>
