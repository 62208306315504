<template>
	<page customNavBar>
		<template #navbar>
			<div class="navbar navbar-light bg-light b-b">
				<a v-if="set" href="/#/qc_setup" class="navbar-brand">{{
					$t("QCSetResponses.title", { name: set.name })
				}}</a>
				<a href="/#/qc_setup" class="navbar-brand right">{{ $t("buttons.back") }}</a>
			</div>
		</template>

		<div class="padding">
			<div class="row">
				<div class="col col-s6">
					<div class="d-flex flex-row justify-content-between align-items-center mb-2" style="height: 35px">
						<h5 class="mb-0">{{ $t("QCSetResponses.current_responses") }}</h5>
					</div>
					<div
						class="d-flex flex-row justify-content-between align-items-center mb-2"
						style="height: 35px"
					></div>
					<table class="table">
						<thead>
							<tr>
								<th></th>
								<th>{{ $t("fields.id") }}</th>
								<th>{{ $t("fields.sequence") }}</th>
								<th>{{ $t("fields.score") }}</th>
								<th>{{ $t("fields.actions") }}</th>
							</tr>
						</thead>
						<!-- <tbody> -->
						<tbody v-if="!currentResponses.length">
							<tr v-if="!currentResponses.length">
								<td colspan="5">{{ $t("data_description.no_responses_set") }}</td>
							</tr>
						</tbody>

						<draggable
							v-model="currentResponses"
							:options="{ handle: '.drag-handle' }"
							@sort="setSorted"
							:element="'tbody'"
						>
							<tr v-for="resp in currentResponses" :key="resp.id">
								<td class="p-custom py-0 v-mid">
									<div class="m-auto px-2 drag-handle flex-fixed pointer">
										<i class="far fa-sort text-muted"></i>
									</div>
								</td>

								<td class="v-mid">{{ resp.ref_id }}</td>
								<td class="v-mid">{{ resp.sequence }}</td>
								<td class="v-mid">
									<span v-if="resp.true_score">
										<inline-score
											:score="resp.true_score"
											:rubric="item && item.rubric"
											:type="20"
											tooltip
										/>
									</span>
									<span v-if="!resp.true_score">--</span>
								</td>

								<td class="p-custom py-0 v-mid" style="min-width: 84px">
									<a
										:href="`#/qc_true_score/${resp.section_id}/${resp.item_id}/${resp.id}?return=true`"
										class="btn btn-sm btn-icon btn-rounded theme-accent text-white m-0"
										v-tippy
										:title="$t('tooltip.edit')"
									>
										<i class="far fa-edit"></i>
									</a>
									<a
										@click="deleteResp(resp)"
										class="btn btn-sm btn-icon btn-rounded red text-white m-0"
										v-tippy
										:title="$t('tooltip.delete')"
									>
										<i class="far fa-trash"></i>
									</a>
								</td>
							</tr>
						</draggable>
					</table>
				</div>
				<div class="col col-s6">
					<div class="d-flex flex-row align-items-center mb-2" style="height: 35px">
						<h5 class="flex mb-0">{{ $t("QCSetResponses.available_responses") }}</h5>
						<a @click="attemptImport" class="btn-subtle" v-tippy :title="$t('tooltip.upload_responses')">
							<i class="fas fa-file-upload fa-lg mx-2" />
						</a>
					</div>
					<div class="d-flex flex-row justify-content-between align-items-center mb-2" style="height: 35px">
						<span class="d-flex flex-row align-items-center">
							{{ $t("QCSetResponses.source") }}
							<config-select
								:options="sourceSets"
								v-model="sourceSet"
								style="min-width: 200px"
								class="ml-2"
								@input="sourceSetSelected"
							>
							</config-select>
							<span class="d-flex flex-row ml-2">
								{{ $t("QCSetResponses.unused_responses") }}
								<label class="md-check ml-2">
									<input type="checkbox" v-model="filterUnusedResps" />
									<i class="theme-accent"></i>
								</label>
							</span>
						</span>
						<qc-tag-select
							v-if="sourceSet && !sourceSet.active"
							:tags="
								fs.qcTags().concat(
									(user.client.custom_rf_tags || []).map((tag) => ({
										id: tag,
										label: tag,
									}))
								)
							"
							v-model="selectedQcTags"
							:customTags.sync="selectedCustomTags"
							@added="tagsChanged"
							@removed="tagsChanged"
						/>
					</div>
					<table class="table">
						<thead>
							<tr>
								<th class="p-custom py-0 v-mid" v-if="availableResponses.length > 1">
									<a
										@click="maybeAssignAll()"
										class="btn btn-sm btn-icon btn-rounded theme-accent text-white m-0"
										v-tippy
										:title="$t('tooltip.add_all')"
									>
										<i class="far fa-plus-circle"></i>
									</a>
								</th>
								<th v-if="availableResponses.length <= 1"></th>
								<th>{{ $t("fields.id") }}</th>
								<th>{{ $t("fields.score") }}</th>
								<th>{{ $t("fields.actions") }}</th>
							</tr>
						</thead>
						<tbody>
							<tr v-if="!availableResponses.length">
								<td colspan="4">{{ $t("data_description.no_responses_available") }}</td>
							</tr>
							<tr
								v-for="resp in availableResponses"
								:key="resp.id"
								:style="{ 'background-color': resp.bg_color }"
							>
								<td class="p-custom py-0 v-mid">
									<a
										v-if="resp.true_score"
										@click="maybeAssign(resp)"
										class="btn btn-sm btn-icon btn-rounded theme-accent text-white m-0"
										v-tippy
										:title="$t('tooltip.add_to_set')"
									>
										<i class="far fa-plus"></i>
									</a>
								</td>
								<td class="p-custom py-0 v-mid">
									<div>
										<div>{{ resp.ref_id }}</div>
										<div class="text-muted text-tiny">
											<span
												v-tippy="{ theme: 'popover', placement: 'left-start' }"
												:title="listTags(resp.qc_tags)"
											>
												<i class="fas fa-tag" style="margin-right: 2px"></i>
												{{ fs.qcTagAbrs((resp.qc_tags || []).concat(resp.custom_tags || [])) }}
											</span>
										</div>
									</div>
								</td>
								<td class="v-mid">
									<span v-if="resp.true_score">
										<inline-score
											:score="resp.true_score"
											:rubric="item && item.rubric"
											:type="20"
											tooltip
										/>
									</span>
									<span v-if="!resp.true_score">--</span>
								</td>
								<td class="p-custom py-0 v-mid" style="min-width: 84px">
									<a
										:href="`#/qc_true_score/${resp.section_id}/${resp.item_id}/${resp.id}?return=true`"
										class="btn btn-sm btn-icon btn-rounded theme-accent text-white m-0"
										v-tippy
										:title="$t('tooltip.edit')"
									>
										<i class="far fa-edit"></i>
									</a>
									<a
										@click="deleteResp(resp)"
										class="btn btn-sm btn-icon btn-rounded red text-white m-0"
										v-tippy
										:title="$t('tooltip.delete')"
									>
										<i class="far fa-trash"></i>
									</a>
								</td>
							</tr>
						</tbody>
					</table>
				</div>
			</div>
		</div>

		<response-import-modal
			id="importModal"
			:visible="importingItem != false"
			:section="section"
			:item="item"
			:qcType="set.qc_type"
			@cancel="importingItem = false"
			@refresh="refresh"
		/>

		<div class="modal" id="importCommentsModal" data-backdrop="static" data-keyboard="false">
			<div class="modal-dialog">
				<div class="modal-content">
					<div class="modal-header">
						<h5 class="mb-0">{{ $t("QCSetResponses.final_feedback") }}</h5>
					</div>
					<div class="modal-body">
						<RichText v-if="!loadingComments" :initial="initComment" :text.sync="comment" />
						<loading
							v-if="loadingComments"
							type="large"
							class="text-very-muted text-center pt-5"
							style="height: 371px"
						/>
						<button
							class="btn float-right"
							v-tippy
							disabled
							v-if="
								assigningResp &&
								assigningResp.true_score &&
								assigningResp.true_score.annotations &&
								assigningResp.true_score.annotations.length > 0
							"
						>
							{{
								assigningResp.true_score.annotations.length == 1
									? "+1 Annotation"
									: `+${assigningResp.true_score.annotations.length} Annotations`
							}}
						</button>
					</div>
					<div class="modal-footer">
						<button class="btn btn-flat btn-primary mr-auto" data-dismiss="modal">Cancel</button>
						<button class="btn btn-flat btn-danger" data-dismiss="modal" @click="assign(assigningResp)">
							None
						</button>
						<button @click="assign(assigningResp, comment)" class="btn btn-flat theme" data-dismiss="modal">
							Apply
						</button>
					</div>
				</div>
			</div>
		</div>

		<div class="modal" id="importMultiCommentsModal" data-backdrop="static" data-keyboard="false">
			<div class="modal-dialog">
				<div class="modal-content">
					<div class="modal-header">
						<h5 class="mb-0">{{ $t("QCSetResponses.final_feedback") }}</h5>
					</div>
					<div class="modal-body">
						<p class="text-md">
							The responses you've selected include
							<strong
								>{{ multiResponseNum }} {{ multiResponseNum > 1 ? "responses" : "response" }}</strong
							>
							with {{ multiResponseNum > 1 ? "final feedback" : "a final feedback" }}.
						</p>
						<p class="text-md">
							Would you like to copy all final feedbacks into the QC feedback fields of these responses?
						</p>
						<small class="text-muted"
							>If you'd like to review or edit the final feedbacks before continuing, please add each
							response individually.</small
						>
					</div>
					<div class="modal-footer">
						<button class="btn btn-flat btn-primary mr-auto" data-dismiss="modal">Cancel</button>
						<button class="btn btn-flat btn-danger" data-dismiss="modal" @click="assignAll()">None</button>
						<button @click="assignAll(true)" class="btn btn-flat theme" data-dismiss="modal">Apply</button>
					</div>
				</div>
			</div>
		</div>
	</page>
</template>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.modal-lg {
	max-width: 90%;
}

.btn-subtle {
	opacity: 0.21;
	transition: opacity 0.25s;
}

.btn-subtle:hover {
	opacity: 0.6;
}

.text-tiny {
	font-size: 0.6rem;
}
</style>

<script>
import AuthService from "@/services/AuthService";
import QCConfigService from "@/services/QCConfigService";
import ConfigService from "@/services/ConfigService";
import SectionService from "@/services/SectionService";
import ItemService from "@/services/ItemService";
import UserService from "@/services/UserService";
import store from "@/services/Store";

import ConfigSelect from "@/components/ConfigSelect";
import QcTagSelect from "@/components/QcTagSelect";
import RichText from "@/components/RichText";
import InlineScore from "../../components/InlineScore";
import ResponseImportModal from "../../components/ResponseImportModal";
import Notie from "@/services/NotieService";
import fs from "@/services/FormatService";
import draggable from "vuedraggable";
import $ from "jquery";
import bb from "bluebird";

import Media from "@/components/viewer/Media";

export default {
	name: "QCSetResponses",
	data() {
		let selectedQcTags = store.getDefault(this, `qc_set_response.${this.$route.params.setID}.selectedQcTags`, []);
		let selectedCustomTags = store.getDefault(
			this,
			`qc_set_response.${this.$route.params.setID}.selectedCustomTags`,
			[]
		);

		return {
			fs: fs,
			user: null,
			setID: this.$route.params.setID,
			set: {},
			currentResponses: [],
			availableResponses: [],
			sourceSets: [],
			sourceSet: null,
			selectedQcTags,
			selectedCustomTags,
			filterUnusedResps: false,

			section: null,
			item: null,
			importingItem: false,

			assigningResp: null,
			initComment: null,
			comment: null,
			loadingComments: false,

			multiResponseNum: 0,
			multiCommentNum: 0,

			files: [],
			loaded: 0,
			uploading: false,

			editResponse: null,
		};
	},
	components: {
		ConfigSelect,
		QcTagSelect,
		draggable,
		Media,
		ResponseImportModal,
		RichText,
		InlineScore,
	},
	created() {
		AuthService.getUser().then((user) => {
			this.user = user;
		});
		this.init();
	},
	watch: {
		selectedQCTags: {
			handler: () => {
				this.constructAvailableResponses();
			},
			deep: true,
		},

		selectedCustomTags: {
			handler: () => {
				this.constructAvailableResponses();
			},
			deep: true,
		},

		filterUnusedResps() {
			this.constructAvailableResponses();
		},
	},
	computed: {},
	methods: {
		init() {
			QCConfigService.getQcSet(this.setID).then((resp) => {
				this.set = resp.data;
				QCConfigService.listSetResponses(this.set.id).then((resp) => {
					this.currentResponses = resp.data;
					this.currentResponses = _.sortBy(this.currentResponses, (r) => {
						return r.sequence;
					});
				});
				QCConfigService.listAvailableResponses(this.set.id).then((resp) => {
					this.sourceSets = resp.data;

					let sourceSetID = store.get(this, `qc_set_response.${this.setID}.sourceSetID`);
					let sourceSet = null;
					if (sourceSetID) {
						sourceSet = _.find(this.sourceSets, {
							id: sourceSetID,
						});
					}

					if (sourceSet) {
						this.sourceSet = sourceSet;
					} else {
						this.sourceSet = this.sourceSets[0];
					}

					this.constructAvailableResponses();
				});
				SectionService.getSection(this.set.section_id).then((resp) => {
					this.section = resp.data;
				});
				ItemService.getItem(this.set.item_id).then((resp) => {
					this.item = resp.data;
				});
			});
		},

		constructAvailableResponses() {
			if (!this.sourceSet) {
				this.availableResponses = [];
			} else {
				let avail = this.filterUnusedResps ? this.sourceSet.unused_responses : this.sourceSet.responses;
				store.set(this, `qc_set_response.${this.setID}.selectedQcTags`, this.selectedQcTags);
				store.set(this, `qc_set_response.${this.setID}.selectedCustomTags`, this.selectedCustomTags);
				if ((this.selectedQcTags.length > 0 || this.selectedCustomTags.length > 0) && !this.sourceSet.active) {
					avail = avail.filter(
						({ qc_tags, custom_tags }) =>
							(qc_tags || []).some((tag) => this.selectedQcTags.includes(tag)) ||
							(custom_tags || []).some((tag) => this.selectedCustomTags.includes(tag))
					);
				}

				_.each(avail, (a) => {
					a.bg_color = this.getBgColor(a.qc_tag);
				});

				this.availableResponses = avail;
				console.log(avail.length, this.availableResponses.length, this.availableResponses);
			}
		},

		maybeAssign(response) {
			if (
				(response.feedback && response.feedback != " ") ||
				(response.true_score && response.true_score.annotations && response.true_score.annotations.length > 0)
			) {
				this.openImportCommentsModal(response);
			} else {
				this.assign(response);
			}
		},

		openImportCommentsModal(response) {
			this.assigningResp = response;
			//this.loadingComments = true;
			this.initComment = response.feedback;
			// let userIDs = [];
			// _.each(response.comments, comment => {
			// 	if (comment.text) {
			// 		userIDs.push(comment.user_id);
			// 	}
			// });
			$("#importCommentsModal").modal("toggle");
			// UserService.getUserNames(userIDs)
			// 	.then(resp => {
			// 		let userMap = resp.data;
			// 		let commentTexts = [];
			// 		_.each(response.comments, comment => {
			// 			if (comment.text) {
			// 				let userName =
			// 					userMap[comment.user_id] || "Unknown";
			// 				commentTexts.push(
			// 					`<div class="_600">${userName}:</div><div>${
			// 						comment.text
			// 					}</div>`
			// 				);
			// 			}
			// 		});
			// 		this.initComment = commentTexts.join("<p></p>");
			// 		this.loadingComments = false;
			// 	})
			// 	.catch(err => {
			// 		Notie.error("Failed to load commenters", err);
			// 	});
		},

		openImportMultiCommentsModal(responseNum, commentNum) {
			this.multiResponseNum = responseNum;
			$("#importMultiCommentsModal").modal("toggle");
		},

		assignAndClose(response, comment) {
			this.assign(response, comment).then(() => {
				$("#importCommentsModal").modal("toggle");
			});
		},

		assign(response, feedback) {
			return QCConfigService.assignToSet(response.id, this.set.id, feedback)
				.then(() => {
					this.init();
					Notie.success(
						this.$i18n.t("notie.response_assigned_to", { ref_id: response.ref_id, name: this.set.name })
					);
				})
				.catch((e) => {
					console.error(e);
					Notie.error("Failed to assign response", e);
				});
		},

		maybeAssignAll() {
			let responseNum = 0;
			_.each(this.availableResponses, (resp) => {
				if (resp.feedback && resp.feedback != " ") {
					console.log("Response", resp.ref_id, "with feedback", resp.feedback);
					responseNum++;
				}
			});
			if (responseNum > 0) {
				this.openImportMultiCommentsModal(responseNum);
			} else {
				this.assignAll();
			}
		},

		assignAll(includeComments) {
			QCConfigService.assignManyToSet(_.map(this.availableResponses, "id"), this.set.id, includeComments)
				.then(() => {
					this.init();
				})
				.catch((err) => {
					console.log(err);
					Notie.error(this.$i18n.t("notie.assign_responses_fail"));
				});
		},

		refresh() {
			this.sourceSet = this.sourceSets[0];
			this.init();
		},

		attemptImport(item) {
			this.importingItem = this.selectedItem;
		},

		setSorted() {
			var counter = 1;
			_.each(this.currentResponses, (resp) => {
				// if (set) {
				if (resp.sequence != counter) {
					resp.sequence = counter;
					QCConfigService.saveQcResponse(resp);
				}
				// set.sequence = counter;
				counter++;
				// }
			});
		},

		editResp(resp) {
			this.editResponse = resp;
			$("#editRespModal").modal("show");
		},

		deleteResp(resp) {
			this.selectedQcTag = null;
			QCConfigService.deleteQcResponse(resp).then(() => {
				this.init();
				Notie.info(this.$i18n.t("notie.response_removed_from", { ref_id: resp.ref_id, name: this.set.name }));
			});
		},

		getBgColor(tagID) {
			let tag = fs.qcTagObject(tagID);
			return fs.qcTagBgColor(tag);
		},

		listTags(tagIDs) {
			if (!(tagIDs && tagIDs.length > 0)) {
				return "";
			}
			let tags = [];
			_.each(fs.qcTags(), (tag) => {
				if (tagIDs.includes(tag.id)) {
					tags.push(tag.label);
				}
			});

			return tags.join("<br />");
		},

		sourceSetSelected() {
			if (!this.sourceSet) {
				return;
			}

			store.set(this, `qc_set_response.${this.setID}.sourceSetID`, this.sourceSet.id);
			this.constructAvailableResponses();
		},

		tagsChanged() {
			this.constructAvailableResponses();
		},
	},
};
</script>


