<template>
	<page customNavBar customBody>
		<template #navbar>
			<div class="navbar navbar-light bg-light b-b">
				<a class="flex navbar-brand" style="cursor: default">
					<span v-if="set" @click="back()" style="cursor: pointer">{{ set.name }}</span>
					<i v-if="set" class="far fa-angle-right"></i>
					<span v-if="set">{{ user.first_name }} {{ user.last_name }}</span>
					<span v-if="!set">{{ $t("data_description.loading") }}</span>
					<i v-if="!set" class="far fa-sync-alt fa-spin" />
				</a>
				<a
					@click="init()"
					class="title-bar-button b-l ml-0 mr-0 d-flex flex-column justify-content-center"
					v-tippy="{ placement: 'bottom' }"
					:title="$t('tooltip.refresh')"
				>
					<i class="far fa-lg fa-sync-alt" :class="{ 'fa-spin': refreshing }"></i>
				</a>
				<div class="title-bar-button b-l ml-0 d-flex flex-column justify-content-center" @click="back()">
					{{ $t("buttons.back") }}
				</div>
			</div>
		</template>

		<div class="content-main" v-if="!loading">
			<div class="d-flex flex-row h100">
				<div class="col col-sm-3 b-r px-0">
					<table class="table">
						<thead>
							<tr>
								<th>{{ $t("QCUserSetReport.responses") }}</th>
								<th></th>
							</tr>
						</thead>
						<tbody>
							<tr
								class="pointer"
								v-if="reportData && reportData.responses"
								:class="{ active: row == selectedRow }"
								v-for="row in reportData.responses"
								:key="row.response.id"
								@click="selectRow(row)"
							>
								<td>{{ row.response.ref_id }}</td>
								<td>
									<span v-if="row.response.true_score"
										>{{ row.response.agreed }}/{{ row.response.total }}</span
									>
									<span v-if="!row.response.true_score">{{ row.response.total }}</span>
								</td>
							</tr>
						</tbody>
					</table>
				</div>
				<div v-if="selectedRow" class="col col-sm-9 light scroll">
					<div class="row light">
						<div class="col-sm-4" v-if="selectedRow.user_score">
							<score
								:compareScore="selectedRow.response.true_score"
								:score="selectedRow.user_score"
								:rubric="item && item.rubric"
								:type="15"
							/>
						</div>
						<div class="col-sm-4" v-if="!selectedRow.user_score">
							<div class="box h100">
								<div class="box-header light">
									<h3>{{ $t("QCUserSetReport.note") }}</h3>
								</div>
								<div class="box-body pb-0 text-center">
									<p>{{ $t("data_description.not_scored_response") }}</p>
									<button
										v-if="!unfinishing"
										class="btn theme"
										@click="goBackToScoring(selectedRow.response.id)"
									>
										{{ $t("buttons.return_to_scoring") }}
									</button>
									<button v-if="unfinishing" class="btn theme" disabled>
										{{ $t("buttons.return_to_scoring") }}
										<loading type="icon" />
									</button>
								</div>
							</div>
						</div>
						<div class="col-sm-8">
							<div class="box h100">
								<div class="box-header light">
									<h3>{{ $t("RFResponseReport.comment") }}</h3>
								</div>
								<div class="box-body">
									<p class="m-0" v-html="getComment(selectedRow.response)"></p>
								</div>
							</div>
						</div>
					</div>
					<div class="row scroll">
						<div class="col-sm-12">
							<media :response="selectedRow.response" />
						</div>
					</div>
				</div>
			</div>
		</div>
	</page>
</template>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.scroll {
	overflow: auto;
}
.title-bar-button {
	margin: -8px -16px;
	width: 56px;
	height: 56px;
	text-align: center;
	cursor: pointer;
}
.title-bar-button:hover {
	background-color: rgba(0, 0, 0, 0.075);
}
.title-bar-button a {
	line-height: 56px;
}
</style>

<script>
import AuthService from "@/services/AuthService";
import QCService from "@/services/QCService";
import ScoringService from "@/services/ScoringService";
import Notie from "@/services/NotieService";
import Score from "../../components/Score";
import InlineScore from "@/components/InlineScore";
import Media from "../../components/viewer/Media";
import UserService from "@/services/UserService";
import store from "../../services/Store";

import $ from "jquery";

export default {
	name: "QCUserSetReport",
	data() {
		return {
			user: null,
			set: null,

			item: null,
			responses: [],
			reportData: null,
			selectedRow: null,

			traitMap: null,
			total: 0,
			agreed: 0,
			loading: true,
			refreshing: false,
			unfinishing: false,

			userID: null,
		};
	},
	components: { Score, InlineScore, Media },
	created() {
		store.set(this, "viewerScale", 3);
		AuthService.getUser().then((user) => {
			this.user = user;
			if (this.$route.query.userID) {
				this.userID = this.$route.query.userID;
			} else {
				this.userID = user.id;
			}

			QCService.getQCSet(this.$route.params.set_id).then((resp) => {
				this.set = resp.data;
				ScoringService.getItem(this.set.item_id).then((r) => {
					this.item = r.data;
					this.init().then(() => {
						this.loading = false;
					});
					UserService.getUser(this.userID).then((resp) => {
						this.user = resp.data;
					});
				});
			});
		});
	},
	watch: {},
	beforeRouteEnter(to, from, next) {
		next((vm) => {
			if (from.path.indexOf("qc_reporting") != -1) {
				vm.return = "back";
			} else {
				vm.return = "scoring";
			}

			next();
		});
	},
	methods: {
		init() {
			this.refreshing = true;
			let index = 0;
			if (this.reportData && this.reportData.responses && this.selectedRow) {
				index = this.reportData.responses.indexOf(this.selectedRow);
			}
			return QCService.getUserQcSetReport(this.set.id, this.userID)
				.then((resp) => {
					console.log(resp.data);
					this.reportData = resp.data;
					this.parseStats();
					this.selectedRow = this.reportData.responses[index];
					this.refreshing = false;
				})
				.catch((err) => {
					Notie.error(this.$i18n.t("notie.load_score_data_fail"), err);
					this.refreshing = false;
				});
		},
		back() {
			if (this.return == "back") {
				this.$router.go(-1);
			} else {
				this.$router.push("/scoring");
			}
		},
		selectRow(row) {
			this.selectedRow = row;
			this.init();
		},
		getComment(resp) {
			let comment = _.find(resp.comments, { user_id: this.user.id });
			if (comment) {
				return comment.text;
			}
		},
		parseStats() {
			_.each(this.reportData.responses, (resp) => {
				if (resp.response.true_score) {
					resp.response.total = 0;
					resp.response.agreed = 0;
					_.each(resp.response.true_score.trait_scores, (ts) => {
						if (resp.user_score) {
							_.each(resp.user_score.trait_scores, (uts) => {
								if (ts.trait_id == uts.trait_id) {
									resp.response.total++;
									if (ts.score == uts.score) {
										resp.response.agreed++;
									}
								}
							});
						}
					});
				} else if (resp.user_score) {
					resp.response.total = resp.user_score.trait_scores.length;
				}
			});
		},
		pc(num, den) {
			return ((num / den) * 100).toFixed(2);
		},

		goBackToScoring(rid) {
			console.log("GO BACK TO SCORING", rid);
			this.unfinishing = true;
			let sid = this.set.section_id;
			let iid = this.set.item_id;
			let setid = this.set.id;
			QCService.unfinishRFSet(setid, rid)
				.then((r) => {
					this.unfinishing = false;
					this.$router.push(`/rangefinding/${sid}/${iid}/${setid}`);
				})
				.catch((e) => {
					this.unfinishing = false;
					console.log(e);
					Notie.error(this.$i18n.t("notie.return_to_rangefinding_fail"));
				});
		},
	},
};
</script>


