<template>
	<div class="tab-pane" :class="{ active: defaultTab, show: defaultTab }" :id="id">
		<div class="p-4 b-b _600 nav-title">{{ $t("TrainingEdit.Details.title") }}</div>
		<div class="px-4 pt-4 row pb-2">
			<div class="col-sm-6">
				<div :class="{ invalid: !valid.field('name') }">
					<label>{{ $t("TrainingEdit.Details.name") }}</label>
					<input type="text" class="form-control" v-model="course.name" />
				</div>
			</div>
			<div class="col-sm-6">
				<div :class="{ invalid: !(valid.field('ref') && valid.field('ref_char')) }">
					<label>{{ $t("TrainingEdit.Details.ref_id") }}</label>
					<input
						type="text"
						class="form-control"
						v-model.trim="course.ref_id"
						:disabled="course.isCompleted"
					/>
					<small v-if="!course.isCompleted" class="form-text text-muted">{{
						$t("TrainingEdit.Details.ref_id_unique")
					}}</small>
				</div>
			</div>
			<div class="col-sm-2">
				<div>Training Expiration</div>
				<label class="ui-switch ui-switch-md theme-accent">
					<input type="checkbox" v-model="course.expires" />
					<i></i>
				</label>
			</div>
			<div class="col-sm-10 row" v-if="course.expires">
				<div class="col-sm-3">
					<div>Expires At Specific Date</div>
					<label class="ui-switch ui-switch-md theme-accent">
						<input type="checkbox" v-model="course.expires_at_specific_date" />
						<i></i>
					</label>
				</div>
				<div v-if="course.expires_at_specific_date" class="col-sm-9">
					<div>Expiration Date</div>
					<config-date v-model="course.expires_at"></config-date>
				</div>
				<div
					v-else
					class="col-sm-9"
					:class="{ invalid: !(valid.field('expires_after_days') && valid.field('expires_after_days')) }"
				>
					<div>Days Expires After Completion</div>
					<b-form-input type="number" min="1" v-model="course.expires_after_days"></b-form-input>
				</div>
			</div>
		</div>
		<div class="px-4 pt-4 row pb-2">
			<div class="col-sm-1">
				<label>
					{{ $t("TrainingEdit.Details.priority") }}
				</label>
				<div>
					<b-form-input type="number" min="0" v-model="course.priority"></b-form-input>
				</div>
			</div>
		</div>
		<hr class="mt-2 mb-4" />
		<div class="px-4 pb-4 col-12 col-xl-10">
			<h5 class="mb-3">{{ $t("TrainingEdit.Details.elements") }}</h5>
			<div v-if="!(course.elements && course.elements.length > 0)" class="text-center text-muted">
				<div class="border p-2 m-2">{{ $t("data_description.no_course_elements") }}</div>
			</div>
			<draggable v-model="course.elements" :options="{ handle: '.drag-handle', animation: 150 }" @end="dragEnd">
				<training-details-element
					v-for="element in course.elements"
					:key="element.id"
					:course="course"
					:element="element"
					:scormLocations="scormLocations"
					:sections="sections"
					@delete="deleteElement(element)"
					class="element-box card shadow"
				/>
			</draggable>

			<div class="d-flex flex-row justify-content-stretch" style="padding: 0.6rem 0.75rem">
				<button @click="addElement()" class="flex btn btn-sm btn-success" :disabled="course.isCompleted">
					<i class="fas fa-plus mr-2" />{{ $t("TrainingEdit.Details.add_element") }}
				</button>
			</div>
		</div>
	</div>
</template>

<style scoped>
.element-box {
	padding: 0.5rem 1rem;
	margin-bottom: 1rem;
}

.element-box .drag-handle {
	position: absolute;
	top: 4px;
	left: 12px;
}
</style>

<script>
import ConfigService from "@/services/ConfigService";
import TrainingService from "@/services/TrainingService";
import Utils from "@/services/Utils";
import fs from "@/services/FormatService";
import Notie from "@/services/NotieService";

import DetailsScorm from "./Details/DetailsScorm";
import TrainingDetailsElement from "./TrainingDetailsElement";
import ConfigSelect from "@/components/ConfigSelect";
import ConfigDate from "@/components/ConfigDate";
import InlineScore from "@/components/InlineScore";
import draggable from "vuedraggable";
import BB from "bluebird";

const SCORM = 1;

export default {
	name: "TrainingEditDetails",

	props: ["id", "course", "defaultTab", "valid"],

	components: {
		TrainingDetailsElement,
		DetailsScorm,
		ConfigSelect,
		ConfigDate,
		InlineScore,
		draggable,
	},

	data() {
		return {
			fs,
			elementMeta: {},
			sections: null,
			scormLocations: [],
		};
	},

	watch: {
		"course.loaded"() {
			this.loadData();
		},
		"course.name"() {
			if (this.autoRef && !this.course.isCompleted) {
				let genRef = fs.toGoodRefID(this.course.name);
				if (this.course.ref_id.toLowerCase() != genRef.toLowerCase()) {
					this.course.ref_id = genRef;
				}
			}
		},
		"course.ref_id"() {
			this.checkAutoRef();
		},
	},

	created() {
		this.loadData();
		this.checkAutoRef();
	},

	methods: {
		loadData() {
			ConfigService.listSectionsShallow()
				.then((r) => {
					this.sections = r.data.sections;
				})
				.catch((e) => {
					console.error(e);
					Notie.error("Failed to load sections", e);
				});
			this.loadElementConfigs();
			this.loadScormLocations();
		},

		loadElementConfigs() {
			_.each(this.course.elements, (element) => {
				element.meta = Utils.metaAccessor(element, this.elementMeta);
				element.setMeta = Utils.metaSetter(element, this.elementMeta);
			});
		},

		async loadScormLocations() {
			try {
				const { data: locations } = await TrainingService.listScormLocations();
				const reqs = await BB.all(
					locations
						.map(({ bucket, id }) => (bucket === "local" ? "local" : id))
						.map(TrainingService.getScormLocationWithPackages)
				);
				this.scormLocations = reqs.map(({ data }) => data);
			} catch (err) {
				console.log(err);
				Notie.error("Failed to get scorm pool", err);
			}
		},

		checkAutoRef() {
			let genRef = fs.toGoodRefID(this.course.name);
			this.autoRef = this.course.ref_id.toLowerCase() == genRef.toLowerCase() && !this.course.isScored;
		},

		addElement() {
			if (!this.course.elements) {
				this.$set(this.course, "elements", []);
			}
			let newElement = {
				id: Utils.generateUUID(),
				name: "New Element",
				sequence: this.course.elements.length + 1,
				type: SCORM,
				scorm: {
					s3_dir: null,
				},
				upload_scorm_file: null,
				upload_resource_file: null,
				showDetails: false,
			};
			newElement.meta = Utils.metaAccessor(newElement, this.elementMeta);
			newElement.setMeta = Utils.metaSetter(newElement, this.elementMeta);
			this.course.elements.push(newElement);
		},

		deleteElement(element) {
			this.course.elements = _.without(this.course.elements, element);
			this.dragEnd();
		},

		dragEnd() {
			let seq = 1;
			_.each(this.course.elements, (el) => {
				el.sequence = seq;
				seq++;
			});
		},
	},
};
</script>
