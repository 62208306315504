import Vue from "vue";
import VueI18n from "vue-i18n";
import en from "./lang/en.json";
import engb from "./lang/en-GB.json";
import fr from "./lang/fr.json";
import es from "./lang/es.json";
import zh from "./lang/zh.json";

Vue.use(VueI18n)

// Construct a key file for the $i18n helper
let keyFile = {}
const traverseEn = function (obj, newObj, prefix) {
	for (let k of Object.keys(obj)) {
		let v = obj[k]
		if (typeof v == "object") {
			newObj[k] = {}
			traverseEn(obj[k], newObj[k], prefix ? prefix + `.${k}` : k)
		} else {
			newObj[k] = `${prefix}.${k}`
		}
	}
}
traverseEn(en, keyFile, "")

let lang = window.navigator.language;
//Cover all "en" settings so we don't get log spam if you are English (United States)
if (lang == "en-US") {
	lang = "en";
}

const languages = {
	en: en,
	"en-GB": engb,
	fr: fr,
	es: es,
	zh: zh,
	keys: keyFile
};
const messages = Object.assign(languages);

export default new VueI18n({
	locale: lang,
	fallbackLocale: "en",
	silentTranslationWarn: true,
	messages
});
