<template>
	<div class="list-item b-b">
		<div class="corner-tag">
			<i
				v-if="markImportant"
				class="fas excalamation-icon fa-exclamation-circle"
				:class="{ 'text-very-muted': !markUnread, 'text-danger': markUnread }"
			></i>
			<i v-if="!markImportant && markUnread" class="fas fa-dot-circle text-success"></i>
		</div>
		<span v-if="!mail.to_external && !sent" class="w-40 avatar circle" :class="{ blue: true }">{{
			initials(mail.from)
		}}</span>
		<span
			v-if="!mail.to_external && sent && mail.to.length == 1"
			class="w-40 avatar circle"
			:class="{ blue: true }"
			>{{ initials(mail.to[0].user) }}</span
		>
		<span
			v-if="!mail.to_external && sent && mail.to.length > 1"
			class="w-40 icon-avatar circle"
			:class="{ blue: true }"
		>
			<i class="far fa-lg fa-users"></i>
		</span>
		<span v-if="mail.to_external" class="w-40 icon-avatar circle" :class="{ green: true }">
			<i
				v-if="!sent"
				class="far fa-lg fa-mail-bulk"
				v-tippy="{ theme: 'popover' }"
				:title="$t('tooltip.will_send_to_external_email')"
			></i>
			<i
				v-if="sent"
				class="far fa-lg fa-mail-bulk"
				v-tippy="{ theme: 'popover' }"
				:title="$t('tooltip.sent_to_external_email')"
			></i>
		</span>
		<div class="list-body">
			<a v-if="!sent" @click="doRowClick" class="item-title _500">{{ getName(mail.from) }}</a>
			<a v-if="sent" @click="doRowClick" class="item-title _500">{{ getNames(mail.to) }}</a>
			<div class="item-except text-sm text-muted h-1x">{{ mail.subject }}</div>
		</div>
		<div class="d-flex flex-column align-items-end overflow-padding">
			<div class="item-date text-xs text-muted">{{ fs.sinceString(mail.updated_at) }}</div>
			<div class="mt-1">
				<a
					v-if="labels && labels.length && labels.length > 0"
					class="item-date text-xs text-muted"
					v-tippy
					:title="listTooltip(labels, 'Labels')"
				>
					{{ labels.length }}
					<i class="far fa-fw fa-tags"></i>
				</a>
				<a v-if="canDelete" @click="deleteMail" class="item-date text-xs text-muted">
					<i class="far fa-fw fa-trash"></i>
				</a>
			</div>
		</div>
	</div>
</template>

<style scoped>
.overflow-padding {
	margin-bottom: -0.75rem;
}
.corner-tag {
	position: absolute;
	left: 0.25rem;
	top: 0.25rem;
	margin-left: 0;
	margin-right: 0;
	line-height: 1;
}
.excalamation-icon {
	font-size: 0.9rem !important;
}
.list-body {
	min-width: 0px;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}
</style>

<script>
import moment from "moment";
import notie from "@/services/NotieService";
import fs from "@/services/FormatService";
import MailService from "@/services/MailService";

export default {
	name: "MailRow",
	props: {
		mail: { default: null },
		sent: { type: Boolean, default: false },
		markImportant: { type: Boolean, default: false },
		markUnread: { type: Boolean, default: false },
		canDelete: { type: Boolean, default: false },
		labels: { default: null },
		rowClick: { default: null }
	},

	data() {
		return {
			fs: fs
		};
	},
	created() {},
	watch: {},
	computer: {},
	methods: {
		deleteMail() {
			MailService.deleteMail(this.mail.id)
				.then(resp => {
					this.$emit("change");
				})
				.catch(err => {
					console.log(err);
					notie.error("Failed to delete mail", err);
				});
		},

		getNames(users) {
			if (users.first_name || users.last_name) {
				//Just a single user
				return this.getName(users);
			} else {
				let names = [];
				_.each(users, user => {
					if (user.first_name || user.last_name) {
						//Actual user object
						names.push(this.getName(user));
					} else {
						//UserMail object
						if (user.user && (user.user.first_name || user.user.last_name)) {
							//Yes, UserMail object
							names.push(this.getName(user.user));
						} else {
							//No, we don't know what this is!
							names.push("UNKNOWN");
						}
					}
				});

				return names.join(", ");
			}
		},

		getName(user) {
			let name = "";
			if (user.first_name) {
				name = user.first_name;
				if (user.last_name) {
					name = name + " " + user.last_name;
				}
			} else {
				name = user.last_name;
			}
			return name;
		},

		getSentTime(date) {
			return moment(date).fromNow();
		},

		initials(user) {
			let first_initial = user.first_name ? user.first_name.substring(0, 1).toUpperCase() : "";
			let last_initial = user.last_name ? user.last_name.substring(0, 1).toUpperCase() : "";
			return first_initial + last_initial;
		},

		doRowClick() {
			if (this.rowClick) {
				this.rowClick(this.mail);
			}
		},

		listTooltip(objects, title) {
			if (objects && objects.length > 0) {
				let nameList = `${title}:<br /><br /><ul class='pl-4 pr-3'>`;
				_.each(objects, object => {
					nameList += `<li style="text-align: left;">${object}</li>`;
				});

				return nameList + "</ul>";
			} else {
				return "";
			}
		}
	}
};
</script>
