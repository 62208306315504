import axios from "axios";

var backendKey = null;

export default {
	getKey() {
		return new Promise((resolve, reject) => {
			if (backendKey == null) {
				axios.get(`/v1/spread_js/key`).then(r => {
					backendKey = r.data
					console.log("Loaded Spread JS Key from backend:", this.keyIdentifier(backendKey));
					resolve(r.data)
				}).catch(e => {
					backendKey = ""
					console.error("Failed to get Spread JS Key from backend");
					console.error(e);
					resolve("")
				})
			} else if (backendKey) {
				resolve(backendKey)
			} else {
				let localKey = this.getLocalKey()
				console.log("Loaded Spread JS Key locally:", this.keyIdentifier(localKey));
				resolve(localKey)
			}
		})
	},

	getLocalKey() {
		var host = window.location.hostname;
		if (host == "app.oscarscore.com") {
			return "app.oscarscore.com,442189295528168#B0hg5UENzK5YGbh9UYwljUSFVU0hDchhGeYFlb7ZlR7gWcJNzK0pVas3CbypXe9pHO0BDRNZjY0dGdLJ7LBdEULRzbLdTNtpnMOJ7YMhUM826RPhHOE9US8pFMr3kWYJnZJFDWuF5ZDp7MyIlaBx6R7s4Q7U4TRZWZ9ZzZ83mZRlHWXVlcx8UaEFmNxElZUxWWQx4LulmUBhnTB36byNEOvE4byJXZ7BVTjRHVTRVesxETYV6N4kzYhVEawZjMElFbCZmRoJ7RsxEZoNmRoR6Z8YkYxN7LUdHex56Zx8WbpVTdvclZGFXV8Q5QlZGM88WaIFkI0IyUiwiIGFTQyQkQxcjI0ICSiwSN4gDO8kjNxUTM0IicfJye#4Xfd5nIMFUUTJiOiMkIsISNx8idgMlSgQWYlJHcTJiOi8kI1tlOiQmcQJCLiUjM6MzNwAiMygDMyIDMyIiOiQncDJCLi46bj9SZy36YzJXYjN7buAHchJiOiMXbEJCLiQnbl5GcvxWZ6VGRgoVTiojIh94QiwiI8YTM8ITN5kjM9gTMyQDNiojIklkIs4XXbpjInxmZiwSZzxWYmpjIyNHZisnOiwmbBJye0ICRiwiI34TU55mbCFGavRmc6hmWBh4cXhXahpWcPZHUwlTYuVTOh5kdaJEZrVmaGhFOLJ5STl5YnR4SkBTdkljVuh7R5UzdzFUSFh4UWlXWzclTrMVeaRGWwh5VRJTNOVkVxJ6MJtmRxU4U";
		} else if (host == "www.oscarscore.uk") {
			return "www.oscarscore.uk,368228167421326#B0kNdJHU0p7LNt6Qyp6VENWO7gEdmVzV4JVUjFWZIdVNpdkbDFUZjhUOFRjbOJkZPxGbYh6ajtSVhV5QjVXVtFUbiFGRnp7LlN6dHhjNxcnTZh4LuNjWkhmY4dmMOVWNOR5V4lHbllVRhxURjJDO4tSNKdUTklHVDFVOspUYG5WZzMXVrQmc8o7crlnU0RDSpxEbxtSdRZVNFJXbyMHaHJWSHR6YCVEUTdDW9UkTvhzLuVHU7R6RvRkNN3UUzVEMURlcUJ5SwEnNGJEU6RkYIlVZwBXSvUXNSFTeKF7LvQXWtVDM7UTdKtSVUNXNZJ4T5YUaiojITJCLiM4QCN4M8UkI0ICSiwiN7AjN9IjMyATM0IicfJye35XX3JCTBF5UiojIDJCLiUTMuYHITpEIkFWZyB7UiojIOJyebpjIkJHUiwiIxUDNzcDMgIjM8AjMyAjMiojI4J7QiwiIrVnLlJ7bjNnchN6cv9yd7dnI0IyctRkIsICduVWbw3GblZXZEBiWNJiOiEmTDJCLiYjMzEjM4cjNxgjMygjNzIiOiQWSiwSfdtlOicGbmJCLlNHbhZmOiI7ckJye0ICbuFkI1pjIEJCLi4TP7J4RQlmYyxmeCJzcnZnVYFmdGJnYzl5S9ckb9RWT4IUZ7UTd6J5cnBzc9BVSLtWa4JGR8tCVoplbqNUNrknaZFTbrgVQDVjYpJDWFd4M5YTTNdnUJdGVlNEbKJEaCBVOQB2eJc";
		} else if (host == "staging.oscarscore.com") {
			return "staging.oscarscore.com,321748295734636#B1NMhtWZoRkZIVzdzkHSp9mRndkcJNFawZUQwNlSxllZTl7KzJGcT94VklVNLZzR6NWQpN6bOZnZHVDbzk4bxQ7doh5SDdlZ5JmNvVnapVnerhleth6KKV6NTRFTJZ4b0RXYxAnesVGRthFNsxUSvAzaylHa4NEdK34QmtEUiJ7LQtWNH5EcvYTerdUWHFXRVB5KXt6dTNDbhZ4MNlkQRVXNBt4d6xGZ7cXSTh6b4RHT5ZDaFZkaORGaixGULRGVmBVW5IWWwlHcVNzKLJEWyVnWv3SewA5My8kaQtWaMl7QtBDTQtkM6h6NSZkWwAlS7QneEFEUiojITJCLiIkMyQTQBFjI0ICSiwyM4UDM4kjM5gTM0IicfJye#4Xfd5nIXR5QaJiOiMkIsIiNx8idgMlSgQWYlJHcTJiOi8kI1tlOiQmcQJCLicTN8ADOwACOxkDMzIDMyIiOiQncDJCLi46bj9SZy36YzJXYjN7bucmbpdWY4NnI0IyctRkIsICduVWbw3GblZXZEBiWNJiOiEmTDJCLiYzM6QzM7UTOygDN7EjMzIiOiQWSiwSfdtlOicGbmJCLlNHbhZmOiI7ckJye0ICbuFkI1pjIEJCLi4TP7VUdL5WOahVYrcVZ93SNuR6V7IUOHl7NLdzUVRzM8oEN9ZlNuRkZpFXTy4Wa8l6cYpEZwg7V88EeCNWNTZFTjdEZQFDT6pEOmVzTThTb5QFTnhGMlZEMBFWMzkVMlNJaLJ";
			// return "staging.oscarscore.com,899216931139484#B13K4kVVC3EZsBlZGVUaKlXRDRVQklFdthTYMdUUTB5ZsBnV6tES0FDWtN6c42GUQRlbEBzQ5dVOxB5bxNlayZ6aPlDMINkMRhkZjFWW6VVMkVHbOVESDdzc4c7SFFzYLZHOTVld9FVcQNmNpdUShFkMH5kaO9ERkR5RydWb7d4b4VHWQpXM7UlerwkQnVDZYlTRaJTVtFHcpdTM5tGTrg5YBtGUzFzL0ZkSUJFZTpXexN4KyJkVtFzQEJGbWVHM996U4Z6TJBTQZNFVaFlQ5RUapVTYNZTRQdVS7ZlNvw4cZ96UZRHN5kzT6MzNLFHamVFSDxEZiojITJCLiUTR5YENBZkMiojIIJCLzcTNxAzM7YTO0IicfJye#4Xfd5nIXR5QaJiOiMkIsIiNx8idgMlSgQWYlJHcTJiOi8kI1tlOiQmcQJCLiMzM8MzNwACNxkDMzIDMyIiOiQncDJCLi46bj9SZy36YzJXYjN7bucmbpdWY4NnI0IyctRkIsICduVWbw3GblZXZEBiWNJiOiEmTDJCLiQDO4kzMxEzM9YTMykTO8IiOiQWSiwSfdtlOicGbmJCLlNHbhZmOiI7ckJye0ICbuFkI1pjIEJCLi4TPBtWWMZzczl4Vz34RLNXRClmQC5GbQRmZrBDdvFDdJ34RalGOSVXW0F6SY5ERx8WTx44NlVzMzlEZTJmeWZ6KPpVethlWylTM0JnSmxmazZFU0hGZj34NRNlaxcnT55zZ5Z";
		}
	},

	keyIdentifier(key) {
		if (!key) {
			return "(blank)"
		}
		let parts = key.split("#");
		return parts[0];
	}
};