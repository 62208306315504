<template>
	<page customNavBar customBody>
		<template #navbar>
			<div class="navbar navbar-light bg-light b-b flex-nowrap no-grow">
				<div class="navbar-text nav-title flex" id="pageTitle">
					{{ $t("ReportCompletion.reporting") }}
					<span class="far fa-fw fa-angle-right"></span>{{ $t("ReportCompletion.completion") }}
				</div>
				<div v-if="!user.role.limit_teams" class="checkbox mr-3">
					<label class="md-check">
						<input v-model="showAssignedOnly" type="checkbox" />
						<i class="theme-accent"></i>
						{{ $t("ReportCompletion.my_assignments_only") }}
					</label>
				</div>
				<div class="float-right navbar-text pointer" @click="init(true)" v-tippy :title="$t('tooltip.refresh')">
					<i class="far fa-sync-alt" :class="{ 'fa-spin': loading || !liveReport }"></i>
				</div>
			</div>
		</template>

		<div class="flex d-flex flex-row scroll-x scroll-y">
			<div class="flex">
				<div class="row mx-4">
					<div class="align-self-stretch col-4 py-3">
						<div class="h-100 box-shadow-heavy card theme">
							<div class="card-body d-flex flex-row align-items-center">
								<h4 class="mb-0 mr-1">
									<i class="fas fa-fw fa-inventory mr-2"></i>
								</h4>
								<div class="text-center">
									<h4 class="mb-0 d-inline-block">
										<span>{{ $t("ReportCompletion.responses") }}:</span>
										<loading type="icon" v-if="loading" />
										<span v-if="!loading">{{ getOverall("total").toLocaleString() }}</span>
									</h4>
								</div>
							</div>
						</div>
					</div>
					<div class="align-self-stretch col-4 py-3">
						<div class="h-100 box-shadow-heavy card theme">
							<div class="card-body d-flex flex-row align-items-center">
								<h4 class="mb-0 mr-1">
									<i class="fas fa-fw fa-flag mr-2"></i>
								</h4>
								<div class="text-center">
									<h4 class="mb-0 mr-1 d-inline-block">
										<span>{{ $t("ReportCompletion.flagged") }}:</span>
										<loading type="icon" v-if="loading" />
										<span v-if="!loading">{{ getOverall("flagged").toLocaleString() }}</span>
									</h4>
									<h5 class="mb-0 d-inline-block">
										<span v-if="!loading" class="text-muted"
											>({{
												((getOverall("flagged") * 100) / getOverall("total")).toFixed(0)
											}}%)</span
										>
									</h5>
								</div>
							</div>
						</div>
					</div>
					<div class="align-self-stretch col-4 py-3">
						<div class="h-100 box-shadow-heavy card theme">
							<div class="card-body d-flex flex-row align-items-center">
								<h4 class="mb-0 mr-1">
									<i class="fas fa-fw fa-badge-check mr-2"></i>
								</h4>
								<div class="text-center">
									<h4 class="mb-0 mr-1 d-inline-block">
										<span>{{ $t("ReportCompletion.complete") }}:</span>
										<loading type="icon" v-if="loading" />
										<span v-if="!loading">{{ getOverall("complete").toLocaleString() }}</span>
									</h4>
									<h5 class="mb-0 d-inline-block">
										<span v-if="!loading" class="text-muted"
											>({{
												((getOverall("complete") * 100) / getOverall("total")).toFixed(0)
											}}%)</span
										>
									</h5>
								</div>
							</div>
						</div>
					</div>
				</div>

				<div class>
					<div
						v-for="(project, i) in projects"
						:key="project.id"
						class="mx-3 box"
						:class="{ 'mb-1': determineCardMargin(projects, i) }"
					>
						<div class="d-flex flex-row">
							<div class="flex justify-content-start align-self-center ml-2">
								<a @click="toggleCollapse(project.id)">
									<i
										class="fas fa-lg fa-angle-down anim-rotate"
										:class="{ 'rotate-180': collapsed[project.id] }"
									></i>
								</a>
							</div>
							<label
								class="flex d-flex justify-content-center align-self-center black-text my-1 _600 text-theme"
								>{{ project.name }}</label
							>

							<div class="flex d-flex justify-content-end">
								<a
									v-if="!collapsed[project.id]"
									class="btn-subtle my-1 mx-2"
									v-tippy
									:title="$t('tooltip.export')"
									data-toggle="dropdown"
									aria-expanded="false"
								>
									<i class="fas fa-file-export" style="margin-left: 2px"></i>
								</a>
								<div class="dropdown-menu dropdown-menu-right w">
									<a class="dropdown-item" @click="dlPdf(project)">
										<i class="far fa-file-pdf fa-fw fa-lg"></i>
										<label class="mb-0">{{ $t("ReportCompletion.export_to_pdf") }}</label>
									</a>
									<a class="dropdown-item" @click="dlCsv(project)">
										<i class="far fa-file-excel fa-fw fa-lg"></i>
										<label class="mb-0">{{ $t("ReportCompletion.export_to_csv") }}</label>
									</a>
								</div>
							</div>
						</div>

						<div v-if="!collapsed[project.id]" class :class="{ cached: !liveReport }">
							<table class="table" style="display: block">
								<thead v-show="!loading">
									<tr>
										<th>
											<div class="t-margin p-custom"></div>
										</th>
										<th style="width: 16px"></th>
										<th></th>
										<th style="width: 10%" class="ar d-long">{{ $t("fields.total") }}</th>
										<th style="width: 10%" class="ar d-abr" v-tippy :title="$t('tooltip.total')">
											{{ $t("fields.total_abr") }}
										</th>
										<th style="width: 10%" class="ar d-long">
											{{ $t("fields.unscored") }}
										</th>
										<th style="width: 10%" class="ar d-abr" v-tippy :title="$t('tooltip.unscored')">
											{{ $t("fields.unscored_abr") }}
										</th>
										<th style="width: 10%" class="ar d-long">
											{{ $t("fields.withheld") }}
										</th>
										<th style="width: 10%" class="ar d-abr" v-tippy :title="$t('tooltip.withheld')">
											{{ $t("fields.withheld_abr") }}
										</th>
										<th style="width: 10%" class="ar d-long">
											{{ $t("fields.reliability") }}
										</th>
										<th
											style="width: 10%"
											class="ar d-abr"
											v-tippy
											:title="$t('tooltip.reliability')"
										>
											{{ $t("fields.reliability_abr") }}
										</th>
										<th style="width: 10%" class="ar d-long" v-if="showAwaitingRes">
											{{ $t("fields.awaiting_resolution") }}
										</th>
										<th
											style="width: 10%"
											class="ar d-abr"
											v-tippy
											:title="$t('tooltip.awaiting_resolution')"
											v-if="showAwaitingRes"
										>
											{{ $t("fields.awaiting_resolution_abr") }}
										</th>
										<th style="width: 10%" class="ar d-long">
											{{ $t("fields.resolution") }}
										</th>
										<th
											style="width: 10%"
											class="ar d-abr"
											v-tippy
											:title="$t('tooltip.resolution')"
										>
											{{ $t("fields.resolution_abr") }}
										</th>
										<th style="width: 10%" class="ar d-long">
											{{ $t("fields.flagged") }}
										</th>
										<th style="width: 13%" class="ar d-abr" v-tippy :title="$t('tooltip.flagged')">
											{{ $t("fields.flagged_abr") }}
										</th>
										<th style="width: 10%" class="ar d-long">
											{{ $t("fields.reviewed") }}
										</th>
										<th
											style="width: 13%"
											class="ar d-abr"
											v-tippy
											:title="$t('tooltip.flagged_and_reviewed')"
										>
											{{ $t("fields.reviewed_abr") }}
										</th>
										<th style="width: 10%" class="ar d-long">
											{{ $t("fields.complete") }}
										</th>
										<th style="width: 13%" class="ar d-abr" v-tippy :title="$t('tooltip.complete')">
											{{ $t("fields.complete_abr") }}
										</th>
										<th style="width: 10%" class="ar d-long">
											{{ $t("fields.backread") }}
										</th>
										<th style="width: 13%" class="ar d-abr" v-tippy :title="$t('tooltip.backread')">
											{{ $t("fields.backread_abr") }}
										</th>
										<th
											style="width: 20%"
											class="ar d-long"
											v-tippy
											:title="$t('tooltip.resolution_required')"
										>
											{{ $t("fields.resolution_required") }}
										</th>
										<th
											style="width: 13%"
											class="ar d-abr"
											v-tippy
											:title="$t('tooltip.resolution_required')"
										>
											{{ $t("fields.resolution_required_abr") }}
										</th>
										<th style="width: 13%" class="ar d-long" v-tippy :title="$t('tooltip.appeals')">
											{{ $t("fields.appeals") }}
										</th>
										<th style="width: 13%" class="ar d-abr" v-tippy :title="$t('tooltip.appeals')">
											{{ $t("fields.appeals_abr") }}
										</th>
										<template v-if="project.totals.rescores > 0">
											<th
												style="width: 20%"
												class="ar d-long"
												v-tippy
												:title="$t('tooltip.rescore_requested')"
											>
												{{ $t("fields.rescore_requested") }}
											</th>
											<th
												style="width: 13%"
												class="ar d-abr"
												v-tippy
												:title="$t('tooltip.rescore_requested')"
											>
												{{ $t("fields.rescore_requested_abr") }}
											</th>
										</template>
										<template v-if="project.totals.invalidated > 0">
											<th
												style="width: 20%"
												class="ar d-long"
												v-tippy
												:title="$t('tooltip.invalidated')"
											>
												{{ $t("fields.invalidated") }}
											</th>
											<th
												style="width: 13%"
												class="ar d-abr"
												v-tippy
												:title="$t('tooltip.invalidated')"
											>
												{{ $t("fields.invalidated_abr") }}
											</th>
										</template>
										<th>
											<div class="t-margin p-custom"></div>
										</th>
									</tr>
								</thead>
								<tbody v-show="loading">
									<tr>
										<td colspan="10">
											<loading type="table" />
										</td>
									</tr>
								</tbody>
								<tbody v-show="!loading">
									<tr class="theme-lighten-3 text-on-color">
										<td class="t-margin p-custom"></td>
										<td colspan="2">{{ $t("ReportCompletion.totals") }}</td>
										<td class="ar">{{ project.totals.total }}</td>
										<td class="ar">{{ project.totals.unscored }}</td>
										<td class="ar">{{ project.totals.withheld }}</td>
										<td class="ar">{{ project.totals.reliability }}</td>
										<td class="ar" v-if="showAwaitingRes">{{ project.totals.awaiting }}</td>
										<td class="ar">{{ project.totals.resolution }}</td>
										<td class="ar">{{ project.totals.flagged }}</td>
										<td class="ar">
											{{ project.totals.flagged_reviewed }}
											<span class="text-muted text-xs"
												>({{
													pcr(project.totals.flagged_reviewed, project.totals.flagged)
												}}%)</span
											>
										</td>
										<td class="ar">
											{{ project.totals.complete }}
											<span class="text-muted text-xs"
												>({{ pcr(project.totals.complete, project.totals.total) }}%)</span
											>
										</td>
										<td class="ar">
											{{ project.totals.backread }}
											<span class="text-muted text-xs"
												>({{ pcr(project.totals.backread, project.totals.complete) }}%)</span
											>
										</td>
										<td class="ar">
											{{ project.totals.resolution_required }}
											<span class="text-muted text-xs">
												({{
													pcr(
														project.totals.resolution_required,
														project.totals.completew2 + project.totals.resolution
													)
												}}%)
											</span>
										</td>
										<td class="ar">
											{{ project.totals.appeals }}
										</td>
										<td v-if="project.totals.rescores > 0" class="ar">
											{{ project.totals.rescores }}
										</td>
										<td v-if="project.totals.invalidated > 0" class="ar">
											{{ project.totals.invalidated }}
										</td>
										<td class="t-margin p-custom"></td>
									</tr>
								</tbody>
								<tbody v-show="!loading" v-for="section in project.sections" :key="section.id">
									<tr class="theme-lighten-5 text-on-color">
										<td class="t-margin p-custom"></td>
										<td rowspan="2" colspan="2" style="vertical-align: middle">
											<div class="section-name" v-tippy :title="section.name">
												{{ section.name }}
											</div>
										</td>
										<td class="ar d-long">{{ $t("fields.total") }}</td>
										<td class="ar d-abr" v-tippy :title="$t('tooltip.total')">
											{{ $t("fields.total") }}
										</td>

										<td class="ar d-long">{{ $t("fields.unscored") }}</td>
										<td class="ar d-abr" v-tippy :title="$t('tooltip.unscored')">
											{{ $t("fields.unscored_abr") }}
										</td>
										<td class="ar d-long">{{ $t("fields.withheld") }}</td>
										<td class="ar d-abr" v-tippy :title="$t('tooltip.withheld')">
											{{ $t("fields.withheld_abr") }}
										</td>
										<td class="ar d-long">{{ $t("fields.reliability") }}</td>
										<td class="ar d-abr" v-tippy :title="$t('tooltip.reliability')">
											{{ $t("fields.reliability_abr") }}
										</td>
										<td class="ar d-long" v-if="showAwaitingRes">
											{{ $t("fields.awaiting_resolution") }}
										</td>
										<td
											class="ar d-abr"
											v-tippy
											:title="$t('tooltip.awaiting_resolution')"
											v-if="showAwaitingRes"
										>
											{{ $t("fields.awaiting_resolution_abr") }}
										</td>
										<td class="ar d-long">{{ $t("fields.resolution") }}</td>
										<td class="ar d-abr" v-tippy :title="$t('tooltip.resolution')">
											{{ $t("fields.resolution_abr") }}
										</td>
										<td class="ar d-long">{{ $t("fields.flagged") }}</td>
										<td class="ar d-abr" v-tippy :title="$t('tooltip.flagged')">
											{{ $t("fields.flagged_abr") }}
										</td>
										<td class="ar d-long">{{ $t("fields.reviewed") }}</td>
										<td class="ar d-abr" v-tippy :title="$t('tooltip.flagged_and_reviewed')">
											{{ $t("fields.reviewed_abr") }}
										</td>
										<td class="ar d-long">{{ $t("fields.complete") }}</td>
										<td class="ar d-abr" v-tippy :title="$t('tooltip.complete')">
											{{ $t("fields.complete_abr") }}
										</td>
										<td class="ar d-long">{{ $t("fields.backread") }}</td>
										<td class="ar d-abr" v-tippy :title="$t('tooltip.backread')">
											{{ $t("fields.backread_abr") }}
										</td>
										<td class="ar d-long" v-tippy :title="$t('tooltip.resolution_required')">
											{{ $t("fields.resolution_required") }}
										</td>
										<td class="ar d-abr" v-tippy :title="$t('tooltip.resolution_required')">
											{{ $t("fields.resolution_required_abr") }}
										</td>
										<td class="ar d-long" v-tippy :title="$t('tooltip.appeals')">
											{{ $t("fields.appeals") }}
										</td>
										<td class="ar d-abr" v-tippy :title="$t('tooltip.appeals')">
											{{ $t("fields.appeals_abr") }}
										</td>
										<template v-if="project.totals.rescores > 0">
											<td class="ar d-long" v-tippy :title="$t('tooltip.rescore_requested')">
												{{ $t("fields.rescore_requested") }}
											</td>
											<td class="ar d-abr" v-tippy :title="$t('tooltip.rescore_requested')">
												{{ $t("fields.rescore_requested_abr") }}
											</td>
										</template>
										<template v-if="project.totals.invalidated > 0">
											<td class="ar d-long" v-tippy :title="$t('tooltip.invalidated')">
												{{ $t("fields.invalidated") }}
											</td>
											<td class="ar d-abr" v-tippy :title="$t('tooltip.invalidated')">
												{{ $t("fields.invalidated_abr") }}
											</td>
										</template>
										<td class="t-margin p-custom"></td>
									</tr>
									<tr class="theme-lighten-5 text-on-color">
										<td class="t-margin p-custom"></td>

										<td class="ar">{{ section.totals.total }}</td>

										<td class="ar">{{ section.totals.unscored }}</td>

										<td class="ar">{{ section.totals.withheld }}</td>

										<td class="ar">{{ section.totals.reliability }}</td>

										<td class="ar" v-if="showAwaitingRes">
											{{ section.totals.awaiting }}
										</td>

										<td class="ar">{{ section.totals.resolution }}</td>

										<td class="ar">{{ section.totals.flagged }}</td>

										<td class="ar">
											{{ section.totals.flagged_reviewed }}
											<span class="text-muted text-xs"
												>({{
													pcr(section.totals.flagged_reviewed, section.totals.flagged)
												}}%)</span
											>
										</td>

										<td class="ar">
											{{ section.totals.complete }}
											<span class="text-muted text-xs"
												>({{ pcr(section.totals.complete, section.totals.total) }}%)</span
											>
										</td>

										<td class="ar">
											{{ section.totals.backread }}
											<span class="text-muted text-xs"
												>({{ pcr(section.totals.backread, section.totals.complete) }}%)</span
											>
										</td>

										<td class="ar">
											{{ section.totals.resolution_required }}
											<span class="text-muted text-xs"
												>({{
													pcr(
														section.totals.resolution_required,
														section.totals.completew2 + section.totals.resolution
													)
												}}%)</span
											>
										</td>

										<td class="ar">
											{{ section.totals.appeals }}
										</td>

										<td v-if="project.totals.rescores > 0" class="ar">
											{{ section.totals.rescores }}
										</td>

										<td v-if="project.totals.invalidated > 0" class="ar invalidated">
											{{ section.totals.invalidated }}
										</td>

										<td class="t-margin p-custom"></td>
									</tr>
									<tr v-for="item in section.items" :key="item.id">
										<td class="t-margin p-custom"></td>
										<td
											class="ar text-center clickable theme-hover-lighten-7"
											@click="burndown(section.id, item.id)"
											v-tippy
											title="Burndown Report"
										>
											<i class="far fa-clipboard-list"></i>
										</td>
										<td>
											<div class="item-name" v-tippy :title="item.name">
												{{ item.name }}
											</div>
										</td>
										<td
											class="ar"
											@click="cellClick(section.id, item.id, 0)"
											:class="{ 'clickable theme-hover-lighten-7': canLink }"
										>
											<a>{{ item.total }}</a>
										</td>
										<td
											class="ar"
											@click="cellClick(section.id, item.id, 1)"
											:class="{ 'clickable theme-hover-lighten-7': canLink }"
										>
											<a>{{ item.unscored }}</a>
										</td>
										<td
											class="ar"
											@click="cellClick(section.id, item.id, 20)"
											:class="{ 'clickable theme-hover-lighten-7': canLink }"
										>
											<a>{{ item.withheld }}</a>
										</td>
										<td
											class="ar"
											@click="cellClick(section.id, item.id, 2)"
											:class="{ 'clickable theme-hover-lighten-7': canLink }"
										>
											<a>{{ item.reliability }}</a>
										</td>
										<td
											class="ar"
											@click="cellClick(section.id, item.id, 40)"
											:class="{ 'clickable theme-hover-lighten-7': canLink }"
											v-if="showAwaitingRes"
										>
											<a>{{ item.awaiting }}</a>
										</td>
										<td
											class="ar"
											@click="cellClick(section.id, item.id, 3)"
											:class="{ 'clickable theme-hover-lighten-7': canLink }"
										>
											<a>{{ item.resolution }}</a>
										</td>
										<td
											class="ar"
											@click="cellClick(section.id, item.id, 0, null, true)"
											:class="{ 'clickable theme-hover-lighten-7': canLink }"
										>
											{{ item.flagged }}
										</td>
										<td
											class="ar"
											@click="cellClick(section.id, item.id, 0, null, true, true)"
											:class="{ 'clickable theme-hover-lighten-7': canLink }"
										>
											{{ item.flagged_reviewed }}
											<span class="text-muted text-xs"
												>({{ pcr(item.flagged_reviewed, item.flagged) }}%)</span
											>
										</td>
										<td
											class="ar"
											@click="cellClick(section.id, item.id, 10)"
											:class="{ 'clickable theme-hover-lighten-7': canLink }"
										>
											<a>
												{{ item.complete }}
												<span class="text-muted text-xs"
													>({{ pcr(item.complete, item.total) }}%)</span
												>
											</a>
										</td>
										<td
											class="ar"
											@click="cellClick(section.id, item.id, 0, 4)"
											:class="{ 'clickable theme-hover-lighten-7': canLink }"
										>
											<a>
												{{ item.backread }}
												<span class="text-muted text-xs"
													>({{ pcr(item.backread, item.complete) }}%)</span
												>
											</a>
										</td>
										<td
											class="ar"
											@click="cellClick(section.id, item.id, 0, 3)"
											:class="{ 'clickable theme-hover-lighten-7': canLink }"
										>
											<a>
												{{ item.resolution_required }}
												<span class="text-muted text-xs"
													>({{
														pcr(
															item.resolution_required,
															item.completew2 + item.resolution
														)
													}}%)</span
												>
											</a>
										</td>

										<td
											class="ar"
											@click="cellClick(section.id, item.id, responseStates.APPEAL.id)"
											:class="{ 'clickable theme-hover-lighten-7': canLink }"
										>
											<a>{{ item.appeals }} </a>
										</td>

										<template v-if="project.totals.rescores > 0">
											<td v-if="item.rescores == 0" class="ar">
												<a>{{ item.rescores }}</a>
											</td>
											<td
												v-else
												class="ar clickable theme-hover-lighten-7"
												@click="openRescoreModal(section, item)"
											>
												<a>{{ item.rescores }}</a>
											</td>
										</template>

										<template v-if="project.totals.invalidated > 0">
											<td v-if="item.invalidated == 0" class="ar">
												<a>{{ item.invalidated }}</a>
											</td>
											<td
												v-else
												class="ar clickable theme-hover-lighten-7"
												data-toggle="modal"
												data-target="#rescoreModal"
												@click="cellClick(section.id, item.id, 100)"
											>
												<a>{{ item.invalidated }}</a>
											</td>
										</template>
										<td class="t-margin p-custom"></td>
									</tr>
								</tbody>
							</table>
						</div>
					</div>
				</div>
			</div>
		</div>

		<div id="rescoreModal" class="modal" data-backdrop="static" data-keyboard="false" style>
			<div class="modal-dialog modal-lg">
				<div class="modal-content" v-if="rescoreRow">
					<div class="modal-header">
						<h5>{{ $t("ReportCompletion.rescore_requests") }}</h5>
						<div class="text-muted">
							Section: {{ rescoreRow.section.name }}, Item: {{ rescoreRow.item.name }}
						</div>
					</div>
					<div class="modal-body">
						<div class="row">
							<div class="col-12">
								<table v-if="rescores" class="table w-100">
									<thead>
										<tr>
											<th>{{ $t("fields.scorer") }}</th>
											<th>{{ $t("fields.ref_id") }}</th>
											<th>{{ $t("fields.type") }}</th>
											<th>{{ $t("fields.request_time") }}</th>
											<th v-if="user.role.request_rescores" class="text-center">
												{{ $t("fields.actions") }}
											</th>
										</tr>
									</thead>
									<tbody>
										<tr v-for="rescore in rescores" :key="rescore.id">
											<td class="v-mid">{{ rescore.user.full_name }}</td>
											<td class="v-mid">{{ rescore.ref_id }}</td>
											<td class="v-mid" v-if="rescore.score_id">{{ $t("fields.rescore") }}</td>
											<td class="v-mid" v-if="rescore.flag_id">{{ $t("fields.flag") }}</td>
											<td class="v-mid">{{ fs.medDate(rescore.time) }}</td>
											<td v-if="user.role.request_rescores" class="v-mid text-center">
												<button
													v-if="!rescore.inProgress"
													@click="deleteRescore(rescore)"
													class="btn btn-sm btn-icon btn-rounded red text-white m-0"
													v-tippy
													:title="$t('tooltip.cancel_request')"
												>
													<i class="far fa-trash"></i>
												</button>
												<button
													v-if="rescore.inProgress"
													class="btn btn-sm btn-icon btn-rounded m-0"
													v-tippy
													title="Removing..."
												>
													<loading type="icon" />
												</button>
											</td>
										</tr>
										<tr v-if="!rescores || rescores.length == 0">
											<td colspan="5" class="p-custom py-3 text-center text-muted">
												{{ $t("data_description.no_rescore_requests") }}
											</td>
										</tr>
									</tbody>
								</table>
							</div>
						</div>
						<div class="modal-footer">
							<button data-dismiss="modal" class="btn modal-action modal-closebtn-flat">
								{{ $t("buttons.ok") }}
							</button>
						</div>
					</div>
				</div>
			</div>
		</div>
	</page>
</template>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.ar {
	text-align: right;
}
table {
	table-layout: fixed;
}
.section-name {
	width: 140px;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}
.item-name {
	width: 124px;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}
.item-margin {
	width: 15px;
}
table a {
	cursor: default;
}
.clickable {
	color: #000;
	cursor: pointer;
	transition: background-color 0.15s;
}
.clickable:hover {
	color: inherit;
	text-decoration-line: underline;
}
.d-long {
	display: none;
}
.d-abr {
	display: table-cell;
}
@media (min-width: 1232px) {
	.d-long {
		display: table-cell;
	}
	.d-abr {
		display: none;
	}
}
.table td,
.table th {
	white-space: nowrap;
}
.table td:not(.t-margin),
.table th:not(.t-margin) {
	padding: 0.15rem 0.25rem !important;
}
.t-margin {
	width: 8px;
	padding: 0 !important;
}
.mw-100 {
	min-width: 100%;
}
.btn-subtle {
	opacity: 0.21;
	transition: opacity 0.25s;
}
.btn-subtle:hover {
	opacity: 0.6;
}
.cached {
}
</style>

<script>
//Libraries

//Services
import ReportingService from "@/services/ReportingService";
import ScoringService from "@/services/ScoringService";
import TenantService from "@/services/TenantService";
import SectionService from "@/services/SectionService";
import notie from "@/services/NotieService";
import store from "@/services/Store";
import FileService from "@/services/FileService";
import fs from "@/services/FormatService";
import Notie from "@/services/NotieService";
import $ from "jquery";

import { SCORE_TYPES, RESPONSE_STATES } from "@/services/Constants";

export default {
	name: "ReportCompletion",

	props: ["user"],

	data() {
		return {
			fs: fs,

			projects: null,
			collapsed: store.getDefault(this, "completion.collapsed", {}),
			showAssignedOnly: store.getDefault(this, "completion.showAssignedOnly", false),
			client: null,

			tempReport: false,
			loading: true,

			rescoreRow: null,
			rescores: null,
		};
	},

	computed: {
		canLink() {
			return this.user.role.page_auditing;
		},
		showAwaitingRes() {
			return this.client && this.client.use_res_groups;
		},
		scoreTypes() {
			return SCORE_TYPES;
		},
		responseStates() {
			return RESPONSE_STATES;
		},
	},

	watch: {
		collapsed: {
			handler: function () {
				store.set(this, "completion.collapsed", this.collapsed);
			},
			deep: true,
		},
		showAssignedOnly() {
			store.set(this, "completion.showAssignedOnly", this.showAssignedOnly);
			this.init();
		},
	},

	created() {
		this.init();
	},

	methods: {
		init(ignoreCache) {
			this.loading = true;
			this.liveReport = false;

			let cacheExpired = true;
			if (ignoreCache) {
				this.loadFull();
			} else {
				this.loadCache();
			}

			TenantService.getClient()
				.then((r) => {
					this.client = r.data;
				})
				.catch((e) => {
					console.log(e);
					notie.error("Failed to load client", e);
				});
		},

		loadCache() {
			var now = new Date().getTime();
			this.showHiddenProjects = store.getDefault(this, "projects.showHiddenProjects");
			var ctx = {};
			ctx.showHiddenProjects = this.showHiddenProjects;
			return ReportingService.getCompletionStatsCached(this.showAssignedOnly || this.user.role.limit_teams, ctx)
				.then((resp) => {
					if (!this.liveReport) {
						this.projects = resp.data.projects;
						this.loading = false;
						console.log("Cache time:" + (new Date().getTime() - now), resp.data);

						if (resp.data.expiredCache) {
							console.log("Cache is expired, load full report");
							return this.loadFull();
						}
					}

					this.liveReport = true;
				})
				.catch((err) => {
					notie.error(this.$i18n.t("notie.load_completion_stats_fail"), err);
				});
		},

		loadFull() {
			var now = new Date().getTime();
			this.showHiddenProjects = store.getDefault(this, "projects.showHiddenProjects");
			var ctx = {};
			ctx.showHiddenProjects = this.showHiddenProjects;
			return ReportingService.getCompletionStats(this.showAssignedOnly || this.user.role.limit_teams, ctx).then(
				(resp) => {
					this.projects = resp.data.projects;
					this.liveReport = true;
					console.log("Live time:" + (new Date().getTime() - now));
					this.loading = false;
				}
			);
			// .catch(err => {
			// 	notie.error("Failed to load completion stats", err);
			// })
		},

		pcr(num, den) {
			if (den == 0) {
				return "-";
			}
			if (num == 0) {
				return "0.00";
			}

			var res = (num / den) * 100;
			return res.toFixed(1);
		},

		getOverall() {
			let total = 0;
			_.each(this.projects, (project) => {
				_.each(arguments, (type) => {
					let count = project.totals[type] || 0;
					total += count;
				});
			});
			return total;
		},

		dlPdf(project) {
			FileService.completionReportPDF(project, this.user, this.pcr, this.showAwaitingRes);
		},

		dlCsv(project) {
			FileService.completionReportCSV(project, this.user, this.pcr, this.showAwaitingRes);
		},

		toggleCollapse(id) {
			this.$set(this.collapsed, id, !this.collapsed[id]);
		},

		determineCardMargin(projects, i) {
			let curr = projects[i];
			let next = projects[i + 1];

			if (!next || (this.collapsed[curr.id] && this.collapsed[next.id])) {
				return true;
			} else {
				return false;
			}
		},

		cellClick(sectionId, itemId, state, hasScore, flagged, reviewed) {
			if (!this.canLink) {
				//Prevent linking if the user doesn't have requisite permission to follow the link
				return;
			}

			SectionService.getSection(sectionId).then((r) => {
				let section = r.data;
				console.log("SECTION", section);

				let index = section.item_ids.indexOf(itemId);
				if (index == -1) {
					Notie.error(
						this.$i18n.t("notie.section_item_does_not_exist"),
						this.$i18n.t("notie.contact_admin_about_section_item", {
							section_id: sectionId,
							item_id: itemId,
						})
					);
				} else {
					this.goToAuditing(section.project_id, sectionId, itemId, state, hasScore, flagged, reviewed);
				}
			});
		},

		goToAuditing(projectID, sectionId, itemId, state, hasScore, flagged, reviewed) {
			store.set(this, "audit.r.response_id", "");
			store.set(this, "audit.r.labels", []);
			store.set(this, "audit.r.users", []);
			store.set(this, "audit.r.trait_scores", []);
			store.set(this, "audit.r.score_specific_query", false);
			if (hasScore) {
				store.set(this, "audit.r.filter_types", [{ type: hasScore }]);
			} else {
				store.set(this, "audit.r.filter_types", []);
			}

			store.set(this, "audit.r.selectedItem", itemId);
			store.set(this, "audit.r.selectedSection", sectionId);
			store.set(this, "audit.r.selectedProject", projectID);
			store.set(this, "audit.r.selectedState", state);
			if (flagged) {
				store.set(this, "audit.r.selectedFlagType", { id: "any" });
				if (reviewed) {
					store.set(this, "audit.r.selectedFlagReviewType", {
						id: "all",
					});
				} else {
					store.set(this, "audit.r.selectedFlagReviewType", null);
				}
			} else {
				store.set(this, "audit.r.selectedFlagType", null);
				store.set(this, "audit.r.selectedFlagReviewType", null);
			}

			this.$router.push("/auditing");
		},

		burndown(sid, iid) {
			store.set(this, "burndown.section", sid);
			store.set(this, "burndown.item", iid);
			store.set(this, "burndown.auto", true);

			this.$router.push("/calc/burndown");
		},

		loadRescores(sectionID, itemID) {
			ScoringService.getRescores(sectionID, itemID)
				.then((r) => {
					this.rescores = r.data;
					$("#rescoreModal").modal("show");
				})
				.catch((e) => {
					console.log(e);
					Notie.error("Failed to get rescore requests", e);
				});
		},

		openRescoreModal(section, item) {
			this.rescoreRow = { section: section, item: item };
			this.rescore = null;
			this.loadRescores(section.id, item.id);
		},

		deleteRescore(rescore) {
			rescore.inProgress = true;
			ScoringService.deleteRescore(rescore.id)
				.then((r) => {
					Notie.info("Rescore request canceled");
					if (this.rescoreRow) {
						this.rescores = null;
						this.loadRescores(this.rescoreRow.section.id, this.rescoreRow.item.id);
						this.init(true);
					}
				})
				.catch((e) => {
					console.log(e);
					Notie.error("Failed to cancel rescore request", e);
				});
		},
	},
};
</script>
