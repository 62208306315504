<template>
	<div>
		<div class="btn-group mt-2 btn-block w-100 form-group">
			<button @click="showQueryModal()" class="btn btn-block theme">
				{{ $t("buttons.query_scores") }}
			</button>
			<button @click="clearScoreSearch()" class="btn theme lighten-2" v-tippy :title="$t('tooltip.clear')">
				<i class="far fa-times"></i>
			</button>
		</div>

		<table class="table condensed-table" v-if="atsDisplay && atsDisplay.length > 0">
			<tr>
				<th>{{ $t("fields.type") }}</th>
				<th v-if="scoreQueryType != 2">{{ $t("fields.trait") }}</th>
				<th>{{ $t("fields.score") }}</th>
			</tr>
			<tr v-for="(row, i) in atsDisplay" :key="i">
				<td v-if="row.first_of_type && scoreQueryType != 2" :rowspan="row.rowspan" class="v-mid">
					<span v-if="row.score_type == 0">{{ $t("Auditing.any") }}</span>
					<span class="d-flex flex-row align-items-center" v-if="row.score_type != 0">
						<i class="material-icons mi-small mr-1">{{ fs.scoreTypeIcon(row.score_type) }}</i>
						{{ fs.scoreTypeAbr(row.score_type) }}
					</span>
				</td>
				<td>{{ row.name }}</td>
				<td>{{ row.condition || row.score }}</td>
			</tr>
		</table>

		<b-modal
			size="xl"
			v-model="visible"
			v-if="rubric && traitSelectionMap"
			no-fade
			no-close-on-backdrop
			:visible="visible"
			@hide="visible = false"
		>
			<template slot="modal-title">{{ $t("Auditing.score_query") }}</template>
			<div class="row">
				<table class="table ats-table">
					<thead v-if="scoreQueryType == 0">
						<tr>
							<th style="width: 50%"></th>
							<th></th>

							<th v-for="{ id } in queryableScoreTypes" :key="id">
								<span class="d-flex flex-row align-items-center justify-content-center">
									<i class="material-icons mi-small mr-1">{{ fs.scoreTypeIcon(id) }}</i>
									{{ fs.scoreTypeAbr(id) }}
								</span>
							</th>
							<th style="width: 50%"></th>
						</tr>
					</thead>
					<thead v-if="scoreQueryType == 1 || scoreQueryType == 2">
						<tr>
							<th style="width: 50%"></th>
							<th></th>
							<th class="text-center" style="min-width: 100px">{{ $t("fields.score") }}</th>
							<th style="width: 50%"></th>
						</tr>
					</thead>
					<tbody v-if="scoreQueryType == 0 || scoreQueryType == 1">
						<template v-for="trait in rubric.traits">
							<tr v-if="!(trait.separator || trait.is_parent)" :key="trait.id">
								<td></td>
								<td class="p-custom pr-4 v-mid">{{ trait.reported_name || trait.name }}</td>
								<td v-if="scoreQueryType == 1" style="width: 10px">
									<v-select
										@input="tsChange(trait.id, 0, $event)"
										class="v-select-narrow"
										:options="traitSelectionMap[trait.id][0].sps"
										:value="traitSelectionMap[trait.id][0].selected"
										:searchable="false"
										:clearable="false"
									></v-select>
								</td>
								<template v-for="(st, i) in scoreTypes">
									<td v-if="scoreQueryType == 0" :key="i">
										<v-select
											@input="tsChange(trait.id, st.type, $event)"
											class="v-select-narrow"
											:options="traitSelectionMap[trait.id][st.type].sps"
											:searchable="false"
											:clearable="false"
											:value="traitSelectionMap[trait.id][st.type].selected"
										>
										</v-select>
									</td>
								</template>
								<td></td>
							</tr>
						</template>
					</tbody>
					<tbody v-if="scoreQueryType == 2">
						<tr>
							<td></td>
							<td>
								<label>{{ $t("CandidateAuditing.min_total_score") }}</label>
							</td>
							<td>
								<input
									v-model.number="minScore"
									type="number"
									class="form-control"
									:class="{
										'is-invalid': minScore != null && maxScore != null && minScore > maxScore,
									}"
								/>
							</td>
							<td></td>
						</tr>
						<tr>
							<td></td>
							<td>
								<label>{{ $t("CandidateAuditing.max_total_score") }}</label>
							</td>
							<td>
								<input
									v-model.number="maxScore"
									type="number"
									class="form-control"
									:class="{
										'is-invalid': minScore != null && maxScore != null && minScore > maxScore,
									}"
								/>
							</td>
							<td></td>
						</tr>
					</tbody>
				</table>
			</div>
			<template slot="modal-footer">
				<div class="d-flex flex-row align-items-center w-100">
					<config-select
						v-if="!brScoreQuery"
						:options="scoreQueryOptions"
						byField="id"
						sortBy="sequence"
						v-model="scoreQueryType"
						class="mr-auto"
						style="min-width: 160px"
					/>
					<div v-else class="mr-auto" style="min-width: 160px" />
					<button @click="applyQueryModal" class="btn btn-flat primary">{{ $t("Auditing.apply") }}</button>
				</div>
			</template>
		</b-modal>
	</div>
</template>

<script>
import fs from "@/services/FormatService";
import ConfigSelect from "@/components/ConfigSelect";

import { CONDITION_CODE_ACTIONS, SCORE_TYPES } from "@/services/Constants";

export default {
	name: "ScoreQuery",

	components: {
		ConfigSelect,
	},

	props: {
		rubric: Object,
		initTraitScores: Array,
		brScoreQuery: Boolean,
	},

	data() {
		return {
			visible: false,
			scoreQueryType: 0,
			fs,
			traitSelectionMap: null,
			minScore: null,
			maxScore: null,
			auditTraitScores: [],
			atsDisplay: null,
		};
	},

	created() {
		this.auditTraitScores = this.initTraitScores.map((score) => ({ ...score })); // copy
		this.traitSelectionMap = this.initialTraitSelectionMap(this.rubric);
		this.auditTraitScores.forEach(({ score, score_type, trait_id }) => {
			this.traitSelectionMap[trait_id][score_type].selected = score;
		});
		this.updateATSDisplay();
	},

	computed: {
		scoreTypes() {
			return this.queryableScoreTypes.map(({ id }) => ({
				label: fs.scoreTypeAbr(id, this.$i18n),
				type: id,
			}));
		},

		queryableScoreTypes() {
			const { FIRST, SECOND, RESOLUTION, BACKREAD, ADJUDICATION, APPEAL, APPEAL2 } = SCORE_TYPES;
			if (this.brScoreQuery) {
				return [FIRST, SECOND];
			}
			return [FIRST, SECOND, RESOLUTION, BACKREAD, ADJUDICATION, APPEAL, APPEAL2];
		},

		scoreQueryOptions() {
			return [
				{ name: this.$i18n.t("score_query_types.each_score"), active: true, id: 0, sequence: 0 },
				{ name: this.$i18n.t("score_query_types.any_score"), active: true, id: 1, sequence: 1 },
				{ name: this.$i18n.t("score_query_types.total_score"), active: true, id: 2, sequence: 2 },
			];
		},
	},

	watch: {
		rubric() {
			this.traitSelectionMap = this.initialTraitSelectionMap(this.rubric);
		},

		scoreQueryType() {
			this.auditTraitScores = [];
			this.minScore = null;
			this.maxScore = null;
		},
	},

	methods: {
		initialTraitSelectionMap(rubric) {
			const traitSelectionMap = {};
			_.each((rubric && rubric.traits) || [], (t) => {
				if (t.separator) return;
				if (t.is_parent) return;

				var scoreTypeMap = {};
				var sps = [];
				if (t.step <= 0) {
					t.step = 1;
				}
				for (var i = t.min; i <= t.max; i += t.step) {
					sps.push({
						trait_id: t.id,
						label: "" + i,
						value: i,
					});
				}

				const { SYMBOL, SYMBOL_LINKED, VALUE, VALUE_LINKED } = CONDITION_CODE_ACTIONS;
				_.each(t.condition_codes, (cc) => {
					if (cc.action === SYMBOL.id || cc.action === SYMBOL_LINKED.id) {
						sps.push({
							trait_id: t.id,
							label: cc.symbol,
							value: -1,
							condition: cc.symbol,
						});
					}
					if (cc.action === VALUE.id || cc.action === VALUE_LINKED.id) {
						sps.push({
							trait_id: t.id,
							label: `${cc.symbol} (${cc.score})`,
							value: cc.score,
						});
					}
				});

				let selectedATS = _.find(this.auditTraitScores, {
					trait_id: t.id,
					score_type: 0,
				});
				let selectedSP = selectedATS ? _.find(sps, { value: selectedATS.score }) : null;
				scoreTypeMap[0] = { selected: selectedSP, sps: sps };
				_.each(this.scoreTypes, (st) => {
					let selectedATS = _.find(this.auditTraitScores, {
						trait_id: t.id,
						score_type: 0,
					});
					let selectedSP = selectedATS ? _.find(sps, { value: selectedATS.score }) : null;
					scoreTypeMap[st.type] = { selected: selectedSP, sps: sps };
				});

				_.each(scoreTypeMap, (st) => {
					_.each(st.sps, (sp) => {
						sp.st = st.type;
					});
				});

				traitSelectionMap[t.id] = scoreTypeMap;
			});
			return traitSelectionMap;
		},

		tsChange(trait_id, score_type, sp) {
			this.traitSelectionMap[trait_id][score_type].selected = sp;

			_.remove(this.auditTraitScores, (e) => {
				return e.trait_id == trait_id && e.score_type == score_type;
			});

			if (sp) {
				this.auditTraitScores.push({
					trait_id: trait_id,
					score_type: score_type,
					score: sp.value,
					condition: sp.condition,
				});
			}
		},

		updateATSDisplay() {
			let rows = [];
			let scoreTypeNumMap = {};

			_.each(this.auditTraitScores, (ats) => {
				var trait = _.find(this.rubric.traits, {
					id: ats.trait_id,
				});
				if (trait) {
					scoreTypeNumMap[ats.score_type] = scoreTypeNumMap[ats.score_type] || 0;
					scoreTypeNumMap[ats.score_type]++;
					rows.push({
						name: trait.reported_name || trait.name,
						score_type: ats.score_type,
						score: ats.score,
						condition: ats.condition,
					});
				}
			});

			rows = _.orderBy(rows, ["score_type", "name"]);
			let currentScoreType = -1;
			_.each(rows, (row) => {
				if (currentScoreType != row.score_type) {
					row.first_of_type = true;
					row.rowspan = scoreTypeNumMap[row.score_type] || 1;
					currentScoreType = row.score_type;
				}
			});

			if (this.minScore != null) {
				rows.push({
					name: this.$i18n.t("CandidateAuditing.min_total_score"),
					score_type: 0,
					score: this.minScore,
				});
			}
			if (this.maxScore != null) {
				rows.push({
					name: this.$i18n.t("CandidateAuditing.max_total_score"),
					score_type: 0,
					score: this.maxScore,
				});
			}

			this.atsDisplay = rows;
		},

		showQueryModal() {
			this.visible = true;
		},

		clearScoreSearch() {
			this.auditTraitScores = [];
			this.minScore = null;
			this.maxScore = null;
			this.traitSelectionMap = this.initialTraitSelectionMap(this.rubric);
			this.emitData();
		},

		applyQueryModal() {
			this.emitData();
			this.visible = false;
		},

		emitData() {
			const { minScore, maxScore, auditTraitScores, traitSelectionMap } = this;
			this.updateATSDisplay();
			this.$emit("newScoreFilters", {
				minScore,
				maxScore,
				auditTraitScores,
				traitSelectionMap,
			});
		},
	},
};
</script>

<style></style>
