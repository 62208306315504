
// Component to render a single score. Shows a list of the traits and their assigned values

// Requires a score and rubric to be passed in.

<template>
	<div class="box mb-0 rubric">
		<table class="table mb-0">
			<tbody>
				<tr v-if="flag.user">
					<td style="width: 33%">
						<span class="d-flex flex-row align-items-center">
							<i class="fas fa-fw fa-flag mr-1"></i>
							<strong>Flag:</strong>
						</span>
					</td>
					<td style="width: 67%">
						<span :class="{ 'text-muted': showFlag != flag }" v-tippy :title="showFlag.user.full_name">{{
							fs.scorerID(showFlag.user)
						}}</span>
					</td>
				</tr>
				<tr v-if="!hideTime">
					<td v-if="!flag.previous_versions || flag.previous_versions.length == 0" colspan="2">
						<i class="far fa-clock"></i> {{ fs.fullDate(flag.created_at) }}
					</td>
					<td v-if="flag.previous_versions && flag.previous_versions.length > 0" colspan="2">
						<div
							@click="toggleHistory"
							class="no-select text-muted expand-caret"
							:class="{ active: showHistory }"
						>
							<i class="fas caret-space fa-caret-right"></i>
							<span v-if="!showHistory">{{ flag.previous_versions.length + 1 }} Revisions...</span>
							<span v-if="showHistory">{{ flag.previous_versions.length + 1 }} Revisions: </span>
						</div>
						<a
							v-if="showHistory"
							v-for="(prev, i) in flag.previous_versions"
							:key="i"
							class="history text-muted"
							:class="{ active: showFlag == prev }"
							@mouseover="pickHistory(prev)"
						>
							<i class="fas fa-clock"></i> {{ fs.fullDate(prev.created_at) }}
						</a>
						<a
							v-if="showHistory"
							class="history"
							:class="{ active: showFlag == flag }"
							@mouseover="pickHistory(flag)"
						>
							<i class="far fa-clock"></i> {{ fs.fullDate(flag.created_at) }}
						</a>
						<div v-if="!showHistory"><i class="far fa-clock"></i> {{ fs.fullDate(flag.created_at) }}</div>
					</td>
				</tr>
				<tr>
					<td>
						<strong>Code:</strong>
					</td>
					<td :class="{ 'text-muted': showFlag != flag }">
						{{ (alert && (alert.description || alert.code)) || "Unknown" }}
					</td>
				</tr>
				<tr>
					<td colspan="2">
						<div>
							<strong>Reason:</strong>
						</div>
						<div :class="{ 'text-muted': showFlag != flag }">{{ showFlag.reason }}</div>
					</td>
				</tr>
				<tr v-if="flag.annotations && flag.annotations.length > 0">
					<td colspan="2" class="p-custom p-1">
						<button
							class="btn float-right"
							:class="{
								'btn-success': annotationMatch(),
							}"
							v-tippy
							:title="`${this.annotationMatch() ? 'Hide' : 'Show'} this user's annotations`"
							@click="toggleAnnotations"
						>
							{{
								flag.annotations.length == 1 ? "1 Annotation" : `${flag.annotations.length} Annotations`
							}}
						</button>
					</td>
				</tr>
				<tr v-if="!hideActions">
					<td colspan="2" class="v-mid">
						<div class="d-flex flex-row align-items-center">
							<div class="flex">
								<button
									v-tippy
									title="Review Flag: Confirm"
									class="btn btn-sm btn-icon btn-rounded"
									:class="{
										'btn-success': flag.reviewed == true,
										'btn-outline': flag.reviewed != true,
									}"
									:disabled="flag.reviewing != null"
									@click="toggleReviewed(true)"
								>
									<loading v-if="flag.reviewing == true" class="fa-lg" type="icon" />
									<i v-else class="far fa-lg fa-eye"></i>
								</button>
								<button
									v-tippy
									title="Review Flag: Reject"
									class="btn btn-sm btn-icon btn-rounded"
									:class="{
										'btn-danger': flag.reviewed == false,
										'btn-outline': flag.reviewed != false,
									}"
									:disabled="flag.reviewing != null"
									@click="toggleReviewed(false)"
								>
									<loading v-if="flag.reviewing == false" class="fa-lg" type="icon" />
									<i v-else class="far fa-lg fa-eye-slash"></i>
								</button>

								<!-- <a
									v-if="!flag.reviewing && !flag.reviewed"
									@click="toggleReviewed()"
									class="text-danger hover-circle"
									:class=""
									v-tippy
									title="Not Reviewed"
								>
									<i class="far fa-lg fa-eye-slash"></i>
								</a>
								<a
									v-if="!flag.reviewing && flag.reviewed"
									@click="toggleReviewed()"
									class="text-success hover-circle"
									v-tippy
									title="Reviewed"
								>
									<i class="far fa-lg fa-eye"></i>
								</a>
								<a
									v-if="!flag.reviewing && !flag.reviewed"
									@click="toggleReviewed()"
									class="text-danger hover-circle"
									v-tippy
									title="Not Reviewed"
								>
									<i class="far fa-lg fa-eye-slash"></i>
								</a>
								<a
									v-if="!flag.reviewing && flag.reviewed"
									@click="toggleReviewed()"
									class="text-success hover-circle"
									v-tippy
									title="Reviewed"
								>
									<i class="far fa-lg fa-eye"></i>
								</a>
								<a
									v-if="flag.reviewingPositive"
									class="text-muted hover-circle"
									v-tippy
									title="Updating..."
								>
									<loading class="fa-lg" type="icon" />
								</a> -->
							</div>
							<div v-if="can_message" class="ml-1 w60 float-right">
								<button
									@click="messageUser()"
									class="btn btn-sm btn-icon btn-rounded blue text-white m-0"
									v-tippy
									title="Send Mail to User"
								>
									<i class="far fa-envelope"></i>
								</button>
							</div>
							<button
								v-if="!flag.updating"
								@click="updateFlag()"
								class="btn btn-sm btn-icon btn-rounded theme text-white ml-1"
								v-tippy
								title="Update"
							>
								<i class="far fa-edit"></i>
							</button>
							<button
								v-if="flag.updating"
								class="btn btn-sm btn-icon btn-rounded theme text-white ml-1"
								v-tippy
								title="Updating..."
							>
								<loading type="icon" />
							</button>
							<button
								v-if="!flag.deleting"
								@click="deleteFlag()"
								class="btn btn-sm btn-icon btn-rounded btn-danger text-white ml-1"
								v-tippy
								title="Delete"
							>
								<i class="far fa-trash"></i>
							</button>
							<button
								v-if="flag.deleting"
								class="btn btn-sm btn-icon btn-rounded btn-danger text-white ml-1"
								v-tippy
								title="Deleteing..."
							>
								<loading type="icon" />
							</button>
						</div>
					</td>
				</tr>
				<tr v-if="rescore">
					<td colspan="2" class="p-custom p-2">
						<button class="btn btn-danger float-right" @click="goToRescore">
							{{ $t("Score.rescore") }}
						</button>
					</td>
				</tr>
			</tbody>
		</table>
	</div>
</template>

<script>
import fs from "@/services/FormatService";
import ItemService from "@/services/ItemService";

export default {
	name: "Flag",
	props: {
		flag: true,
		item: true,
		itemID: true,
		hideTime: {
			type: Boolean,
			default: false,
		},
		hideActions: {
			type: Boolean,
			default: false,
		},
		showingAnnotations: {
			default: null,
		},
		message: {
			default: null,
		},
		rescore: {
			default: null,
		},
		alerts: {
			type: Array,
			default: null,
		},
	},
	data() {
		return {
			fs: fs,
			showHistory: false,
			historyFlag: null,
			can_message: this.message,
		};
	},
	created() {
		if (this.itemID && !this.item) {
			ItemService.getItem(this.itemID)
				.then((r) => {
					this.item = r.data;
				})
				.catch((e) => {
					console.error(e);
				});
		}
	},
	mounted() {},
	computed: {
		showFlag() {
			return this.historyFlag || this.flag;
		},
		alert() {
			const { item, alerts, showFlag } = this;

			if (!(item || alerts)) {
				return null;
			}

			return _.find((item && item.alerts) || alerts, { id: showFlag.alert_id });
		},
	},
	watch: {},
	methods: {
		toggleHistory() {
			this.showHistory = !this.showHistory;
			if (!this.showHistory) {
				this.historyFlag = null;
			}
		},

		pickHistory(flag) {
			console.log("PICK HISTORY", flag);
			this.historyFlag = flag;
		},

		deleteFlag() {
			this.$emit("delete", this.flag);
		},

		updateFlag() {
			this.$emit("update", this.flag);
		},

		toggleReviewed(val) {
			this.$set(this.flag, "reviewing", val);

			this.$emit("review", this.flag);
		},

		toggleAnnotations() {
			if (this.annotationMatch()) {
				this.$emit("showAnnotations", []);
			} else {
				this.$emit("showAnnotations", this.flag.annotations);
			}
		},

		annotationMatch() {
			return this.showingAnnotations == this.flag.annotations;
		},

		messageUser() {
			this.$emit("on-message", this.flag);
		},

		goToRescore() {
			if (!this.rescore) {
				return;
			}
			this.$router.push(
				`/rescore_flag/${this.rescore.section_id}/${this.rescore.item_id}/${this.rescore.response_id}/${this.rescore.flag_id}?return=true`
			);
		},
	},
};
</script>

<style scoped>
a.hover-circle {
	margin-top: -6px;
	margin-bottom: -6px;
	transition: background-color 0.25s;
	width: 30px;
	height: 30px;
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	border-radius: 2rem;
}
a.hover-circle:hover {
	background-color: rgba(0, 0, 0, 0.1);
}
a.hover-circle:active {
	background-color: rgba(0, 0, 0, 0.1);
	border: 3px solid rgba(0, 0, 0, 0.1);
}
.expand-caret {
	cursor: pointer;
}
.expand-caret i {
	transition: transform 0.15s ease-in-out;
}
.expand-caret.active i {
	transform: rotate(90deg);
}
a.history {
	display: block;
	cursor: default;
	transition: background-color 0.25s;
	margin-left: -0.5rem;
	margin-right: -0.5rem;
	padding-left: 0.5rem;
	padding-right: 0.5rem;
	border-radius: 0.4rem;
}
a.history.active {
	background-color: rgba(0, 0, 0, 0.1);
}
.caret-space {
	text-align: center;
	width: 0.9rem;
}
.text-muted {
	font-style: italic;
}
</style>
