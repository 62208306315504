import i18n from "@/i18n/setup";

export const QC_TYPES = {
	PRACTICE: { name: i18n.t("qc_types.practice"), id: 1 },
	QUALIFICATION: { name: i18n.t("qc_types.qualification"), id: 2 },
	VALIDITY: { name: i18n.t("qc_types.validity"), id: 3 },
	CALIBRATION: { name: i18n.t("qc_types.calibration"), id: 4 },
	RANGE_FINDING: { name: i18n.t("qc_types.rangefinding"), id: 5 },
	EXEMPLAR: { name: i18n.t("qc_types.exemplar"), id: 6 },
};

export const QC_STATES = {
	MISSING: { name: i18n.t("QCStates.not_started"), id: 0 },
	SCORING: { name: i18n.t("QCStates.in_progress"), id: 1 },
	PASSED: { name: i18n.t("QCStates.passed"), id: 2 },
	FAILED: { name: i18n.t("QCStates.failed"), id: 3 },
};

// TODO refactor this to use { id: # } like the other consts
export const CONDITION_CODE_ACTIONS = {
	SYMBOL: {
		id: 1,
		name: i18n.t("RubricEdit.trait_edit_modal.score_as_code"),
	},
	FLAG: {
		id: 2,
	},
	VALUE: {
		id: 3,
		name: i18n.t("RubricEdit.trait_edit_modal.score_as_value"),
	},
	VALUE_LINKED: {
		id: 4,
		name: i18n.t("RubricEdit.trait_edit_modal.score_as_value_linked"),
	},
	SYMBOL_LINKED: {
		id: 5,
		name: i18n.t("RubricEdit.trait_edit_modal.score_as_code_linked"),
	},
	SYMBOL_AND_VALUE: {
		id: 6,
		name: i18n.t("RubricEdit.trait_edit_modal.score_as_symbol_and_value"),
	},
	SYMBOL_AND_VALUE_LINKED: {
		id: 7,
		name: i18n.t("RubricEdit.trait_edit_modal.score_as_symbol_and_value_linked"),
	},
};

export const TRAINING_PROGRESS_STATES = {
	COMPLETE: { name: i18n.t("Training.complete"), id: 2 },
	IN_PROGRESS: { name: i18n.t("Training.in_progress"), id: 1 },
	INCOMPLETE: { name: i18n.t("Training.not_started"), id: 0 },
	FAILED: { name: i18n.t("Training.failed"), id: -1 },
};

export const TRAINING_ELEMENT_TYPES = {
	SCORM: { id: 1, name: i18n.t("TrainingEdit.scorm_module") },
	RESOURCE: { id: 2, name: i18n.t("TrainingEdit.resource") },
	QUALIFICATION: { id: 3, name: i18n.t("TrainingEdit.qualification") },
	PRACTICE: { id: 4, name: i18n.t("TrainingEdit.practice_set") },
	EXEMPLAR: { id: 5, name: i18n.t("TrainingEdit.exemplar_set") },
};

export const ADJUDICATION_RULES = {
	NO_ADJUDICATION: { id: "", name: i18n.t("Adjudication.Rule.none"), sort: 0 },
	EXACT_SCORE: { id: "EXACT_SCORE", name: i18n.t("Adjudication.Rule.exact_score"), sort: 1 },
	EXACT_TRAIT: { id: "EXACT_TRAIT", name: i18n.t("Adjudication.Rule.exact_trait"), sort: 2 },
	ADJACENT_SCORE: { id: "ADJACENT_SCORE", name: i18n.t("Adjudication.Rule.adjacent_score"), sort: 3 },
	ADJACENT_TRAIT: { id: "ADJACENT_TRAIT", name: i18n.t("Adjudication.Rule.adjacent_trait"), sort: 4 },
};

// TODO: add the rest as needed
export const QUAL_CROSS_SET_REQS = {
	TRAIT_EXACT: { id: "trait_exact" },
	TRAIT_EXACT_ADJ: { id: "trait_exact_adj" },
	KEEP_BEST_TRAIT: { id: "keep_best_trait" },
	TRAIT_MUST_PASS: { id: "trait_pass" },
};

export const RATE_EXCEPTION_TYPES = {
	AVG_RESP_TIME: { id: "AVG_RESP_TIME", name: i18n.t("ItemEdit.Quality.rate_exception.avg_resp_time") },
	WORDS_PER_MINUTE: { id: "WORDS_PER_MINUTE", name: i18n.t("ItemEdit.Quality.rate_exception.words_per_minute") },
};

export const RESPONSE_STATES = {
	UNSCORED: { id: 1, name: i18n.t("fs.unscored") },
	RELIABILITY: { id: 2, name: i18n.t("fs.reliability") },
	RESOLUTION: { id: 3, name: i18n.t("fs.resolution") },
	ADJUDICATION: { id: 4, name: i18n.t("fs.adjudication") },
	APPEAL: { id: 5, name: i18n.t("fs.appeal") },
	COMPLETE: { id: 10, name: i18n.t("fs.complete") },
	COMPARATIVE_JUDGMENT: { id: 11, name: i18n.t("fs.comparative_judgment") },
	WITHHELD: { id: 20, name: i18n.t("fs.withheld") },
	REVIEW: { id: 30, name: i18n.t("fs.review") },
	AWAITING_RESOLUTION: { id: 40, name: i18n.t("fs.awaiting_resolution") },
	INVALIDATED: { id: 100, name: i18n.t("fs.invalidated") },
	LOADING: { id: 50, name: i18n.t("fs.loading") },
};

export const SCORE_TYPES = {
	FIRST: { id: 1, name: i18n.t("fs.first") },
	SECOND: { id: 2, name: i18n.t("fs.second") },
	RESOLUTION: { id: 3, name: i18n.t("fs.resolution") },
	BACKREAD: { id: 4, name: i18n.t("fs.backread") },
	ADJUDICATION: { id: 5, name: i18n.t("fs.adjudication") },
	APPEAL: { id: 17, name: i18n.t("fs.appeal") },
	APPEAL2: { id: 18, name: i18n.t("fs.appeal2") },
	PRACTICE: { id: 11, name: i18n.t("fs.practice") },
	QUALIFICATION: { id: 12, name: i18n.t("fs.qualification") },
	VALIDITY: { id: 13, name: i18n.t("fs.validity") },
	CALIBRATION: { id: 14, name: i18n.t("fs.calibration") },
	RANGE_FINDING: { id: 15, name: i18n.t("fs.rangefinding") },
	TRUE_SCORE: { id: 20, name: i18n.t("fs.true_score") },
	CONSENSUS: { id: 21, name: i18n.t("fs.consensus") },
	EMMA: { id: 30, name: i18n.t("fs.emma") },
	FINAL: { id: 50, name: i18n.t("fs.final_export") },
	EXTERNAL_FINAL_SCORE: { id: 51, name: i18n.t("fs.external_final_score") },
	INVALIDATED_RESOLUTION: { id: 103, name: i18n.t("fs.invalidated_resolution") },
	INVALIDATED_ADJUDICATION: { id: 105, name: i18n.t("fs.invalidated_adjudication") },
};

export const SCORE_MODES = {
	SCORING: { id: "SCORING" },
	RESOLUTION: { id: "RESOLUTION" },
	ADJUDICATION: { id: "ADJUDICATION" },
	APPEAL: { id: "APPEAL" },
	REVIEW: { id: "REVIEW" },
	BACKREAD: { id: "BACKREAD" },
	QUEUE_BACKREAD: { id: "QUEUE BACKREAD" },
	AUDITING: { id: "AUDITING" },
	SCORE_AUDITING: { id: "SCORE_AUDITING" },
	FLAG_QUEUE: { id: "FLAG QUEUE" },
	QC_TRUE_SCORE: { id: "QC_TRUE_SCORE" },
	PRACTICE: { id: "PRACTICE" },
	RANGEFINDING: { id: "RANGEFINDING" },
	RF_TRUE_SCORE: { id: "RF_TRUE_SCORE" },
	RESCORE: { id: "RESCORE" },
};

export const LOCKOUT_REASONS = {
	QUALIFICATION: {
		id: "QUALIFICATION_LOCK",
		lockoutText: i18n.t("tooltip.qual_exception"),
		noLockoutText: i18n.t("tooltip.no_qual_exceptions"),
		abbreviation: i18n.t("Dashboard.qual"),
	},
	VALIDITY: {
		id: "VALIDITY_LOCK",
		lockoutText: i18n.t("tooltip.validity_exception"),
		noLockoutText: i18n.t("tooltip.no_validity_exceptions"),
		abbreviation: i18n.t("Dashboard.val"),
	},
	CALIBRATION: {
		id: "CALIBRATION_LOCK",
		lockoutText: i18n.t("tooltip.cal_exception"),
		noLockoutText: i18n.t("tooltip.no_cal_exceptions"),
		abbreviation: i18n.t("Dashboard.cal"),
	},
	RATE_EXCEPTION: {
		id: "RATE_EXCEPTION_LOCK",
		lockoutText: i18n.t("tooltip.rate_exception"),
		noLockoutText: i18n.t("tooltip.no_rate_exception"),
		abbreviation: i18n.t("Dashboard.rate"),
	},
	FD: {
		id: "FD",
		lockoutText: i18n.t("tooltip.fd_exception"),
		noLockoutText: i18n.t("tooltip.no_fd_exceptions"),
		abbreviation: i18n.t("Dashboard.fd"),
	},
	MANUAL: {
		id: "Manual Lockout",
		lockoutText: i18n.t("tooltip.manual_lockout"),
		noLockoutText: i18n.t("tooltip.no_manual_lockout"),
		abbreviation: i18n.t("Dashboard.man"),
	},
};

export function isInvalidedScore(scoreType) {
	const { INVALIDATED_RESOLUTION, INVALIDATED_ADJUDICATION } = SCORE_TYPES;
	return [INVALIDATED_RESOLUTION.id, INVALIDATED_ADJUDICATION.id].includes(scoreType);
}

export const CF_STATUSES = {
	ERROR: { id: -1, name: i18n.t("Copyleaks.error") },
	NOT_SUBMITTED: { id: 0, name: i18n.t("Copyleaks.not_submitted") },
	SENT: { id: 1, name: i18n.t("Copyleaks.waiting") },
	COMPLETED: { id: 2, name: i18n.t("Copyleaks.completed") },
};

export const FINAL_SCORE_RULES = {
	AVERAGE: { id: "average" },
	WEIGHTED_SUM: { id: "weighted_sum" },
	DOUBLE: { id: "double" },
};

export const TRAIT_RESOLUTION_TYPES = {
	NO_RES: { id: 0 },
	EXACT_ADJ: { id: 1 },
	EXACT: { id: 2 },
};

export const SCORM_PACKAGE_TYPES = {
	SCORM_1_2: { id: "scorm_1.2" },
	ARTICULATE_RISE: { id: "articulate_rise" },
	ARTICULATE_STORYLINE: { id: "articulate_storyline" },
};

export function idToName(constantsSet, constantID) {
	const val = Object.values(constantsSet).find(({ id }) => id === constantID);
	return (val && val.name) || "";
}
