<template>
	<div class="flex w-100" id="exemplar-container">
		<navbar v-if="isForTraining == 'true'" @login="doReturn"></navbar>
		<div class="header theme d-flex" v-if="isForTraining == 'true'">
			<div class="text-muted mr-2 ml-3">{{ $t("ExemplarSet.item") }}:</div>
			<div>{{ item.name }}</div>
			<div class="horizontal-divider ml-3"></div>
			<div class="text-muted mr-2 ml-3">{{ $t("ExemplarSet.set") }}:</div>
			<div>{{ set.name }}</div>
			<div class="horizontal-divider ml-3"></div>

			<div class="horizontal-divider"></div>
			<div class="horizontal-divider ml-auto"></div>
			<a class="header-button" @click="doReturn"> {{ $t("tooltip.exit") }}<i class="ml-2 fas fa-times" /> </a>
		</div>
		<!-- Title bar -->
		<div class="pl-2 pr-3 limit-width center-content">
			<div v-if="displayPage" class="mb-0 box card py-2 px-3 box-shadow theme d-flex flex-row w-100">
				<span class="flex _600">{{ $t("ExemplarSet.exemplar") }} {{ displayPage.response_index }}</span>
				<span class="text-muted">{{ displayPage.ref_id }}</span>
			</div>
		</div>

		<!-- Content with response -->
		<div class="limit-width center-content mt-0 mb-3" v-if="displayPage && displayPage.response">
			<div
				v-if="aboveAnno || aboveScore"
				class="d-flex flex-row justify-content-center limit-width pl-2 pr-3 mt-3"
			>
				<div v-if="aboveAnno" class="flex box-shadow card">
					<div class="box-header b-b _600 py-2">
						<span>{{ $t("ExemplarSet.annotation") }}</span>
					</div>
					<div class="box-body" v-html="displayPage.annotation"></div>
				</div>
				<div v-if="aboveScore" style="flex-basis: 33%" class="ml-3">
					<score
						class="mb-0 box-shadow"
						:score="displayPage.score"
						:rubric="item.rubric"
						:type="20"
						hideTime
						fullWidth
					></score>
				</div>
			</div>

			<media :response="displayPage.response" :item="item" noMargin noChan class="flex pb-2 mb-3 pr-3 scroll-x">
			</media>

			<div
				v-if="belowAnno || belowScore"
				class="d-flex flex-row justify-content-center limit-width pl-2 pr-3 mt-3"
			>
				<div v-if="belowAnno" class="box-shadow card">
					<div class="box-header b-b">{{ $t("ExemplarSet.annotation") }}</div>
					<div class="box-body" v-html="displayPage.annotation"></div>
				</div>
				<div v-if="belowScore" style="flex-basis: 33%" class="ml-3">
					<score
						class="mb-0 box-shadow"
						:score="displayPage.score"
						:rubric="item.rubric"
						:type="20"
						hideTime
						fullWidth
					></score>
				</div>
			</div>
		</div>

		<!-- Content without response -->
		<div class="limit-width-more center-content mb-3" v-if="displayPage && !displayPage.response">
			<score
				v-if="displayPage.score"
				class="mx-auto mb-3 box-shadow"
				:score="displayPage.score"
				:rubric="item.rubric"
				:type="20"
				hideTime
				fullWidth
				style="width: 50%"
			></score>
			<div v-if="displayPage.annotation" class="flex box-shadow card mb-3">
				<div class="box-header b-b _600 py-2">
					<span>{{ $t("ExemplarSet.annotation") }}</span>
				</div>
				<div class="box-body" v-html="displayPage.annotation"></div>
			</div>
		</div>
	</div>
</template>

<style scoped>
.box-header {
	padding: 0.6rem 0.75rem !important;
}

.set-nav-footer {
	margin-left: -10px;
	/* margin-right: -10px; */
	background-color: rgb(231, 231, 231);
}

.limit-width {
	max-width: 800px;
}

.limit-width-more {
	width: 100%;
	max-width: 800px;
	padding-left: 16px;
	padding-right: 16px;
}

.center-content {
	margin-left: auto;
	margin-right: auto;
	margin-top: 1rem;
}

.essay {
	margin-bottom: 20px !important;
}

.box-shadow {
	box-shadow: 3px 3px 11px 1px rgba(184, 182, 184, 1);
}
</style>

<script>
import Media from "@/components/viewer/Media";
import Score from "@/components/Score";
import CompensationQueueTimeService from "@/services/CompensationQueueTimeService";
import Utils from "@/services/Utils";

const MESSAGE = "only_one_active_tab_or_window_allowed_for_training"
var bc;


export default {
	name: "ExemplarSet",
	props: ["set", "item", "currentPage", "compensationStartTime"],

	data() {
		return {
			isForTraining: this.$route.params.isForTraining,
			user: this.$route.meta.user,
		};
	},
	components: { Media, Score },
	created() {
		if (this.isForTraining == "true") {
			bc = new BroadcastChannel("oscar_broadcast_channel");
			bc.onmessage = (event) => {
				if (event.data == MESSAGE){
					console.log(event.data)
					this.$router.go(-1);
				}
			};
			bc.postMessage(MESSAGE)
		}

		let _this = this;
		if (this.isForTraining == "true") {
			window.addEventListener("beforeunload", this.updateCompensationQueueTime);
			window.onpopstate = function () {
				_this.updateCompensationQueueTime();
			};
		}
		Utils.throttleMathJAX();
	},
	watch: {
		currentPage() {
			Utils.throttleMathJAX();
		},
	},
	computed: {
		pages() {
			let pages = [];
			const resps = (this.set.responses || []).sort((respA, respB) => respA.sequence - respB.sequence);
			_.each(resps, (r, i) => {
				let response_index = i + 1;
				//Make a page before the response
				if (this.set.score_position == 3 || this.set.anno_position == 3) {
					let page = {
						response_index: response_index,
						ref_id: r.ref_id,
					};
					if (this.set.score_position == 3) {
						page.score = r.true_score;
					}
					if (this.set.anno_position == 3) {
						page.annotation = r.feedback;
					}
					pages.push(page);
				}

				//Main response page
				let page = {
					response_index: response_index,
					ref_id: r.ref_id,
					response: r,
				};
				if (this.set.score_position == 1 || this.set.score_position == 2) {
					page.score = r.true_score;
				}
				if (this.set.anno_position == 1 || this.set.anno_position == 2) {
					page.annotation = r.feedback;
				}
				pages.push(page);

				//Make a page after the response
				if (this.set.score_position == 4 || this.set.anno_position == 4) {
					let page = {
						response_index: response_index,
						ref_id: r.ref_id,
					};
					if (this.set.score_position == 4) {
						page.score = r.true_score;
					}
					if (this.set.anno_position == 4) {
						page.annotation = r.feedback;
					}
					pages.push(page);
				}
			});

			this.$emit("update:numPages", pages.length);

			return pages;
		},
		displayPage() {
			return this.pages[this.currentPage - 1];
		},
		aboveAnno() {
			return this.displayPage && this.set && this.displayPage.annotation && this.set.anno_position == 1;
		},
		aboveScore() {
			return this.displayPage && this.set && this.displayPage.score && this.set.score_position == 1;
		},
		belowAnno() {
			return this.displayPage && this.set && this.displayPage.annotation && this.set.anno_position == 2;
		},
		belowScore() {
			return this.displayPage && this.set && this.displayPage.score && this.set.score_position == 2;
		},
	},

	beforeDestroy() {
		if (this.isForTraining == "true") {
			console.log("destroyed");
			window.removeEventListener("beforeunload", this.updateCompensationQueueTime);
			window.onpopstate = function () {};
			this.updateCompensationQueueTime();
			bc.close()
		}
	},

	methods: {
		updateCompensationQueueTime() {
			CompensationQueueTimeService.updateCompensationQueueTime(
				this.user.id,
				this.item.id,
				"TRAINING_EXEMPLAR_SET",
				this.compensationStartTime.compensation_start_time,
				"000000000000000000000000",
				6
			);
		},

		doReturn() {
			this.$router.go(-1);
		},
	},
};
</script>
