<template>
	<div class="tab-pane h-100" :class="{ active: defaultTab, show: defaultTab }" :id="id">
		<div class="flex-v h-100">
			<div class="p-4 b-b _600 nav-title flex-fixed">{{ $t("SectionEdit.Teams.title") }}</div>

			<div class="flex-grow flex-v">
				<dual-table
					:leftLabel="$t('SectionEdit.Teams.assign_teams')"
					:leftSearch="$t('SectionEdit.Teams.search_assigned')"
					:leftItems="hasProvider"
					:leftFields="hasFields"
					:leftControl="hasControl"
					:rightLabel="$t('SectionEdit.Teams.add')"
					:rightSearch="$t('SectionEdit.Teams.search_available')"
					:rightItems="canProvider"
					:rightFields="canFields"
					:rightControl="canControl"
				>
					<!-- Actions -->
					<template #cell(left:actions)="data">
						<nobr>
							<div class="mx-auto w30">
								<button
									v-if="!data.item.inProgress"
									class="btn btn-sm btn-icon btn-rounded red text-white m-0"
									@click="attemptRemoveTeam(data.item)"
									v-tippy
									:title="$t('tooltip.remove_from_section')"
								>
									<i class="far fa-minus"></i>
								</button>
								<button
									v-if="data.item.inProgress"
									class="btn btn-sm btn-icon btn-rounded m-0"
									v-tippy
									title="Removing..."
								>
									<loading type="icon" />
								</button>
							</div>
						</nobr>
					</template>

					<!-- Actions -->
					<template #cell(right:actions)="data">
						<nobr>
							<div class="mx-auto w30">
								<button
									v-if="!data.item.inProgress"
									class="btn btn-sm btn-icon btn-rounded success text-white m-0"
									@click="attemptAddTeam(data.item)"
									v-tippy
									title="Assign to Section"
								>
									<i class="far fa-plus"></i>
								</button>
								<button
									v-if="data.item.inProgress"
									class="btn btn-sm btn-icon btn-rounded m-0"
									v-tippy
									title="Adding..."
								>
									<loading type="icon" />
								</button>
							</div>
						</nobr>
					</template>
				</dual-table>
			</div>
		</div>

		<save-required-modal
			:dirty="dirty"
			:valid="valid"
			:save="save"
			:next="saveReqNext"
			objectText="Section"
			:objectName="section.name"
			:actionText="saveReqAction"
			v-model="saveReqModal"
		/>
	</div>
</template>

<style scoped>
.w30 {
	width: 30px;
}
</style>

<script>
import SectionService from "@/services/SectionService";
import notie from "@/services/NotieService";

import DualTable from "@/components/DualTable";
import SaveRequiredModal from "@/components/SaveRequiredModal";

export default {
	name: "SectionEditTeams",

	props: ["id", "section", "dirty", "valid", "save", "defaultTab"],

	components: {
		"dual-table": DualTable,
		"save-required-modal": SaveRequiredModal,
	},

	data() {
		return {
			hasFields: [
				{
					key: "name",
					label: this.$i18n.t("fields.name"),
					sortable: true,
				},
				{ key: "ref_id", label: this.$i18n.t("fields.ref_id") },
				{
					key: "actions",
					label: this.$i18n.t("fields.actions"),
					thStyle: { "text-align": "center" },
					tdClass: "p-custom py-0 v-mid",
				},
			],
			hasControl: {
				totalRows: 0,
				currentPage: 1,
				perPage: 10,
				filter: "",
				sortBy: "",
				sortDesc: false,
				emptyText: "",
			},
			canFields: [
				{
					key: "name",
					label: this.$i18n.t("fields.name"),
					sortable: true,
				},
				{ key: "ref_id", label: this.$i18n.t("fields.ref_id") },
				{
					key: "actions",
					label: this.$i18n.t("fields.actions"),
					thStyle: { "text-align": "center" },
					tdClass: "p-custom py-0 v-mid",
				},
			],
			canControl: {
				totalRows: 0,
				currentPage: 1,
				perPage: 10,
				filter: "",
				sortBy: "",
				sortDesc: false,
				emptyText: "",
			},

			saveReqModal: false,
			saveReqAction: "continue",
			saveReqNext: () => {},
		};
	},

	created() {},

	methods: {
		hasProvider(ctx) {
			if (this.section.id) {
				this.hasControl.emptyText = this.$i18n.t("data_description.no_records");
				return SectionService.getSectionTeams(this.section.id, ctx)
					.then((resp) => {
						console.log(resp);
						this.hasControl.totalRows = resp.data.totalRows;
						return resp.data.teams;
					})
					.catch((err) => {
						notie.error(this.$i18n.t("notie.get_section_teams_fail"), err);
						return [];
					});
			} else {
				this.hasControl.emptyText = "Cannot assign teams to a section before saving";
				return [];
			}
		},

		canProvider(ctx) {
			ctx.inverse = "t";
			if (this.section.id) {
				this.canControl.emptyText = this.$i18n.t("data_description.no_records");
				return SectionService.getSectionTeams(this.section.id, ctx)
					.then((resp) => {
						console.log(resp);
						this.canControl.totalRows = resp.data.totalRows;
						return resp.data.teams;
					})
					.catch((err) => {
						notie.error(this.$i18n.t("notie.load_assignable_teams_fail"), err);
						return [];
					});
			} else {
				this.canControl.emptyText = "Cannot assign teams to a section before saving";
				return [];
			}
		},

		attemptAddTeam(team) {
			if (this.dirty) {
				this.saveReqNext = () => {
					this.addTeam(team);
				};
				this.saveReqAction = "add an team to the section";
				this.saveReqModal = true;
			} else {
				this.addTeam(team);
			}
		},

		addTeam(team) {
			team.inProgress = true;
			SectionService.addTeam(this.section.id, team.id)
				.then((resp) => {
					notie.info(this.$i18n.t("notie.team_added"));
					this.refreshTables();
					team.inProgress = false;
				})
				.catch((err) => {
					notie.error(this.$i18n.t("notie.add_team_fail"), err);
					team.inProgress = false;
				});
		},

		attemptRemoveTeam(team) {
			if (this.dirty) {
				this.saveReqNext = () => {
					this.removeTeam(team);
				};
				this.saveReqAction = "remove an team from the section";
				this.saveReqModal = true;
			} else {
				this.removeTeam(team);
			}
		},

		removeTeam(team) {
			team.inProgress = true;
			SectionService.removeTeam(this.section.id, team.id)
				.then((resp) => {
					notie.info(this.$i18n.t("notie.team_removed"));
					this.refreshTables();
					team.inProgress = false;
				})
				.catch((err) => {
					notie.error(this.$i18n.t("notie.remove_team_fail"), err);
					team.inProgress = false;
				});
		},

		refreshTables() {
			if (this.hasControl && this.hasControl.refresh) {
				this.hasControl.refresh();
			} else {
				console.error("Left (has assigned) table had no refresh defined");
			}
			if (this.canControl && this.canControl.refresh) {
				this.canControl.refresh();
			} else {
				console.error("Right (can assign) table had no refresh defined");
			}
		},
	},
};
</script>
