import axios from "axios";

export default {
	fa_icon: "fa-user-graduate",
	name: "Practice",
	allowUpdateScore: true,
	showScores() {
		return false;
	},
	sidebarType() {
		return "normal";
	},

	getNext(ctx) {
		let sid = ctx.section_id;
		let iid = ctx.item_id;
		let setID = ctx.set_id;
		var promise = axios.get(`/v1/practice/${sid}/${iid}/${setID}`);
		promise.then(function (resp) {
			console.log("PracticeSD GetNext:" + resp.data.response.ref_id);
		});
		return promise;
	},

	getResponse(ctx, responseID) {
		let sid = ctx.section_id;
		let iid = ctx.item_id;
		let setID = ctx.set_id;
		var promise = axios.get(`/v1/practice/${sid}/${iid}/${setID}`, {
			params: { r: responseID }
		});
		promise.then(function (resp) {
			console.log("PracticeSD GetResponse:" + resp.data.response.ref_id);
		});
		return promise;
	},

	onError(err, ctx, vue) {
		vue.$router.push(`/qc_set_report/${ctx.set_id}`);
	}
};
